const messages = {
    structuresTypes: 'Tipos de Estructuras',
    addStructureType: 'Añadir Tipo de Estructura',
    editStructureType: 'Editar Tipo de Estructura',
    level: 'Nivel',
    informativeTextStructuresTypes: 'Listado de los tipos de estructuras de la compañía.',
    parentStructure: 'Estructura Padre',
    firstChild: 'Adicionar Estructura Después de:',
    secondChild: 'Adicionar Estructura Antes de:',
    companyStructure: 'Estructura de la Compañía',
    addStructure: 'Añadir Estructura',
    editStructure: 'Editar Estructura',
    addStructureTo: 'Adicionar Estructura a ',
    addRootStructure: 'Adicionar Estructura Raíz',
    delete: 'Eliminar',
    confirmDeleteStructure: '¿Estás seguro(a) que deseas eliminar ésta estructura?',
    structureDeletedSuccessfully: 'Estructura eliminada exitosamente',
    structureAddedSuccessfully: 'Estructura adicionada exitosamente',
    structureEditedSuccessfully: 'Estructura editada exitosamente',
    structureTypeAddedSuccessfully: 'Tipo de estructura adicionado exitosamente',
    structureTypeEditedSuccessfully: 'Tipo de estructura editado exitosamente',
};

export default messages;
