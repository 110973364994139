import { RouterView } from 'vue-router';
import assignedLeadRoutes from './sales/assignedLeads.routes';
const FrontPatients = () => import('@/pages/frontdesk/tpv/FrontPatients.vue');
const PaymentLinks = () => import('@/pages/sales/paymentLinks/PaymentLinks.vue');
const DepartmentLeads = () => import('@/pages/sales/assignedLeads/DepartmentLeads.vue');
const RecuperableLeads = () => import('@/pages/sales/assignedLeads/RecuperableLeads.vue');
const HistoricalLeads = () => import('@/pages/sales/assignedLeads/HistoricalLeads.vue');
const AssignAssistants = () => import('@/pages/sales/sellers/AssignAssistants.vue');
const ChartLeads = () => import('@/pages/sales/chart/ChartLeads.vue');
const AmountSalesBySeller = () => import('@/pages/sales/statistics/AmountSalesBySeller.vue');
const GeneralSurgery = () => import('@/pages/sales/statistics/GeneralSurgery.vue');
const GeneralCosmetology = () => import('@/pages/sales/statistics/GeneralCosmetology.vue');
const CreateQuote = () => import('@/pages/sales/quotes/CreateQuote.vue');
const AppointmentsByAssignedLeads = () =>
    import('@/pages/sales/appointments/AppointmentsByAssignedLeads.vue');

const salesRoutes = [
    {
        path: 'sales',
        name: 'Sales',
        redirect: () => {
            return { path: '/sales/leads' };
        },
        component: RouterView,
        meta: {
            title: 'Sales',
            public: false,
            icon: 'PresentationChartLineIcon',
        },
        children: [
            ...assignedLeadRoutes,
            {
                path: 'lookup',
                name: 'SalesAllLeads',
                component: FrontPatients,
                meta: {
                    menu: true,
                    title: 'Leads',
                    permissionsAnyOf: ['Permissions.Sales.LeadProfiles.Search'],
                },
            },
            {
                path: 'leads',
                name: 'DepartmentLeads',
                component: DepartmentLeads,
                meta: {
                    menu: true,
                    title: 'All Leads',
                    permissionsAnyOf: ['Permissions.Sales.AssignedLeads.SearchDepartment'],
                },
            },
            {
                path: 'recuperation',
                name: 'RecuperableLeads',
                component: RecuperableLeads,
                meta: {
                    menu: true,
                    title: 'Recuperation',
                    permissionsAllOf: [
                        'Permissions.Sales.AssignedLeads.RecuperableSearch',
                        'Permissions.Sales.AssignedLeads.Recuperate',
                    ],
                },
            },
            {
                path: 'historical',
                name: 'HistoricalLeads',
                component: HistoricalLeads,
                meta: {
                    menu: true,
                    title: 'Historical Leads',
                    permissionsAllOf: ['Permissions.Sales.AssignedLeads.MarketingHistoricalSearch'],
                },
            },
            {
                path: 'assistants',
                name: 'AssignAssistants',
                component: AssignAssistants,
                meta: {
                    menu: true,
                    title: 'Assign Assistants',
                    permissionsAllOf: [
                        'Permissions.Sales.Sellers.Search',
                        'Permissions.Sales.Sellers.SearchAssistants',
                    ],
                },
            },
            {
                path: 'paymentlinks/:id',
                name: 'PaymentLinks',
                component: PaymentLinks,
                props: true,
            },
            {
                path: 'patient/:id/quote',
                name: 'Quote',
                component: CreateQuote,
                props: true,
                meta: {
                    title: 'Quote',
                    permissionsAnyOf: [
                        'Permissions.Sales.Quotes.Create',
                        'Permissions.Sales.Quotes.Update',
                    ],
                },
            },
            {
                path: 'chart',
                name: 'ChartLeads',
                component: ChartLeads,
                meta: {
                    menu: true,
                    title: 'Chart Leads',
                    permissionsAllOf: [
                        'Permissions.Publicity.Leads.Search',
                        'Permissions.ClientProfile.Chart.Search',
                    ],
                },
            },
            {
                path: 'general-cosmetology',
                name: 'GeneralCosmetology',
                component: GeneralCosmetology,
                meta: {
                    menu: true,
                    title: 'General Cosmetology',
                    permissionsAnyOf: ['Permissions.Sales.Statistics.GeneralSurgery'],
                },
            },
            {
                path: 'general-surgery',
                name: 'GeneralSurgery',
                component: GeneralSurgery,
                meta: {
                    menu: true,
                    title: 'General Surgery',
                    permissionsAnyOf: ['Permissions.Sales.Statistics.GeneralSurgery'],
                },
            },
            {
                path: 'sales-seller',
                name: 'AmountSalesBySeller',
                component: AmountSalesBySeller,
                meta: {
                    menu: true,
                    title: 'Sellers',
                    permissionsAnyOf: ['Permissions.Sales.Statistics.AmountSalesBySeller'],
                },
            },
            {
                path: 'appointments',
                name: 'FrontDeskAllAppointments',
                component: AppointmentsByAssignedLeads,
                meta: {
                    menu: true,
                    title: 'My CheckIns',
                    permissionsAllOf: ['Permissions.Sales.Appointments.Search'],
                },
            },
        ],
    },
];

export default salesRoutes;
