import { RouterView } from 'vue-router';
const AddUser = () => import('@/pages/identity/users/AddUsers.vue');
const EditUser = () => import('@/pages/identity/users/EditUsers.vue');
const ListUsers = () => import('@/pages/identity/users/ListUsers.vue');
const ResetUsersPassword = () => import('@/pages/identity/users/ResetUsersPassword.vue');
const ListRoles = () => import('@/pages/identity/roles/ListRoles.vue');
const AddRoles = () => import('@/pages/identity/roles/AddRoles.vue');
const EditRoles = () => import('@/pages/identity/roles/EditRoles.vue');
const ListPermissions = () => import('@/pages/identity/roles/ListPermissions.vue');

const identityRoutes = [
    {
        path: 'identity',
        name: 'Identity',
        redirect: (to) => {
            return { path: '/identity/users' };
        },
        component: RouterView,
        meta: {
            title: 'Identity',
            public: false,
            icon: 'UserCircleIcon',
        },
        children: [
            {
                path: 'users',
                name: 'IdentityUsers',
                component: ListUsers,
                meta: {
                    menu: true,
                    title: 'Users',
                    permissionsAnyOf: ['Permissions.Identity.Users.Search'],
                },
            },
            {
                path: 'users/add',
                name: 'IdentityUsersAdd',
                component: AddUser,
            },
            {
                path: 'users/edit/:id',
                name: 'IdentityUsersEdit',
                component: EditUser,
            },
            {
                path: 'users/reset-password/:id',
                name: 'IdentityUsersResetPassword',
                component: ResetUsersPassword,
            },
            {
                path: 'roles',
                name: 'IdentityRoles',
                component: ListRoles,
                meta: {
                    menu: true,
                    title: 'Roles',
                    permissionsAnyOf: ['Permissions.Identity.Roles.Search'],
                },
            },
            {
                path: 'roles/add',
                name: 'IdentityRolesAdd',
                component: AddRoles,
            },
            {
                path: 'roles/edit/:id',
                name: 'IdentityRolesEdit',
                component: EditRoles,
            },
            {
                path: 'roles/permissions/:id',
                name: 'IdentityRolesPermissions',
                component: ListPermissions,
            },
        ],
    },
];

export default identityRoutes;
