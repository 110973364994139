const messages = {
    Type: 'Tipo',
    createNewAppointment: 'Crear Nueva Cita',
    editAppointment: 'Editar Cita',
    detailedDailyPayments: 'Pagos Diarios Detallados',
    applied: 'Aplicado',
    To: 'a',
    product: 'Producto',
    check: 'Cheque',
    creditCard: 'Tarjeta de Crédito',
    debitCard: 'Tarjeta de Débito',
    cash: 'Efectivo',
    surgery: 'Cirugía',
    nonSurgical: 'No Quirúrgico',
    followUp: 'Follow Up',
    consultation: 'Consulta',
    toSeller: 'Al Vendedor',
    other: 'Otro',
    earningsReport: 'Informe de Ganancias',
    service: 'Servicio',
    services: 'Servicios',
    shoppingCart: 'Carrito de Compras',
    remove: 'Eliminar',
    total: 'Total',
    qty: 'Cant.',
    noItemsSelected: 'No hay elementos seleccionados',
    paymentNumber: 'Número de Pago',
    paymentReceipt: 'Recibo de Pago',
    shippingTaxesCalculatedCheckout:
        'Gastos de envío e impuestos calculados al finalizar la compra.',
    goBack: 'Regresar',
    checkout: 'Chequear',
    or: 'o',
    totalQuotes: 'Cotizaciones Totales',
    availableBalance: 'Saldo Disponible',
    pendientBalance: 'Saldo Pendiente',
    deposit: 'Depósito',
    close: 'Cerrar',
    cancelSaleCauses: 'Cancelar Causas de Ventas',
    value: 'Valor',
    saleCreatedSuccessfully: 'Venta creada exitosamente',
    sell: 'Vender',
    fee: 'Tarifa',
    dispatch: 'Despachar',
    showingContactInfo: 'Mostrando Información de Contacto Aquí',
    createNewMassageAppointment: 'Crear Nueva Cita de Masaje',
    errorCheckInAppointment: 'Error al Registrar la Cita',
    appointmentType: 'Tipo de Cita',
    totalBalance: 'Total Balance',
    checkIn: 'Registrarse',
    clientName: 'Nombre del Cliente',
    clientId: 'ID del Cliente',
    checked: 'Checked',
    providers: 'Proveedores',
    addAppointment: 'Adicionar cita',
    cancelSaleCreditBalance: 'La cantidad de ${field} seran adicionados a su credito de balance',
    sale: 'Venta',
    addDepositTo: 'Añadir depósito a',
    editDepositTo: 'Editar depósito a',
    unauthorizedAppointmentsList: 'Usted no tiene autorización para ver el listado de citas',
    errorNotPendentBalance: 'El paciente no tiene saldo pendiente',
    cardNumber: 'Número de Tarjeta',
    cardHolder: 'Propietario de la Tarjeta',
    Nursing: 'Enfermería',
    NonNursing: ' No Enfermería',
    massage: 'Masaje',
    selectService: 'Seleccionar Servicio',
    selectProvider: 'Seleccionar Proveedor',
    selectProcedure: 'Seleccionar Procedimiento',
    formIncomplete: 'Debe completar el formulario',
    appointmentServiceDeletedSuccessfully: 'Servicio de cita eliminado satisfactoriamente',
    confirmDeleteAppointmentService: 'Confirmar Eliminación del Servicio',
    deleteAppointmentService: 'Eliminar Servicio de Cita',
    types: 'Tipos',
    causes: 'Causas',
    massageAppointment: 'Cita de Masaje',
    nonSurgicalAppointment: 'Cita no Quirúrgica',
    evaluationAppointment: 'Cita de Evaluación',
    cosmetologyAppointment: 'Cita de Cosmetología',
    coordinator: 'Coordinador',
    youWantToCancelCheckIn: '¿Está seguro que desea cancelar el checkin de esta cita?',
    appointmentCancelCheckIn: 'A la cita le será cancelado el checkin',
    cancelCheckIn: 'Cancelar checkin',
    notPayFee: 'No pagar la tarifa',
    payFeeLater: 'Pagar el tarifa posteriormente',
    youWantToSendSmsToPatient:
        '¿Está seguro de que desea enviar un SMS al cliente? Esto puede venir con acciones irreversibles.',
    notifyPatient: 'Notificar al Paciente',
    charger: 'Charger',
    pay: 'Pagar',
    NonSurgical: 'No Quirúrgico',
    itemRemovedSuccessfully: 'Artículos de tratamiento eliminado satisfactoriamente',
    confirmDeleteTreatmentItem: 'Confirmar Eliminación del artículos de tratamiento',
    authorizeTreatmentRefund: 'Autorizar rembolso de tratamiento',
    updateTreatment: 'Actualizar tratamiento',
    createTreatment: 'Crear tratamiento',
    itemPaidSuccessfully: 'Artículo pagado satisfactoriamente',
    last4: 'Ultimos 4',
    activeQuotes: 'Cuota Activa',
    items: 'Elemento',
    changeDepartment: 'Cambiar departamento',
    changePaymentMethod: 'Cambiar Método de Pago',
    transferToTreatment: 'Transferencia a {item} del Tratamiento #{quote}',
    changeSeller: 'Cambiar vendedor',
    inventoryOffice: 'Oficina de inventario',
};

export default messages;
