const ChangePassword = () => import('../pages/identity/profile/ChangePassword.vue');
const EditProfile = () => import('../pages/identity/profile/EditProfile.vue');
const NotificationList = () => import('../pages/identity/profile/NotificationList.vue');
import { RouterView } from 'vue-router';
const profileRoutes = [
    {
        path: 'profile',
        name: 'Profile',
        redirect: (to) => {
            return { path: '/identity/users' };
        },
        component: RouterView,
        meta: {
            title: 'User Profile',
            public: false,
        },
        children: [
            {
                path: 'change-password',
                name: 'ChangePassword',
                component: ChangePassword,
            },
            {
                path: 'edit-profile',
                name: 'EditProfile',
                component: EditProfile,
            },
            {
                path: 'notifications',
                name: 'UserNotifications',
                component: NotificationList,
            },
        ],
    },
];
export default profileRoutes;
