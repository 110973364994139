const messages = {
    defaultConfig: 'Default Configuration',
    overtimeConfigurations: 'Overtime Configurations',
    addSettings: 'Add Configuration',
    fromThe: 'From',
    firstExplanatoryTextOvertime: 'A list of all overtime configurations.',
    addOvertimeConfig: 'Add Overtime Configurations',
    editOvertimeConfig: 'Edit Overtime Configurations',
};

export default messages;
