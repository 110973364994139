const messages = {
    closePaymentPeriod: 'Close payment period',
    drafts: 'Drafts',
    review: 'Review',
    payrolls: 'Payrolls',
    checkWasPrinted: 'With printed checks',
    returnToDraft: 'Back to draft',
    excludeThisPayrollFromPayment: 'Exclude this payroll from period?',
    excludeThisPayrollFromPaymentHelpText:
        'This will return this payroll to "draft" state and exclude it from payment period. You can include it again when you want.',
    payrollExcludedSuccess: 'Payroll excluded successfully',
    regularHours: 'Regular hours',
    overtimeHourlyWage: 'Overtime hourly wage',
    bonuses: 'Bonuses',
    deductions: 'Deductions',
    basic: 'Basic',
    printPaySlip: 'Print payslip',
    updateAllHours: 'Update all hours',
    hours: 'Hours',
    earnings: 'Earnings',
    commissionDate: 'Commission date',
};

export default messages;
