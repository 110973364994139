const messages = {
    addNewPaymentType: 'Adicionar Nuevo Tipo de Pago',
    editNewPaymentType: 'Editar Nuevo Tipo de Pago',
    addPayment: 'Añadir Pago',
    addPaymentMethods: 'Añadir Método de Pago',
    addPaymentsTypes: 'Añadir Tipo de Pago',
    paymentsTypes: 'Tipos de Pagos',
    invoiceNumber: 'Número de Factura',
    valueToPaid: 'Valor a Pagar',
    paymentTypeId: 'Tipo de Pago',
    paymentType: 'Tipo de Pago',
    paymentTypeAddedSuccessfully: 'Tipo de Pago adicionado exitosamente',
    paymentTypeUpdatedSuccessfully: 'Tipo de Pago editado exitosamente',
    paymentsByProvider: 'Pagos Pensuales a Proveedores',
    moneyCollectionPerDay: 'Estadísticas de Cobros',
    graphMoneyCollectionPerDay: 'Operaciones de Cobro por Día',
    numberMoneyCollectionPerDay: 'Monto de Cobros por Día',
    companyLegalDataEditedSuccessfully: 'Información Legal de la Compañía Actualizada Exitosamente',
    companyLegalDataNote: 'Ésta Información es necesaria para algunos documentos de pagos',
    companyLegalDataAlert: 'Ésta información es requerida por documentos de pagos',
    companyLegalData: 'Datos Legales de la Empresa',
    confirmDeletePaymentMethod: 'Confirmar Eliminación del Método de Pago',
    confirmDeletePayment: 'Confirmar Eliminación del Pago',
    paymentDeletedSuccessfully: 'Pago eliminado satisfactoriamente',
    paymentAddedSuccessfully: 'Pago adicionado exitosamente',
    paymentUpdatedSuccessfully: 'Pago editado exitosamente',
    unauthorizedAction: 'Usted no tiene autorización para realizar ésta acción',
    unauthorizedStatistics: 'Usted no tiene autorización para ver éstas estadísticas',
    unauthorizedPaymentsStatistics: 'Usted no tiene autorización para ver estadísticas de pago',
    unauthorizedAddPayments: 'Usted no tiene autorización para adicionar pagos',
    unauthorizedEditPayments: 'Usted no tiene autorización para editar pagos',
    unauthorizedPaymentsList: 'Usted no tiene autorización para ver la lista de pagos',
    unauthorizedPaymentsTypesList:
        'Usted no tiene autorización para ver la lista de tipos de pagos',
    unauthorizedEditCompanyLegalData:
        'Usted no tiene autorización para editar los datos de la compañía',
    unauthorizedBalanceAdjustmentsList:
        'Usted no tiene autorización para ver la lista de ajustes de balance',
    unauthorizedBalanceAdjustmentsHistory:
        'Usted no tiene autorización para ver el historial de ajustes de balance',
    unauthorizedCreateBalanceAdjustments:
        'Usted no tiene autorización para crear ajustes de balance',
    Done: 'Terminado',
    paymentState: 'Estado del Pago',
    typeFinishedServices: 'Tipo de Servicio Finalizado',
    addExternalProvider: 'Adicionar Proveedor Externo',
    editExternalProvider: 'Editar Proveedor Externo',
    editPayment: 'Editar Pago',
    checkNumber: 'Número de Cheque',
    printPayment: 'Imprimir Pago',
    paymentDelete: 'Borrar Pago',
    youWantToRemovePayment: 'Desea Eliminar el Pago',
    youWantToRemoveExternalProvider: 'Desea eliminar el Proveedor externo',
    unauthorizedCreateLockPatient: 'Usted no tiene autorización para bloquear pacientes',
    payPeriodStartDate: 'Fecha Inicio Período Pago',
    payPeriodEndDate: 'Fecha Fin Período Pago',
    quoteLogs: 'Registros de Cuota',
    modifiedBy: 'Modificado',
    QuoteTransfers: 'Transferencias de Cuota',
    TreatmentTransfers: 'Transferencias de Tratamientos',
    allTreatmentTransfers: 'Todas las transferencias de los tratamientos',
};

export default messages;
