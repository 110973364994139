import { RouterView } from 'vue-router';
const MassagesPage = () => import('@/pages/medicalServices/massages/MassagesPage.vue');
const NonSurgicalPage = () => import('@/pages/medicalServices/nonSurgicals/NonSurgicalPage.vue');
const NursingPage = () => import('@/pages/medicalServices/nursing/NursingPage.vue');
const EvaluationAppointments = () =>
    import('@/pages/medicalServices/evaluations/EvaluationAppointments.vue');
const EvaluationsList = () =>
    import('@/pages/medicalServices/evaluations/components/EvaluationsList.vue');
const MedicalServicesPatients = () =>
    import('@/pages/medicalServices/patients/MedicalServicesPatients.vue');
const SurgeryAppointments = () =>
    import('@/pages/medicalServices/surgeries/SurgeryAppointments.vue');
const CheckOutSurgeryAppointment = () =>
    import('@/pages/medicalServices/surgeries/checkOut/CheckOutSurgeryAppointment.vue');
const AllTreatments = () => import('@/pages/frontdesk/treatments/AllTreatments.vue');
const AssignAssistants = () =>
    import('@/pages/medicalServices/providerAssistants/AssignAssistants.vue');

const medicalServicesRoutes = [
    {
        path: 'medical-services',
        component: RouterView,
        name: 'MedicalServices',
        redirect: (to) => ({ name: 'Massage' }),
        meta: {
            title: 'Medical Services',
            public: false,
            icon: 'HeartIcon',
        },
        children: [
            {
                path: 'massage',
                name: 'Massage',
                component: MassagesPage,
                meta: {
                    public: false,
                    menu: true,
                    title: 'Massage appointments',
                    header: false,
                    permissionsAllOf: [
                        'Permissions.MedicalServices.Massages.AppointmentsSearch',
                        'Permissions.MedicalServices.Massages.CompleteMassageSession',
                        'Permissions.MedicalServices.Massages.SearchCompletedMassageSessions',
                        'Permissions.MedicalServices.Massages.GetPatientStats',
                    ],
                },
            },
            {
                path: 'massage/treatments/:id',
                name: 'MedicalServicesMassageSales',
                component: AllTreatments,
                meta: {
                    menu: false,
                    permissionsAnyOf: ['Permissions.FrontDesk.Sales.Search'],
                },
                props: {
                    routeName: 'Massage',
                    showSlideoverCart: true,
                    checkoutDisable: true,
                },
            },
            {
                path: 'provider-assistants',
                name: 'ProviderAssistants',
                component: AssignAssistants,
                meta: {
                    public: false,
                    menu: true,
                    title: 'Provider assistants',
                    header: false,
                    permissionsAllOf: ['Permissions.MedicalServices.Provider.SearchAssistants'],
                },
            },
            {
                path: 'non-surgical',
                name: 'NonSurgical',
                component: NonSurgicalPage,
                meta: {
                    public: false,
                    menu: true,
                    title: 'No Surgical appointments',
                    header: false,
                    permissionsAllOf: [
                        'Permissions.MedicalServices.NonSurgical.AppointmentsSearch',
                        'Permissions.MedicalServices.NonSurgical.CompleteNonSurgicalSession',
                        'Permissions.MedicalServices.NonSurgical.SearchNonSurgicalAppointments',
                    ],
                },
            },
            {
                path: 'non-surgical/treatments/:id',
                name: 'MedicalServicesNonSurgicalSales',
                component: AllTreatments,
                meta: {
                    menu: false,
                    permissionsAnyOf: ['Permissions.FrontDesk.Sales.Search'],
                },
                props: {
                    routeName: 'NonSurgical',
                },
            },
            {
                path: 'nursing',
                name: 'Nursing',
                component: NursingPage,
                meta: {
                    public: false,
                    menu: true,
                    title: 'Nursing appointments',
                    header: false,
                    permissionsAllOf: ['Permissions.MedicalServices.Nursing.AppointmentsSearch'],
                },
            },
            {
                path: 'nursing/treatments/:id',
                name: 'MedicalServicesNursingSales',
                component: AllTreatments,
                meta: {
                    menu: false,
                    permissionsAnyOf: ['Permissions.FrontDesk.Sales.Search'],
                },
                props: {
                    routeName: 'Nursing',
                },
            },
            {
                path: 'evaluations',
                name: 'Evaluations',
                component: EvaluationAppointments,
                meta: {
                    public: false,
                    menu: true,
                    title: 'Evaluation appointments',
                    header: true,
                    permissionsAnyOf: [
                        'Permissions.MedicalServices.Surgery.SearchEvaluationAppointments',
                    ],
                },
            },
            {
                path: 'evaluation-list/:leadId',
                name: 'EvaluationsList',
                component: EvaluationsList,
                meta: {
                    permissionsAnyOf: [
                        'Permissions.MedicalServices.Surgery.SearchEvaluationAppointments',
                    ],
                },
                props: (route) => ({ leadId: route.params.leadId, fullPage: true }),
            },
            {
                path: 'preop',
                name: 'SurgeryAppointments',
                component: SurgeryAppointments,
                meta: {
                    public: false,
                    menu: true,
                    title: 'PreOp',
                    header: true,
                    permissionsAllOf: [
                        'Permissions.MedicalServices.Surgery.SearchSurgeryAppointments',
                    ],
                },
            },
            {
                path: 'preop/check-out/:id/:patientId',
                name: 'CheckOutSurgeryAppointment',
                component: CheckOutSurgeryAppointment,
                meta: {
                    public: false,
                    title: 'CheckOut',
                    header: true,
                },
            },
            {
                path: 'preop/change-check-out/:id/:patientId',
                name: 'ChangeCheckOutSurgeryAppointment',
                component: CheckOutSurgeryAppointment,
                meta: {
                    public: false,
                    title: 'CheckOut',
                    header: true,
                },
                props: {
                    editable: true,
                },
            },
            {
                path: 'patients',
                name: 'MedicalServicesPatients',
                component: MedicalServicesPatients,
                meta: {
                    public: false,
                    menu: true,
                    title: 'Patients',
                    header: true,
                    permissionsAllOf: ['Permissions.MedicalServices.Leads.Search'],
                },
            },
        ],
    },
];

export default medicalServicesRoutes;
