const messages = {
    addRole: 'Añadir Rol',
    editRole: 'Editar Rol',
    manageRolePermissions: 'Administrar Permisos del Rol',
    roleAddSuccessfully: 'Rol adicionado exitosamente',
    roleEditSuccessfully: 'Rol editado exitosamente',
    roleDeleteSuccessfully: 'Rol eliminado exitosamente',
    confirmDeleteRole: 'Confirmar Eliminación del Rol',
    permissions: 'Permisos',
    roles: 'Roles',
    selectAll: 'Seleccionar Todos',
    unselectAll: 'Deseleccionar Todos',
};

export default messages;
