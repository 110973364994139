const messages = {
    commissionRules: 'Reglas de Comisiones',
    commissionRulesHelpText:
        'Configura las reglas para desencadenar las comisiones de los empleados',
    productOrProcedure: 'Producto / Procedimiento',
    quantity: 'Cantidad',
    commissionType: 'Tipo de Comisión',
    value: 'Valor',
    paymentMethod: 'Método de Pago',
    balanceRange: 'Rango del Balance',
    triggerOnPaymentNum: 'Activar en Pago',
    team: 'Equipo',
    commissions: 'Comisiones',
    debitCard: 'Tarjeta de Débito',
    creditCard: 'Tarjeta de Crédito',
    cash: 'Efectivo',
    accountBalance: 'Saldo de Cuenta',
    anyEmployee: 'Cualquier Empleado',
    noTeam: 'Sin Equipo',
    any: 'Cualquiera',
    noRange: 'Sin Rango',
    employeeName: 'Nombre de Empleado',
    item: 'Elemento',
    teamDrName: 'Nombre del Médico del Equipo',
    searchRules: 'Buscar Reglas',
    employee: 'Empleado',
    percentage: 'Porciento',
    fixed: 'Fijo',
    createNewCommissionRule: 'Crear Nueva Regla',
    createNewCommissionRuleHelpText:
        'Una condición específica bajo la cuál se activará una comisión',
    fromBalance: 'Desde Balance',
    toBalance: 'Hasta Balance',
    commissionRuleAdded: 'Regla agregada satisfactoriamente',
    commissionRuleCreationError: 'Error al crear la regla',
    payment: 'Pago',
    disableComissionRule: 'Desabilitar Regla de Comisión',
    disableComissionRuleHelpText:
        'Esta acción ocasionará que esta regla no sea tenida en cuenta de ahora en adelante para el cálculo de comisiones',
    enableComissionRule: 'Habilitar Regla de Comisión',
    enableComissionRuleHelpText:
        'Esta regla será considerada de ahora en adelante para evaluar las comisiones entrantes',
    isActive: 'Está Activa',
    errorLoadingCommissionableItems: 'Error al cargar artículos comisionables',
    errorLoadingCommissionRules: 'Error al cargar reglas de comisiónn',
    commissionableItems: 'Artículos Comisionables',
    commissionableItem: 'Artículo Comisionable',
    financed: 'Financiado',
    undefinedType: 'Indefinido',
    emptyCommissionRules: 'Aún no hay reglas definidas',
    emptyCommissionRulesHelpText:
        'Comienza definiendo una nueva regla por la que evaluar las comisiones de los empleados',
    firstAssistant: 'Primer Asistente',
    secondAssistant: 'Segundo Asistente',
    anesthesiologist: 'Anestesiólogo',
    surgeryCoordinator: 'Coordinador de Cirugía',
    fromQuantity: 'Desde Cantidad',
    toQuantity: 'Hasta Cantidad',
    comboType: 'Tipo de Combo',
    simple: 'Simple',
    multiple: 'Múltiple',
    combo: 'Combo',
    case: 'Case',
    createNewProcedureCommissionRule: 'Crear Regla de Comisión para Procedimiento',
    createNewItemCommissionRule: 'Crear Regla de Comisión para Producto',
    createNewItemCommissionRuleHelpText: 'Una cierta condición en la que se activará una comisión',
    teamMemberType: 'Miembro Tipo',
    quantityEach: 'Cantidad Para Cada Uno',
    quantityEachHelpText: 'Aplica el rango de cantidad para cada elemento del combo',
    createNewPaymentCommissionRule: 'Crear Nueva Regla de Comisión por Pago',
    createNewPaymentCommissionRuleHelpText: 'Cierta condición bajo la que se pagará una comisión',
    firstPayment: 'Primer Pago',
    fromAmount: 'Desde Cantidad',
    toAmount: 'Hasta Cantidad',
    transferRule: 'Regla de Transferencia',
    transferRuleHelpText: 'Indica que esta regla es de transferencia',
    isTransferRule: 'Regla de Transferencia',
    commission: 'Comisión | Comisiones',
    sourceEvent: 'Evento Fuente',
    noCommissionsForThisPayroll: 'No hay comisiones para este período',
    noCommissionsForThisPayrollHelpText:
        'Las comisiones se originan por eventos configurados en el sistema',
    anyItem: 'Cualquier Elemento',
    itemsRules: 'Reglas de Artículos',
    proceduresRules: 'Reglas de Procedimientos',
    paymentRules: 'Reglas de Pagos',
    itemsRulesExplanatoryText: 'Se aplica a cualquier artículo comisionable',
    proceduresRulesExplanatoryText:
        'Cualquier regla de comisión para procedimientos quirúrgicos y no quirúrgicos',
    paymentRulesExplanatoryText: 'Comisiones aplicadas en los pagos a los clientes',
    changePublishedStatus: 'Cambiar Estado Publicado',
    changeCommissionRulesView: 'Cambiar la vista de reglas de comisión',
    appliedCommissions: 'Comisiones Aplicadas',
    fromDate: 'Desde la Fecha',
    toDate: 'Hasta la Fecha',
    noAppliedCommissions: 'No hay comisiones aplicadas',
    noAppliedCommissionsHelpText: 'Regrese Más Tarde',
    myCommissions: 'Mis Comisiones',
    overTotal: 'Sobre el Total',
    Bundles: 'Bundles',
    addBundle: 'Añadir Bundle',
    editBundle: 'Editar Bundle',
    earningsAndDeductions: 'Ganancias y Deducciones',
    reviewAndPayments: 'Revisión y Pago',
    discount: 'Descuento',
    thirdAssistant: 'Tercer Asistente',
    fourthAssistant: 'Cuarto Asistente',
    massagesOnly: 'Sólo masajes',
    massagesOnlyHelpText: 'Activar sólo en procedimientos de masaje',
    patientPhone: 'Teléfono del paciente',
    appliedOnDate: 'Aplicada en la fecha',
    commissionValue: 'Valor de comisión',
    unspecified: 'No especificado',
    confirmCommissionDelete: '¿Seguro que desea eliminar esta comisión?',
    confirmCommissionDeleteHelpText: 'Eliminar una comisión aplicada es una acción irreversible',
    removeComissionRule: 'Eliminar Regla de Comisión',
    removeComissionRuleHelpText:
        'Esta acción ocasionará que esta regla sea eliminada de ahora en adelante para el cálculo de comisiones',
    SaleCommission: 'Commisiones de Venta',
    ServiceCommissions: 'Comisiones de Servicio',
    itemType: 'Tipo de item',
    nonSurgicalProcedure: 'Procedimiento no quirúrgico',
    surgicalProcedure: 'Procedimiento quirúrgico',
    showingThreeMonthsCommissions: 'Mostrando tres meses de comisiones',
    showingThreeMonthsCommissionsHelpText:
        'Por favor use los filtros para cambiar cualquiera de los límites',
    dynamic: 'Dinámico',
    discountType: 'Tipo de descuento',
};

export default messages;
