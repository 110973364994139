const Procedures = () => import('../pages/settings/procedures/ListProcedures.vue');
const ProceduresDoctor = () => import('../pages/settings/proceduresDoctor/ProceduresDoctor.vue');
const ProductServiceAttribute = () =>
    import('../pages/settings/productServiceAttribute/ProductServiceAttributes.vue');
const SaleProducts = () => import('../pages/settings/saleProducts/SaleProducts.vue');
const AddEditPaymentMethod = () =>
    import('@/pages/settings/paymentMethod/AddEditPaymentMethod.vue');
const PaymentsMethods = () => import('@/pages/settings/paymentMethod/PaymentsMethods.vue');
import { RouterView } from 'vue-router';
const DocumentsTypes = () => import('@/pages/clientProfile/DocumentsTypes.vue');
const AddEditDocumentType = () => import('@/pages/clientProfile/AddEditDocumentType.vue');
const ListConsents = () => import('@/pages/clientProfile/ListConsents.vue');
const EditConsents = () => import('@/pages/clientProfile/EditConsents.vue');
const AppointmentServices = () =>
    import('@/pages/settings/appointmentService/AppointmentServices.vue');
import configRoutes from '@/routes/configuration.routes';

const settingsRoutes = [
    {
        path: 'settings',
        name: 'Settings',
        redirect: (to) => {
            return { path: '/settings/procedures' };
        },
        component: RouterView,
        meta: {
            title: 'Settings',
            public: false,
            icon: 'CogIcon',
        },
        children: [
            ...configRoutes,
            {
                path: 'procedures',
                name: 'SettingsProcedures',
                component: Procedures,
                meta: {
                    menu: true,
                    title: 'Procedures',
                    permissionsAnyOf: ['Permissions.Setting.Procedure.Search'],
                },
            },
            {
                path: 'doctor-procedures',
                name: 'SettingsProceduresDoctor',
                component: ProceduresDoctor,
                meta: {
                    menu: true,
                    title: 'Doctors & Procedures',
                    permissionsAnyOf: ['Permissions.Setting.ProceduresDoctor.Search'],
                },
            },
            {
                path: 'product-service-attributes',
                name: 'SettingsProductServiceAttribute',
                component: ProductServiceAttribute,
                meta: {
                    menu: true,
                    title: 'Products Attributes',
                    permissionsAnyOf: ['Permissions.Setting.ProductServiceAttribute.Search'],
                },
            },
            {
                path: 'sale-product-service',
                name: 'SettingsSaleProducts',
                component: SaleProducts,
                meta: {
                    menu: true,
                    title: 'Sale Products',
                    permissionsAnyOf: ['Permissions.Setting.SaleProductService.Search'],
                },
            },
            {
                path: 'payments-methods',
                name: 'PaymentMethod',
                component: PaymentsMethods,
                meta: {
                    menu: true,
                    title: 'Payments Methods',
                    permissionsAnyOf: ['Permissions.Setting.PaymentMethod.Search'],
                },
            },
            {
                path: 'payments-methods/add',
                name: 'AddEditPaymentMethod',
                component: AddEditPaymentMethod,
            },
            {
                path: 'documents-types',
                name: 'DocumentsTypes',
                component: DocumentsTypes,
                meta: {
                    menu: true,
                    title: 'Documents Types',
                    permissionsAnyOf: ['Permissions.ClientProfile.DocumentType.Search'],
                },
            },
            {
                path: 'documents-types/add',
                name: 'AddEditDocumentType',
                component: AddEditDocumentType,
            },
            {
                path: 'consents',
                name: 'Consents',
                component: ListConsents,
                meta: {
                    menu: true,
                    title: 'Consents',
                    permissionsAnyOf: ['Permissions.ClientProfile.Consent.Search'],
                },
            },
            {
                path: 'consents/edit/:id',
                name: 'EditConsents',
                component: EditConsents,
            },
            {
                path: 'appointment-services',
                name: 'AppointmentServices',
                component: AppointmentServices,
                meta: {
                    menu: true,
                    title: 'Appointment Services',
                    permissionsAnyOf: ['Permissions.Setting.AppointmentServices.Search'],
                },
            },
        ],
    },
];

export default settingsRoutes;
