const messages = {
    idPatientName: 'ID/Nombre',
    date: 'Fecha',
    adjustmentType: 'Tipo de Ajuste',
    amount: 'Cantidad',
    notBalanceAdjustment: 'No hay ajustes de balance disponibles...',
    motive: 'Motivo',
    adjustedBy: 'Ajustado Por',
    balanceAdjustments: 'Ajustes de Balance',
    balanceAdjustmentsHelpText: 'Ajustes de Balance de los Pacientes',
    Adding: 'Sumando',
    Substracting: 'Restando',
    updateBalanceTo: 'Actualizar Saldo a ',
    updateBalance: 'Actualizar Saldo',
    operation: 'Operación',
    subtractionOperations: 'Balance: Operaciones de Resta',
    additionOperations: 'Balance: Operaciones de Suma',
    unauthorizedBalanceAdjustments:
        'Usted no tiene autorización para ver estadísticas de ajustes de balance',
    nonComments: 'No hay comentarios para mostrar',
    anyPatient: 'Cualquier Paciente',
    balanceUpdatedSuccessfully: 'Ajuste de balance creado satisfactoriamente',
    motiveDescription: 'Descripción del Motivo',
    unauthorizedStatistics: 'Estadísticas no Autorizadas',
    newAdjustment: 'Nuevo Ajuste',
    representative: 'Representante',
    logo: 'Logo',
    assignedAsSeller: 'fue asignado(a) como vendedor por',
    paymentsFrom: 'Pagos Desde',
    paymentsUpTo: 'Pagos Hasta',
    unknown: 'Desconocido',
    appointmentType: 'Tipo de Cita',
    surgery: 'Cirugía',
    undefined: 'Indefinido',
    Scheduled: 'Programado',
    Confirmed: 'Confirmado',
    Done: 'Hecho',
    Pending: 'Pendiente',
    PreOp: 'Preoperatorio',
    Checked: 'Chequeado',
    Cancelled: 'Cancelado',
    Rejected: 'Rechazado',
    Surgery: 'Cirugía',
    Unavailable: 'Indisponible',
    addToGiftCard: 'Adicionar a credito de balance',
    addToBalance: 'Adicionar al balance',
    refundsCreatedFrom: 'Reembolsos Creados Desde',
    refundsCreatedUpTo: 'Reembolsos Creados Hasta',
    lockPatient: 'Bloquear Paciente',
    unlockPatient: 'Desbloquear Paciente',
    lockedPatient: 'Paciente Bloqueado',
    chargeback: 'Contracargo',
    chargebacks: 'Contracargos',
    unauthorizedChargebacksList: 'Usted no tiene autorización para ver la lista decontracargos',
    addCategory: 'Añadir Categoría',
    addNewCategory: 'Adicionar Nueva Categoría',
    editNewCategory: 'Editar Nueva Categoría',
    category: 'Categoría',
    spentMoneyByExternalProvider: 'Dinero Gastado Por Proveedores Externos',
    spentMoneyByExternalProvidersCategories:
        'Dinero Gastado Por Categorías de Proveedores Externos',
    spentMoneyByExternalProviderDescription:
        'Genera un informe de cuánto dinero se gastó en proveedores externos',
    spentMoneyByExternalProvidersCategoriesDescription:
        'Genera un informe de cuánto dinero se gastó en categorías de proveedores externos',
    anyExternalProvider: 'Cualquier Proveedor Externo',
    anyExternalProviderCategory: 'Cualquier Categoría',
    anyDepartment: 'Cualquier Departamento',
};

export default messages;
