import { PageFilters } from '@/api/common/request/common.request';

export interface GetAmountOfSalesBySeller extends PageFilters {
    minimumSales?: number;
    maximumSales?: number;
    initialDateTime?: string;
    endDateTime?: string;
    sellerName?: string;
    departmentId?: string;
    active?: boolean;
}

export interface GetSalesByPatient extends PageFilters {
    patient?: string;
    seller?: string;
    initialDateTime?: string;
    endDateTime?: string;
    patientId?: number;
}

export interface GetAccountsReceivable extends PageFilters {
    patientId?: number;
    patient?: string;
    seller?: string;
    initialDateTime?: string;
    endDateTime?: string;
    debtRange?: number;
}

export interface GetGeneralSurgery {
    initialDateTime?: string;
    endDateTime?: string;
    departmentTagId?: string;
}
export const QuoteVersion = {
    DEFAULT: 1,
    CLAIM: 2,
    MULTIPLE: 3,
    FINANCED: 4,
} as const;
