const messages = {
    seller: 'Vendedor',
    location: 'Ubicación del Dispositivo',
    deviceName: 'Nombre del Dispositivo',
    advertisingScreens: 'Pantallas Publicitarias',
    manageDevicesToShowAdversiting: 'Gestionar los dispositivos para mostrar publicidad',
    addAdvertisingTV: 'Añadir Publicidad al TV',
    noContentPlanned: 'Sin contenido planeado',
    someContentPlanned: 'Algo de contenido planeado',
    addNewAdvertisingTV: 'Añadir un Nuevo TV p Publicitario',
    deviceSuccessfulyDeleted: 'Dispositivo eliminado con éxito',
    confirmDeleteDevice: 'Confirmar Eliminación del Dispositivo',
    showcase: 'Escaparate',
    destinationDate: 'Fecha de Destino',
    editDevice: 'Editar Dispositivo',
    updatePlaylist: 'Actualizar Lista de Reproducción',
    copyThisSchedule: 'Copiar este horario',
    activateShowcaseView: 'Activar Vista de Escaparate',
    confirmCopyOfPlaylist: 'Confirmar copia de lista de reproducción',
    copyThePlaylistOf: 'Copiar la lista de reproducción de ',
    errorCopyingPlaylist: 'Error copiando la lista de reproducción',
    errorSavingPlaylist: 'Error la lista de reproducción',
    playlistSaved: 'Lista de reproducción guardada',
    exitShowcaseMode: 'Salir de Modo Escaparate ',
    errorLoadingDeviceData: 'Error cargando los datos del dispositivo',
    tags: 'Etiquetas',
    title: 'Título',
    file: 'Archivo',
    assetType: 'Tipo de Recurso',
    assetAddedSuccessfully: 'Archivo adicionado exitosamente',
    youWantToDeleteAsset: '¿Está seguro que desea eliminar éste archivo?',
    assets: 'Recursos',
    addAsset: 'Añadir Recurso',
    searchByTitle: 'Buscar por Título',
    searchByDescription: 'Buscar por Descripción',
    searchByYear: 'Buscar por Año',
    searchByMonth: 'Buscar por Mes',
    searchByTag: 'Buscar por Etiqueta',
    searchByType: 'Buscar por Tipo',
    detail: 'Detalle',
    download: 'Descargar',
    upload: 'Subir',
    image: 'Imagen',
    video: 'Video',
    audio: 'Audio',
    document: 'Documento',
    uploadDate: 'Fecha de Carga',
    selectAsset: 'Selecione un Recurso',
    browserDoesNotSupportVideoTag: 'Su navegador no soporta la etiqueta de vídeo.',
    viewDetailsFor: 'Ver Detalles para ',
    browserDoesNotSupportAudioElement: 'Su navegador no soporta el elemento de audio.',
    detailsFor: 'Detalles para ',
    confirmDeleteAsset: 'Confirmar eliminación del recurso',
    notFound: 'No encontrado',
    preview: 'Vista Previa',
    downloadLink: 'Enlace de Descarga',
    assetUpdatedSuccessfully: 'Recurso actualizado exitosamente',
    messageLine: 'Línea de Mensaje',
    generateContentFor: 'Generar Contenido para ',
    post: 'Publicación',
    ad: 'Anuncio',
    both: 'Ambos',
    contentType: 'Tipo de Contenido',
    campaigns: 'Campañas',
    campaign: 'Campaña',
    addCampaign: 'Añadir Campaña',
    searchByContentType: 'Buscar por Tipo de Contenido',
    searchByContentGeneratedFor: 'Buscar por Contenido Generado para',
    typeOfContract: 'Tipo de Contrato',
    influencer: 'Influencer',
    sponsorship: 'Patrocinio',
    advertisingServicesAgreement: 'Acuerdo de Servicios Publicitarios',
    contractObjective: 'Objetivo del Contrato',
    contractDocument: 'Documento de Contrato',
    contracts: 'Contratos',
    addContract: 'Añadir Contrato',
    searchByName: 'Buscar por Nombre',
    searchByStartDate: 'Buscar por Fecha Inicial',
    searchByExpirationDate: 'Buscar por Fecha de Caducidad',
    objective: 'Objetivo',
    firstContactAddedSuccessfully: 'Primer Contacto agregado con éxito',
    firstContactUpdatedSuccessfully: 'Primer Contacto actualizado con éxito',
    firstContacts: 'Primeros Contactos',
    firstContact: 'Primer Contacto',
    addFirstContact: 'Añadir Primer Contacto',
    addNewContact: 'Añadir Nuevo Contacto',
    editContact: 'Editar Contacto',
    pleaseSelectOne: 'Por favor seleccione uno',
    customerSources: 'Fuentes de Clientes',
    preferredDoctor: 'Doctor Preferido',
    comment: 'Comentario',
    assign: 'Asignar',
    manually: 'Manualmente',
    automatically: 'Automáticamente',
    assignedTo: 'Asignado a',
    createNewAppointment: 'Crear Nueva Cita',
    addNewTask: 'Añadir Nueva Tarea',
    editTask: 'Editar Tarea',
    share: 'Compartir',
    unshare: 'Dejar de Compartir',
    shareCalendar: 'Compartir Calendario',
    sharingWith: 'Compartir con',
    subscribedTo: 'Suscrito A',
    sourceAddedSuccessfully: 'Fuente añadida con éxito',
    sourceUpdatedSuccessfully: 'Fuente editada con éxito',
    addCustomerSource: 'Añadir Fuente del Cliente',
    addNewSource: 'Añadir Nueva Fuente',
    editSource: 'Editar Fuente',
    noAdvertisingDevicesConfigured: 'No hay dispositivos publicitarios configurados',
    deviceStatus: 'Estado del Dispositivo',
    currentStatus: 'Estado Actual',
    offline: 'Desconectado',
    noContent: 'Sin Contenido',
    playingNow: 'Reproduciendo Ahora',
    Offline: 'Desconectado',
    Online: 'Conectado',
    NoContent: 'Sin Contenido',
    Playing: 'Reproduciendo',
    tag: 'Etiqueta',
    year: 'Año',
    month: 'Mes',
    leadsPerDay: 'Leads Captados por Día',
    publicityStatistics: 'Estadísticas de Publicidad',
    leadsStatisticsBySourceFirstContact: 'Estadísticas por Fuente y Primer Contacto',
    existingLeadsAssigned: 'Leads existentes asignados',
    newLeadsAssigned: 'Nuevos leads asignados',
    emergencyContact: 'Contacto de Emergencia',
    emergencyContactMessage: 'Éste paciente no tiene un contacto de emergencia.',
    Unknown: 'Desconocido',
    secondaryPhone: 'Teléfono Secundario',
    inProcess: 'Procesándose',
    processedBy: 'Procesado por',
    conflictWith: 'En conflicto con',
    resolveConflict: 'Resolver Conflicto',
    processingByOtherUserError: 'El cliente potencial está siendo procesado por otro usuario.',
    existingLeadConflictError: 'El nuevo cliente potencial tiene conflicto con uno existente',
    existingLead: 'Cliente potencial existente',
    incomingLead: 'Nuevo cliente potencial',
    samePerson: 'Misma persona',
    samePersonWarningMessage:
        'Esta accion elimina el nuevo cliente potencial y mantiene el existente sin modificaciones. ¿Desea Continuar?',
    differentPersons: 'Diferentes personas',
    differentPersonsWarningMessage:
        'Esta acción crea un cliente potencial y elimina el número de teléfono del existente. ¿Desea continuar?',
    withConflict: 'En conflicto con un cliente potencial existente',
    processing: 'Procesando',
    uploading: 'cargando...',
    // Tutorials
    tutorials: 'Ayuda',
    createTutorial: 'Crear Nueva Tutorial',
    youWantToDeleteTutorial: '¿Está seguro que desea eliminar éste archivo?',
    tutorialsErrorTitle: 'Error!',
    tutorialsErrorMessage: 'Algo salió mal.',
    tutorialsTryAgain: 'Intentar otra vez',
    tutorialsGetStarted: 'Seleccione un elemento para comenzar.',
    tutorialsShowMore: 'Mostrar más',
    tutorialsMoreFrom: 'Más ayuda de',
    tutorialsReorder: 'Reordenar',
    tutorialNewSortOrder: 'Nueva orden',
    // Reports
    publicityCustomerSourcesSold: 'Publicidad fuentes de clientes vendidas',
    publicityCustomerSourcesSoldDescription:
        'Vea cuántos NUEVOS LEADS se vendieron en un período de tiempo por fuente del cliente',
    publicityCustomerSourcesSoldReassignee: 'Publicidad fuentes de clientes reasignados',
    publicityCustomerSourcesSoldReassigneeDescription:
        'Vea cuántas redes sociales reasignadas se vendieron en un período de tiempo por fuente de cliente',
};

export default messages;
