import { createI18n } from 'vue-i18n';
import messages from '@/locales';
import { getBrowserLocale, supportedLocalesInclude } from '@/composables/common';

const numberFormats = {
    'en-US': {
        currency: {
            style: 'currency',
            currency: 'USD',
        },
        decimal: {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        },
        percent: {
            style: 'percent',
            useGrouping: false,
        },
    },
};

const getStaringLocale = () => {
    const browserLocale = getBrowserLocale();
    if (supportedLocalesInclude(browserLocale)) {
        return browserLocale;
    } else return import.meta.env.VITE_APP_I18N_LOCALE || 'en';
};

const i18n = createI18n({
    legacy: false,
    locale: getStaringLocale(), // set locale
    fallbackLocale: 'en', // set fallback locale
    messages, // set locale messages
    numberFormats,
});

export default i18n;
