//import { GetLeadRequest, GetLeadsRequest, AddEditLeadRequest } from "../api/publicity/request/leads.request";
import { GetAssetsRequest, GetAssetRequest } from '../api/publicity/request/assets.request';
import {
    GetContractsRequest,
    GetContractRequest,
    AddEditContractRequest,
} from '../api/publicity/request/contracts.request';
import {
    GetCampaignsRequest,
    GetCampaignRequest,
} from '../api/publicity/request/campaigns.request';
//import { GetPlanningTasksRequest, GetPlanningTaskRequest, AddEditPlanningTaskRequest } from "../api/publicity/request/planningTasks.request";
//import { GetLeadsResponse, GetLeadResponse } from "../api/publicity/response/leads.response";
import { GetAssetsResponse, GetAssetResponse } from '../api/publicity/response/assets.response';
import {
    GetContractsResponse,
    GetContractResponse,
} from '../api/publicity/response/contracts.response';
import {
    GetCampaignsResponse,
    GetCampaignResponse,
} from '../api/publicity/response/campaigns.response';
//import { GetPlanningTasksResponse, GetPlanningTaskResponse } from "../api/publicity/response/planningTasks.response";
import { Result } from '../api/common/response/common.response';
import { HttpClient } from '@/services/HttpClient';

//// Leads
//async function getLeads(request: GetLeadsRequest) {
//    return await HttpClient.get<GetLeadsResponse>("Leads", { params: request });
//}

//async function getLead(request: GetLeadRequest) {
//    return await HttpClient.get<GetLeadResponse>(`Leads/${request.id}`);
//}

//async function createLead(request: AddEditLeadRequest) {
//    return await HttpClient.post<GetLeadResponse>("Leads", request);
//}

//async function editLead(request: AddEditLeadRequest) {
//    return await HttpClient.put<Result>(`Leads/${request.id}`, request);
//}

// Assets
async function getAssets(request: GetAssetsRequest) {
    return await HttpClient.get<GetAssetsResponse>('Assets', { params: request });
}

async function getAsset(request: GetAssetRequest) {
    return await HttpClient.get<GetAssetResponse>(`Assets/${request.id}`);
}

// Contracts
async function getContracts(request: GetContractsRequest) {
    return await HttpClient.get<GetContractsResponse>('Contracts', { params: request });
}

async function getContract(request: GetContractRequest) {
    return await HttpClient.get<GetContractResponse>(`Contracts/${request.id}`);
}

async function createContract(request: AddEditContractRequest, xRequestId?: string) {
    return await HttpClient.post<GetContractResponse>('Contracts', request, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'x-requestid': xRequestId,
        },
    });
}

async function editContract(request: AddEditContractRequest, xRequestId?: string) {
    return await HttpClient.put<Result>(`Contracts/${request.id}`, request, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'x-requestid': xRequestId,
        },
    });
}

// Campaigns
async function getCampaigns(request: GetCampaignsRequest) {
    return await HttpClient.get<GetCampaignsResponse>('Campaigns', { params: request });
}

async function getCampaign(request: GetCampaignRequest) {
    return await HttpClient.get<GetCampaignResponse>(`Campaigns/${request.id}`);
}

//// Planning Tasks
//async function getPlanningTasks(request: GetPlanningTasksRequest) {
//    return await HttpClient.get<GetPlanningTasksResponse>("PlanningTasks", { params: request });
//}

//async function getPlanningTask(request: GetPlanningTaskRequest) {
//    return await HttpClient.get<GetPlanningTaskResponse>(`PlanningTasks/${request.id}`);
//}

//async function createPlanningTask(request: AddEditPlanningTaskRequest) {
//    return await HttpClient.post<GetPlanningTaskResponse>("PlanningTasks", request);
//}

//async function editPlanningTask(request: AddEditPlanningTaskRequest) {
//    return await HttpClient.put<Result>(`PlanningTasks/${request.id}`, request);
//}

export default {
    //getLeads,
    //getLead,
    //createLead,
    //editLead,

    getAssets,
    getAsset,

    getContracts,
    getContract,
    createContract,
    editContract,

    getCampaigns,
    getCampaign,

    //getPlanningTasks,
    //getPlanningTask,
    //createPlanningTask,
    //editPlanningTask,
};
