const messages = {
    procedure: 'Procedimiento',
    procedures: 'Procedimientos',
    addProcedure: 'Añadir Procedimiento',
    isSurgical: 'Es Quirúrgico',
    addNewProcedure: 'Adicionar Nuevo Procedimiento',
    addNewDoctorProcedure: 'Adicionar Nuevo Procedimiento Médico',
    editProcedure: 'Editar Procedimiento',
    editDoctorProcedure: 'Editar Procedimiento Médico',
    theProcedureIsSurgical: 'El Procedimiento es Quirúrgico.',
    surgical: 'Quirúrgico',
    notSurgical: 'No Quirúrgico',
    procedureAddedSuccessfully: 'Procedimiento adicionado exitosamente',
    procedureEditedSuccessfully: 'Procedimiento editado exitosamente',
    doctorsAndProcedures: 'Doctores y Procedimientos',
    doctorsProceduresDeletedSuccessfully: 'Procedimiento médico eliminado exitosamente',
    confirmDeleteDoctorsProcedures: 'Confirmar eliminación de procedimiento médico',
    noticeDelete: 'Ésta acción no se puede revertir y algunos datos podrían perderse.',
    errorLoadingEmployees: 'Error cargando la lista de empleados',
    Accessories: 'Accesorios',
    requiredAccessories: 'Accesorios requeridos',
    promotionPrice: 'Precio de Promoción',
    hasPromotion: 'Tiene Promoción',
    doctorProcedureHasPromotion: 'El procedimiento del doctor tiene una promoción.',
    doctorProcedureGeneralAnesthesia: 'El procedimiento médico con anestesia general.',
    pleaseSelectOne: 'Por favor, seleccione uno',
    appointmentServices: 'Servicios de Citas',
    addNewAppointmentService: 'Añadir Nuevo Servicio de Citas',
    addAppointmentService: 'Añadir Servicio de Citas',
    editAppointmentService: 'Editar Servicio de Citas',
    electronic: 'Electrónico',
    canBeUseElectronic: 'Puede ser usado electrónicamente',
    doctorProcedureAddedSuccessfully: 'Procedimiento del Doctor añadido con éxito',
    emptyAccessories: 'Aún no hay accesorios adicionados',
    emptyAccessoriesHelpText: 'Comienza adicionando nuevos accesorios al procedimiento del doctor',
    emptyConsumables: 'Aún no hay consumibles adicionados',
    emptyConsumablesHelpText: 'Comienza adicionando nuevos consumibles al procedimiento del doctor',
    theAccessoryIsRequired: 'El accesorio es requerido',
    addNewConsumable: 'Adicionar nuevo consumible',
    addNewAccessory: 'Adicionar nuevo accesorio',
    addNewAccessoryHelpText: 'Adicionar nuevo accesorio al procedimiento del doctor',
    addNewConsumableHelpText: 'Adicionar nuevo consumible al procedimiento del doctor',
    confirmDeleteStructure: '¿Estás seguro(a) que deseas eliminar éste procedimiento?',
    deleteProcedure: 'Eliminar Procedimiento',
    startAmount: 'Monto inicial',
    endAmount: 'Monto final',
    addCosmetologyPromotion: 'Adicionar promociones de Cosmetología',
    editCosmetologyPromotion: 'Editar promociones de Cosmetología',
};

export default messages;
