import { RouterView } from 'vue-router';
import leadRoutes from './publicity/leads.routes';
import firstContactsRoutes from './publicity/firstContacts.routes';
import customerSourceRoutes from './publicity/sources.routes';
import assetRoutes from './publicity/assets.routes';
import contractRoutes from './publicity/contracts.routes';
import campaignRoutes from './publicity/campaigns.routes';
import advertisingRoutes from './publicity/advertising.routes';
import tutorialRoutes from './publicity/tutorials.routes';

const publicityRoutes = [
    {
        path: 'publicity',
        name: 'Publicity',
        redirect: () => {
            return { path: '/publicity/leads' };
        },
        component: RouterView,
        meta: {
            title: 'Publicity',
            public: false,
            icon: 'SparklesIcon',
        },
        children: [
            ...leadRoutes,
            ...advertisingRoutes,
            ...firstContactsRoutes,
            ...customerSourceRoutes,
            ...assetRoutes,
            ...contractRoutes,
            ...campaignRoutes,
            ...tutorialRoutes,
        ],
    },
];

export default publicityRoutes;
