const CustomerSources = () => import('../../pages/publicity/sources/CustomerSources.vue');
const AddCustomerSource = () => import('../../pages/publicity/sources/AddCustomerSource.vue');
const EditCustomerSource = () => import('../../pages/publicity/sources/EditCustomerSource.vue');

const customerSourceRoutes = [
    {
        path: 'sources',
        name: 'PublicitySources',
        component: CustomerSources,
        meta: {
            menu: true,
            title: 'Customer Source',
            permissionsAnyOf: ['Permissions.Publicity.CustomerSources.Search'],
        },
    },
    {
        path: 'sources/add',
        name: 'PublicitySourceAdd',
        component: AddCustomerSource,
    },
    {
        path: 'sources/edit/:id',
        name: 'PublicitySourceEdit',
        component: EditCustomerSource,
    },
];

export default customerSourceRoutes;
