import { defineStore } from 'pinia';
import axios from 'axios';
import { GetAnyAdminResponse } from '../api/identity/response/account.response';
import { HttpClient } from '@/services/HttpClient';
import {
    GetApplicationUserResponse,
    GetApplicationUsersResponse,
} from '../api/identity/response/users.response';
import {
    ApplicationUsersRequest,
    RegisterApplicationUsersRequest,
    ResetApplicationUsersPasswordRequest,
    UpdateApplicationUsersRequest,
} from '../api/identity/request/users.request';
import { IResult, Result } from '../api/common/response/common.response';
import i18n from '../plugins/i18n';
import { setLocale } from '@vee-validate/i18n';

interface ConfirmationOptions {
    title?: string;
    text?: string;
    severity?: 'error' | 'info' | 'warning';
    confirmText?: string;
    cancelText?: string;
}

export const useMainStore = defineStore('main', {
    state: () => {
        return {
            busy: false,
            locale: import.meta.env.VITE_APP_I18N_LOCALE,
            error: '' as string,
            globalDialog: {
                options: {
                    title: 'You must confirm this action',
                    text: 'Are you sure? This may come with irreversible actions',
                    severity: 'error',
                    cancelText: i18n.global.t('cancel'),
                    confirmText: i18n.global.t('confirm'),
                } as ConfirmationOptions,
                open: false,
                cooldown: 0,
                followUpAction: () => {
                    return;
                },
                cancelAction: () => {
                    return;
                },
            },
        };
    },
    actions: {
        setLoading(loading: boolean) {
            this.busy = loading;
        },
        setLocale(locale: string) {
            this.locale = locale;
            i18n.global.locale.value = this.locale;
            setLocale(this.locale);
        },
        async requestWrapper(axiosRequest) {
            this.cleanAll();
            this.busy = true;
            try {
                const result = await axiosRequest();
                if (result.data.success) {
                    this.cleanAll();
                    return result.data.data ?? true;
                } else {
                    this.error = result.data.message ?? '';
                }
                this.busy = false;
            } catch (e) {
                this.busy = false;
                if (axios.isAxiosError(e) && e.response?.data) {
                    {
                        const data = e.response.data as IResult;
                        this.error = data.message;
                        throw new Error(this.error);
                    }
                } else {
                    this.error = e as string;
                    throw new Error(this.error);
                }
            }
        },
        cleanAll() {
            this.busy = false;
            this.error = '';
        },
        getData(request) {
            const formData = new FormData();
            Object.entries(request).forEach((e) => {
                const [key, val] = e;
                if (Array.isArray(val)) {
                    val.forEach((v, i) => {
                        if (v instanceof Object) {
                            Object.entries(v).forEach((el) => {
                                const [key1, val1] = el;
                                formData.append(`${key}[${i}].${key1}`, val1 as string | Blob);
                            });
                        } else formData.append(`${key}[${i}]`, v);
                    });
                } else {
                    formData.append(key, val as string | Blob);
                }
            });
            return formData;
        },
        async getAnyAdmin() {
            return await this.requestWrapper(async () => {
                return HttpClient.get<GetAnyAdminResponse>('Account/GetAnyAdmin');
            });
        },
        async registerApplicationUser(userRequest: RegisterApplicationUsersRequest) {
            return await this.requestWrapper(async () => {
                return HttpClient.post<Result>('Users', userRequest);
            });
        },
        async getApplicationUsers(usersFilters: ApplicationUsersRequest) {
            return await this.requestWrapper(async () => {
                return HttpClient.get<GetApplicationUsersResponse>('Users', {
                    params: usersFilters,
                });
            });
        },
        async getApplicationUser(userId: string) {
            return await this.requestWrapper(async () => {
                return HttpClient.get<GetApplicationUserResponse>(`Users/${userId}`);
            });
        },
        async updateApplicationUser(userRequest: UpdateApplicationUsersRequest) {
            return await this.requestWrapper(async () => {
                return HttpClient.put<Result>(`Users/${userRequest.id}`, userRequest);
            });
        },
        async resetApplicationUserPassword(resetRequest: ResetApplicationUsersPasswordRequest) {
            return HttpClient.put<Result>(`Users/${resetRequest.id}/ResetPassword`, resetRequest);
        },
        async getRoles() {
            return await this.requestWrapper(async () => {
                return HttpClient.get<GetApplicationUsersResponse>('Roles');
            });
        },
        confirmAction(followUpAction: () => void, opts?: ConfirmationOptions): void {
            this.$patch({
                globalDialog: {
                    open: true,
                    options: opts,
                    followUpAction: followUpAction,
                    cooldown: undefined,
                },
            });
        },
        confirmActionWithCooldown(
            followUpAction: () => void,
            cooldown: number,
            opts?: ConfirmationOptions
        ): void {
            this.$patch({
                globalDialog: {
                    open: true,
                    options: opts,
                    followUpAction: followUpAction,
                    cooldown: cooldown,
                },
            });
            const interval = setInterval(() => {
                if (this.globalDialog.cooldown === 0) {
                    clearInterval(interval);
                    this.$patch({
                        globalDialog: {
                            cooldown: undefined,
                        },
                    });
                } else {
                    this.$patch({
                        globalDialog: {
                            cooldown: this.globalDialog.cooldown - 1,
                        },
                    });
                    console.log(this.globalDialog.cooldown);
                }
            }, 1000);
        },
        confirmActionWithCancel(
            followUpAction: () => void,
            opts?: ConfirmationOptions,
            cancelAction?: () => void
        ): void {
            this.$patch({
                globalDialog: {
                    open: true,
                    options: opts,
                    followUpAction: followUpAction,
                    cancelAction: cancelAction,
                    cooldown: undefined,
                },
            });
        },
    },
    getters: {
        getLocale: (state) => state.locale,
    },
});
