const messages = {
    saveTheme: 'Save Theme',
    noteCompanyForm: 'This information will not be displayed publicly.',
    website: 'Website',
    primaryColor: 'Primary Color',
    secondaryColor: 'Secondary Color',
    yourCompanyLogo: 'Your Company Logo',
    yourCompanyImage: 'Your Company Image',
    createOccupation: 'Create Occupation',
    openOptions: 'Open Options',
    firstExplanatoryTextOccupation: 'Base Parameters and Values',
    secondaryExplanatoryTextOccupation: 'Add or remove values to fit your company needs.',
    thirdExplanatoryTextOccupation: 'Mark this in case this occupation is a manager.',
    fourthExplanatoryTextOccupation: 'ie. Surgery Assistant',
    fifthExplanatoryTextOccupation: 'Some description of the occupation if necessary',
    itIsManager: "It's a Manager",
    occupationName: 'Occupation Name',
    signIn: 'Sign in',
    signInAccount: 'Sign in to your account',
    emailAddress: 'Email Address',
    forgotYourPassword: 'Forgot your password?',
    create: 'Create',
    update: 'Update',
    occupations: 'Occupations',
};

export default messages;
