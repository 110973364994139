const messages = {
    editIncidence: 'Editar Incidencia',
    addIncidence: 'Adicionar Incidencia',
    incidenceUpdatedSuccessfully: 'Incidencia editada satisfactoriamente',
    confirmDeleteIncidence: 'Confirmar Eliminación de la Incidecia',
    incidenceAddedSuccessfully: 'Incidencia adicionada exitosamente',
    incidenceDeletedSuccessfully: 'Incidencia eliminada exitosamente',
    incidenceAssignedSuccessfully: 'Incidencia asignada exitosamente',
    assignIncidence: 'Asignar Incidencia',
    unauthorizedEmployeeList: 'Usted no tiene autorización para asignar empleados',
    unauthorizedIncidenceList: 'Usted no tiene autorización para ver la lista de incidencias',
    assignToEmployee: 'Asignar a Empleado',
    Complaint: 'Queja/Sugerencia',
    Incentive: 'Incentivo',
    Refund: 'Reembolso',
    FixSurgery: 'Arreglar la Cirugía',
    GiftCard: 'Crédito de Balance',
    CreditBalance: 'Crédito de Balance',
    requestingUser: 'Usuario Solicitante',
    compensations: 'Compensaciones',
    process: 'Procesar',
    approvedCompensation: 'Compensación Aprobada',
    requiresCompensation: 'Requiere Compensación',
    desiredCompensation: 'Compensación Deseada',
    compensation: 'Compensación',
    incidenceType: 'Tipo de Incidencia',
    processCompensationSuccessfully: 'Compensación procesada exitosamente',
    Requested: 'Solicitado',
    compensationType: 'Tipo de Compensación',
    compensationRequest: 'Solicitar Compensación',
    solve: 'Resolver',
    processCompensation: 'Procesar Compensación',
    resolvingActions: 'Resolución de Acciones',
    solveIncidence: 'Resolver Incidencia',
    customerSatisfaction: 'Satisfacción del Cliente',
    valuationOfMotives: 'Valoración de Motivos',
    approved: 'Aprobada',
    denied: 'Denegada',
    amountApproved: 'Monto Aprobado',
    incidenceClosedSuccessfully: 'Incidencia cerrada exitosamente',
    closeIncidence: 'Cerrar Incidencia',
    observations: 'Observaciones',
    compensationRequestDetails: 'Detalles de la Solicitud de Compensación',
    incidenceId: 'ID Incidencia',
    requestDetails: 'Detalles de la Solicitud',
    addAmountToGiftCard: 'Adicionar Monto al Crédito de Balance',
    mountAddedSuccessfully: 'Monto adicionado exitosamente',
    giftCard: 'Crédito de Balance',
    close: 'Cerrar',
    incentiveAddedSuccessfully: 'Incentivo adicionado exitosamente',
    complaintAddedSuccessfully: 'Queja/Sugerencia adicionada exitosamente',
    incidenceData: 'Datos de la Incidencia',
    incidenceDataHelpText: 'Datos Generales de la Incidencia',
    patientData: 'Datos del Paciente',
    patientDetailsHelpText: 'Datos Generales del Paciente que Reporta la Incidencia',
    approve: 'Aprobar',
    deny: 'Denegar',
    noticeApproveSolutionComplaint:
        'Si apruebas ésta solución, pasará automáticamente a contabilidad para su procesamiento.',
    noticeApproveSolutionIncentive: 'Estás seguro que deseas aprobar ésta solución.',
    confirmApproveSolution: 'Confirmar Aprobación de la Solución',
    solutionApprovedSuccessfully: 'Solución aprobada exitosamente',
    noticeDenySolutionComplaint:
        'Si deniegas ésta solución, no se podrá procesar la compensación en contabilidad.',
    noticeDenySolutionIncentive: 'Estás seguro que deseas denegar ésta solución.',
    confirmDenySolution: 'Confirmar Denegación de la solución',
    solutionDeniedSuccessfully: 'Solución denegada exitosamente',
    compensationCreatedBy: 'Compensación Creada Por',
    paymentMethod: 'Método de Pago',
    processingDate: 'Fecha de Procesamiento',
    refunds: 'Reembolso',
    reasonsForRejection: 'Razones de la Denegación',
    paymentDate: 'Fecha de Pago',
    pending: 'Pendiente',
    reviewed: 'Revisado',
    planned: 'Planificado',
    paid: 'Pagado',
    cancelled: 'Cancelado',
    confirmScheduleRefund: 'Confirmar la autorización del reembolso',
    noticeScheduleRefund: '¿Usted está seguro que desea autorizar el reembolso?',
    refundAuthorizedSuccessfully: 'Reembolso autorizado satisfactoriamente',
    payRefund: 'Pagar Reembolso',
    payRefundSuccessfully: 'Reembolso pagado satisfactoriamente',
    refundConfirmation: 'Confirmación de Reembolso',
    generalRemarks: 'Observaciones Generales',
    incidenceCreationDate: 'Fecha de la Incidencia',
    refundStatus: 'Estado del Reembolso',
    refundCreationDate: 'Fecha del Reembolso',
    refundAmount: 'Monto del Reembolso',
    schedulePayment: 'Programar Pago',
    scheduleRefundSuccessfully: 'Reembolso programado satisfactoriamente',
    byCreationDate: 'Fecha del Reembolso',
    byIncidenceCreationDate: 'Fecha de la Incidencia',
    byPaymentDate: 'Fecha de Pago del Reembolso',
    orderBy: 'Ordenar Por',
    sortDirection: 'Orden de Fecha',
    ascending: 'Ascendente',
    descending: 'Descendente',
    incidenceStatus: 'Estado de la Incidencia',
    incidenceDate: 'Fecha de la Incidencia',
    scheduleRefund: 'Programar Reembolso',
    scheduledRefund: 'Reembolso Programado',
    cancelRefundSuccessfully: 'Reembolso cancelado satisfactoriamente',
    cancelRefund: 'Cancelar Reembolso',
    selectPaymentDate: 'Seleciona la Fecha de Pago',
    scheduleFor: 'Reembolsos a Pagar en ',
    authorizeRefund: 'Autorizar reembolso',
    authorizeTreatmentRefund: 'Autorizar reembolso del tratamiento',
    paymentDetails: 'Detalles del Pago',
    reassignToEmployee: 'Reasignar a Empleado',
    byApprove: 'Por Aprobar',
    reSchedulePayment: 'Reprogramar Pago',
    type: 'Tipo',
    Closed: 'Cerrada',
    refundDetails: 'Detalles del Reembolso',
    refundDetailsHelpText: 'Datos Generales del Reembolso',
    paidOn: 'Pagado en',
    paidBy: 'Pagado por',
    plannedPaymentDate: 'Fecha de Pago Planificada',
    selectWorker: 'Debe seleccionar un empleado',
    procedureDate: 'Fecha del Procedimiento',
    answeredPhone: 'Contestó el Teléfono',
    contactedPatient: 'Paciente Contactado',
    addComment: 'Añadir un Comentario',
    special: 'Especial',
    originalDatetime: 'Fecha y Hora Original',
    totalPlannedFor: 'Total planificado para ',
    canNotCopied: 'No Puede Copiar',
    copied: 'Copiado',
    copyNote: 'Copiar Nota',
    confirmNonSurgicalRollback: 'Confirmar deshacer esta cita completada',
    noticeNonSurgicalRollback: '¿Usted está seguro que desea deshacer esta cita completada?',
};

export default messages;
