const AdvertTvsPage = () => import('../../pages/publicity/advertising/AdvertTvs.vue');
const EditAdvertTv = () => import('../../pages/publicity/advertising/EditAdvertTv.vue');

const advertisingRoutes = [
    {
        name: 'AdvertisingTvs',
        path: 'advertising',
        redirect: { name: 'ListAdvertisingTvs' },
        children: [
            {
                name: 'ListAdvertisingTvs',
                path: 'tvs/',
                component: AdvertTvsPage,
                meta: {
                    menu: true,
                    title: 'Advertising TVs',
                    permissionsAnyOf: ['Permissions.Publicity.AdvertisingTv.Search'],
                },
            },
            {
                name: 'EditAdvertisingTv',
                path: 'tvs/:id/',
                component: EditAdvertTv,
            },
        ],
    },
];

export default advertisingRoutes;
