const AddEmployees = () => import('@/pages/humanResources/employees/AddEmployee.vue');
const Employees = () => import('@/pages/humanResources/employees/EmployeeList.vue');
const EditWorkingHoursEmployee = () =>
    import('@/pages/humanResources/employees/EditWorkingHoursEmployee.vue');
const CommissionsConfig = () => import('@/pages/humanResources/commissions/CommissionsConfig.vue');
const Commissions = () => import('@/pages/humanResources/commissions/AppliedCommissions.vue');
const EmployeesContainer = () => import('@/pages/humanResources/employees/EmployeesContainer.vue');
const OccupationsTemplates = () =>
    import('../pages/humanResources/configuration/template/OccupationsTemplates.vue');
const AddOccupationTemplate = () =>
    import('../pages/humanResources/configuration/template/AddOccupationTemplate.vue');
const EditOccupationTemplate = () =>
    import('../pages/humanResources/configuration/template/EditOccupationTemplate.vue');
import { RouterView } from 'vue-router';
const Bundles = () => import('../pages/humanResources/bundles/BundlesData.vue');
const PayrollDashboard = () =>
    import('@/pages/humanResources/payroll/paymentPeriod/PayrollDashboard.vue');
const PaymentPeriods = () =>
    import('@/pages/humanResources/payroll/paymentPeriod/PaymentPeriods.vue');
const PayrollDrafts = () => import('@/pages/humanResources/payroll/payrolls/PayrollDrafts.vue');
const PayrollReview = () => import('@/pages/humanResources/payroll/payrolls/PayrollReview.vue');
const AllAppointments = () => import('@/pages/frontdesk/AllAppointments.vue');
const HumanResReports = () => import('@/pages/humanResources/reports/HumanResReports.vue');
const CheckOutSurgeryAppointment = () =>
    import('@/pages/medicalServices/surgeries/checkOut/CheckOutSurgeryAppointment.vue');

const humanResourcesRoutes = [
    {
        path: 'hr',
        name: 'HumanResources',
        redirect: () => {
            return { name: 'HrEmployees' };
        },
        component: RouterView,
        meta: {
            title: 'Human Resources',
            public: false,
            icon: 'UserGroupIcon',
        },
        children: [
            {
                path: 'employees',
                component: EmployeesContainer,
                children: [
                    {
                        path: '',
                        component: Employees,
                        name: 'HrEmployees',
                        meta: {
                            menu: true,
                            title: 'Employees',
                            permissionsAnyOf: ['Permissions.HumanResources.Employees.List'],
                        },
                    },
                    {
                        path: 'add',
                        name: 'HrEmployeesAdd',
                        component: AddEmployees,
                    },
                    {
                        path: ':id/edit',
                        name: 'HrEmployeesEdit',
                        component: AddEmployees,
                        props: true,
                    },
                    {
                        path: 'working-hours/:employee/edit/:id',
                        name: 'HrEmployeesEditWorkingHours',
                        component: EditWorkingHoursEmployee,
                    },
                ],
            },
            {
                path: 'commissions',
                name: 'AllCommissions',
                component: Commissions,
                meta: {
                    menu: true,
                    header: false,
                    title: 'Commissions',
                    permissionsAnyOf: ['Permissions.HumanResources.Commissions.Search'],
                },
            },
            {
                path: 'commission/config',
                name: 'Commissions',
                component: CommissionsConfig,
                meta: {
                    menu: true,
                    title: 'Commissions config',
                    permissionsAnyOf: ['Permissions.HumanResources.Commissions.Search'],
                },
            },
            {
                path: 'bundle',
                name: 'Bundles',
                component: Bundles,
                meta: {
                    menu: true,
                    title: 'Bundles',
                    permissionsAnyOf: ['Permissions.HumanResources.Bundle.List'],
                },
            },
            {
                path: 'occupations-templates',
                name: 'HrOccupationsTemplates',
                component: OccupationsTemplates,
                meta: {
                    menu: true,
                    title: 'Occupations Templates',
                    permissionsAnyOf: ['Permissions.HumanResources.OccupationTemplate.List'],
                },
            },
            {
                path: 'occupations-templates/add',
                name: 'HrAddOccupationTemplate',
                component: AddOccupationTemplate,
            },
            {
                path: 'occupations-templates/edit/:id',
                name: 'HrEditOccupationTemplate',
                component: EditOccupationTemplate,
            },
            {
                path: 'payroll',
                name: 'Payroll',
                component: PayrollDashboard,
                redirect: () => {
                    return { name: 'PaymentPeriods' };
                },
                meta: {
                    header: false,
                    menu: true,
                    title: 'Payroll',
                    permissionsAnyOf: ['Permissions.HumanResources.PaymentPeriod.Search'],
                },
                children: [
                    {
                        path: 'payment-periods',
                        name: 'PaymentPeriods',
                        component: PaymentPeriods,
                        title: 'Payment Periods',
                    },
                    {
                        path: 'payment-periods/:ppId/drafts',
                        name: 'PayrollDrafts',
                        component: PayrollDrafts,
                        title: 'Drafts',
                    },
                    {
                        path: 'payment-periods/:ppId/review',
                        name: 'PayrollReview',
                        component: PayrollReview,
                        title: 'Draft review',
                    },
                ],
            },
            {
                path: 'appointments',
                name: 'AllHumanResourcesAppointments',
                component: AllAppointments,
                meta: {
                    menu: true,
                    title: 'All Appointments',
                    permissionsAllOf: ['Permissions.HumanResources.Appointments.Search'],
                },
                props: {
                    checkOutRoute: 'HumanResourcesChangeCheckOutSurgeryAppointment',
                },
            },
            {
                path: 'reports',
                name: 'HumanResReports',
                component: HumanResReports,
                meta: {
                    menu: true,
                    title: 'Reports',
                    permissionsAnyOf: ['Permissions.HumanResources.PaymentPeriod.Reports'],
                },
            },
            {
                path: 'appointments/change-check-out/:id/:patientId',
                name: 'HumanResourcesChangeCheckOutSurgeryAppointment',
                component: CheckOutSurgeryAppointment,
                meta: {
                    public: false,
                    title: 'CheckOut',
                    header: true,
                },
                props: {
                    editable: true,
                    route: 'AllHumanResourcesAppointments',
                },
            },
        ],
    },
];
export default humanResourcesRoutes;
