const messages = {
    closePaymentPeriod: 'Cerrar período de pago',
    drafts: 'Borradores',
    review: 'Revisión',
    payrolls: 'Nóminas',
    checkWasPrinted: 'Con cheques impresos',
    returnToDraft: 'Volver a borrador',
    excludeThisPayrollFromPayment: '¿Excluir esta nómina del pago?',
    excludeThisPayrollFromPaymentHelpText:
        'Esta acción regresará la nómina a estado "borrador" y la exluirá de este período. Puedes incluirla de nuevo cuando quieras.',
    payrollExcludedSuccess: 'Nómina excluída satisfactoriamente',
    regularHours: 'Horas regulares',
    overtimeHourlyWage: 'Tarifa horaria de overtime',
    bonuses: 'Bonuses',
    deductions: 'Deducciones',
    basic: 'Básico',
    printPaySlip: 'Print payslip',
    updateAllHours: 'Actualizar horas',
    hours: 'Horas',
    earnings: 'Ingresos',
    commissionDate: 'Fecha de comisión',
};

export default messages;
