const HomeContainer = () => import('@/pages/containers/HomeContainer.vue');
const Register = () => import('@/pages/RegisterUser.vue');
const NotFound = () => import('@/pages/NotFound.vue');
const ShowcaseView = () => import('@/pages/publicity/advertising/ShowcaseView.vue');
const TermsAndConditions = () => import('@/pages/TermsAndConditions.vue');
import layoutRoutes from '@/routes/layout';
import authenticationRoutes from './authentication.routes';
import invoiceRoutes from './invoice.routes';

export const routes = [
    {
        path: '/',
        name: 'Home',
        component: HomeContainer,
        meta: {
            title: 'Home',
            public: false,
        },
        children: layoutRoutes,
    },
    {
        path: '/register',
        name: 'Register',
        component: Register,
        meta: {
            title: 'Register',
            public: true,
        },
    },
    {
        path: '/terms-and-conditions',
        name: 'TermsAndConditions',
        component: TermsAndConditions,
        meta: {
            title: 'Terms and Conditions',
            public: true,
        },
    },
    {
        /*
         * The Showcase route is a special case inside no layout
         * so keep it here. No nested.
         * */
        name: 'ShowcaseTv',
        path: '/publicity/advertising/showcase/',
        component: ShowcaseView,
        meta: {
            title: 'Screen showcase view',
            public: false,
        },
    },
    ...authenticationRoutes,
    ...invoiceRoutes,
    {
        path: '/:path(.*)',
        meta: {
            title: '404',
            public: true,
        },
        name: 'not-found',
        component: NotFound,
    },
];
