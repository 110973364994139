const AssignedLeads = () => import('@/pages/sales/assignedLeads/AssignedLeads.vue');

export const assignedLeadRoutes = [
    {
        path: 'myleads',
        name: 'AssignedLeads',
        component: AssignedLeads,
        meta: {
            menu: true,
            title: 'Assigned Leads',
            permissionsAnyOf: ['Permissions.Sales.AssignedLeads.Search'],
        },
    },
];

export default assignedLeadRoutes;
