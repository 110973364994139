const messages = {
    saveTheme: 'Guardar Tema',
    noteCompanyForm: 'Esta información no se mostratrá públicamente.',
    website: 'Sitio Web',
    primaryColor: 'Color Primario',
    secondaryColor: 'Color Secundario',
    yourCompanyLogo: 'Logotipo de la Compañía',
    yourCompanyImage: 'Imagen de la Compañía',
    createOccupation: 'Crear Ocupación',
    openOptions: 'Abrir Opciones',
    firstExplanatoryTextOccupation: 'Parámetros y Valores básicos.',
    secondaryExplanatoryTextOccupation:
        'Agregue o elimine valores para satisfacer las necesidades de su empresa.',
    thirdExplanatoryTextOccupation: 'Marque esto en caso de que esta ocupación sea un gerente.',
    fourthExplanatoryTextOccupation: 'es decir. Asistente de cirugía',
    fifthExplanatoryTextOccupation: 'Alguna descripción de la ocupación si es necesario',
    itIsManager: 'Es Manager',
    occupationName: 'Nombre de la Ocupación',
    signIn: 'Iniciar Sesión',
    signInAccount: 'Inicie Sesión en su Cuenta',
    emailAddress: 'Correo Electrónico',
    forgotYourPassword: '¿Olvidaste tu contraseña?',
    create: 'Crear',
    update: 'Actualizar',
    occupations: 'Ocupaciones',
};

export default messages;
