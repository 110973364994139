const AddAsset = () => import('../../pages/publicity/assets/AddAsset.vue');
const EditAsset = () => import('../../pages/publicity/assets/EditAsset.vue');
const AllAssets = () => import('../../pages/publicity/assets/AllAssets.vue');
const DetailAsset = () => import('../../pages/publicity/assets/DetailAsset.vue');

const assetRoutes = [
    {
        path: 'assets',
        name: 'Assets',
        component: AllAssets,
        meta: {
            menu: true,
            title: 'Assets',
            permissionsAnyOf: ['Permissions.Publicity.Assets.Search'],
        },
    },
    {
        path: 'assets/detail/:id',
        name: 'DetailAsset',
        component: DetailAsset,
        props: true,
    },
    {
        path: 'assets/add',
        name: 'PublicityAssetsAdd',
        component: AddAsset,
    },
    {
        path: 'assets/edit/:id',
        name: 'PublicityAssetsEdit',
        component: EditAsset,
        props: true,
    },
];

export default assetRoutes;
