const messages = {
    StockAdded: 'Adicionado',
    StockRemoved: 'Eliminado',
    Surgery: 'Cirugía',
    Cosmetology: 'Cosmetología',
    Dispatch: 'Despacho',
    TpvSale: 'Venta',
    TpvSaleItemCancelled: 'Venta Cancelada',
};

export default messages;
