const AllContracts = () => import('../../pages/publicity/contracts/AllContracts.vue');
const AddEditContract = () => import('../../pages/publicity/contracts/AddEditContract.vue');
const DetailContract = () => import('../../pages/publicity/contracts/DetailContract.vue');

export const contractRoutes = [
    {
        path: 'contracts',
        name: 'Contracts',
        component: AllContracts,
        meta: {
            menu: true,
            title: 'Contracts',
            permissionsAnyOf: ['Permissions.Publicity.Contracts.Search'],
        },
    },
    {
        path: 'contracts/add',
        name: 'AddContract',
        component: AddEditContract,
    },
    {
        path: 'contracts/edit/:id',
        name: 'EditContract',
        component: AddEditContract,
        props: true,
    },
    {
        path: 'contracts/detail/:id',
        name: 'DetailContract',
        component: DetailContract,
        props: true,
    },
];

export default contractRoutes;
