const AllLeads = () => import('@/pages/publicity/leads/AllLeads.vue');
const IncomingLeads = () => import('@/pages/publicity/leads/IncomingLeads.vue');
const AddEditLead = () => import('@/pages/publicity/leads/AddEditLead.vue');

export const leadRoutes = [
    {
        path: 'leads',
        name: 'Leads',
        component: AllLeads,
        meta: {
            menu: true,
            title: 'Leads',
            permissionsAnyOf: ['Permissions.Publicity.Leads.Search'],
        },
    },
    {
        path: 'leads/add',
        name: 'AddLead',
        component: AddEditLead,
        meta: {
            permissionsAllOf: [
                'Permissions.Publicity.Leads.Create',
                'Permissions.Publicity.CustomerSources.Search',
                'Permissions.Publicity.FirstContacts.Search',
                'Permissions.Publicity.SettingProcedure.Search',
                'Permissions.HumanResources.Employees.List',
            ],
        },
    },
    {
        path: 'leads/edit/:id',
        name: 'EditLead',
        component: AddEditLead,
        meta: {
            permissionsAllOf: [
                'Permissions.Publicity.Leads.Update',
                'Permissions.Publicity.CustomerSources.Search',
                'Permissions.Publicity.FirstContacts.Search',
                'Permissions.Publicity.HumanResourcesEmployee.List',
            ],
        },
        props: true,
    },
    {
        path: 'incoming-leads',
        name: 'IncomingLeads',
        component: IncomingLeads,
        meta: {
            menu: true,
            title: 'Incoming Leads',
            permissionsAnyOf: ['Permissions.Publicity.Leads.Search'],
        },
    },
];

export default leadRoutes;
