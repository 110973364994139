const messages = {
    cantEmployees: 'Cant Employees',
    January: 'January',
    December: 'December',
    November: 'November',
    October: 'October',
    September: 'September',
    August: 'August',
    July: 'July',
    June: 'June',
    May: 'May',
    April: 'April',
    March: 'March',
    February: 'February',
};

export default messages;
