import { RouterView } from 'vue-router';
const ProviderSchedules = () => import('@/pages/coordination/schedule/ProviderSchedules.vue');
const SurgicalAppointments = () =>
    import('@/pages/coordination/appointments/SurgicalAppointments.vue');
const NonSurgicalAppointments = () =>
    import('@/pages/coordination/appointments/NonSurgicalAppointments.vue');
const SurgicalPendingAppointments = () =>
    import('@/pages/coordination/appointments/SurgicalPendingAppointments.vue');
const SurgerySetup = () => import('@/pages/coordination/appointments/SurgerySetup.vue');
const ChartLeads = () => import('@/pages/coordination/chart/ChartLeads.vue');
const AllCoordinationAppointments = () =>
    import('@/pages/coordination/appointments/AllAppointments.vue');
const CoordinationPatients = () => import('@/pages/coordination/patients/CoordinationPatients.vue');
const AllProviders = () => import('@/pages/coordination/schedulesProvider/AllProviders.vue');
const CheckOutSurgeryAppointment = () =>
    import('@/pages/medicalServices/surgeries/checkOut/CheckOutSurgeryAppointment.vue');
const coordinationRoutes = [
    {
        path: 'coordination',
        name: 'appointments',
        redirect: (to) => {
            return { path: '/coordination/appointments' };
        },
        component: RouterView,
        meta: {
            title: 'Coordination',
            public: false,
            icon: 'CalendarIcon',
        },
        children: [
            {
                path: 'surgical-appointments',
                name: 'SurgicalAppointments',
                component: SurgicalAppointments,
                meta: {
                    menu: true,
                    title: 'PreOp',
                    permissionsAnyOf: ['Permissions.Coordination.Appointments.Search'],
                },
            },
            {
                path: 'schedules',
                name: 'CoordinationProviders',
                component: ProviderSchedules,
                meta: {
                    menu: true,
                    title: 'Schedules',
                    permissionsAllOf: [
                        'Permissions.Coordination.Providers.Search',
                        'Permissions.Coordination.ProviderSchedules.SearchAvailableSchedules',
                    ],
                },
            },
            {
                path: 'pending-surgical-appointments',
                name: 'SurgicalPendingAppointments',
                component: SurgicalPendingAppointments,
                meta: {
                    menu: true,
                    title: 'Pending Appointments',
                    permissionsAnyOf: ['Permissions.Coordination.Appointments.Search'],
                },
            },
            {
                path: 'non-surgical-appointments',
                name: 'NonSurgicalAppointments',
                component: NonSurgicalAppointments,
                meta: {
                    menu: true,
                    title: 'Massages Appointments',
                    permissionsAnyOf: ['Permissions.Coordination.Appointments.Search'],
                },
            },
            {
                path: 'surgery-setup',
                name: 'CoordinationSurgerySetup',
                component: SurgerySetup,
                meta: {
                    menu: true,
                    title: 'Surgery Setup',
                    permissionsAnyOf: ['Permissions.Coordination.Appointments.Search'],
                },
            },
            {
                path: 'config-provider-schedules',
                name: 'CoordinationConfigProviderSchedules',
                component: AllProviders,
                meta: {
                    menu: true,
                    title: 'Schedule Settings',
                    permissionsAllOf: [
                        'Permissions.Coordination.Providers.Search',
                        'Permissions.Coordination.ProviderSchedules.SearchAvailableSchedules',
                    ],
                },
            },
            {
                path: 'patient-chart',
                name: 'PatientsChart',
                component: ChartLeads,
                meta: {
                    menu: true,
                    title: 'Patients Chart',
                    permissionsAllOf: [
                        'Permissions.Coordination.ClientProfileChart.Search',
                        'Permissions.Coordination.PublicityLeads.Search',
                    ],
                },
            },
            {
                path: 'appointments',
                name: 'CoordinationAllAppointments',
                component: AllCoordinationAppointments,
                meta: {
                    menu: true,
                    title: 'All Appointments',
                    permissionsAllOf: [
                        'Permissions.Coordination.Appointments.Search',
                        'Permissions.Coordination.FrontDeskAppointments.Search',
                    ],
                },
                props: {
                    checkOutRoute: 'CoordinationChangeCheckOutSurgeryAppointment',
                },
            },
            {
                path: 'coordination-patients',
                name: 'CoordinationPatients',
                component: CoordinationPatients,
                meta: {
                    menu: true,
                    title: 'Patients',
                    permissionsAnyOf: ['Permissions.Coordination.Leads.Search'],
                },
            },
            {
                path: 'appointments/change-check-out/:id/:patientId',
                name: 'CoordinationChangeCheckOutSurgeryAppointment',
                component: CheckOutSurgeryAppointment,
                meta: {
                    public: false,
                    title: 'CheckOut',
                    header: true,
                },
                props: {
                    editable: true,
                    route: 'CoordinationAllAppointments',
                },
            },
        ],
    },
];

export default coordinationRoutes;
