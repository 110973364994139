const messages = {
    addRole: 'Add Role',
    editRole: 'Edit Role',
    manageRolePermissions: 'Manage Role Permissions',
    roleAddSuccessfully: 'Role add successfully',
    roleEditSuccessfully: 'Role edit successfully',
    roleDeleteSuccessfully: 'Role delete successfully',
    confirmDeleteRole: 'Confirm Delete Role',
    permissions: 'Permissions',
    roles: 'Roles',
    selectAll: 'Select All',
    unselectAll: 'Unselect All',
};

export default messages;
