const AccountLogin = () => import('@/pages/identity/AccountLogin.vue');
const TwoFactor = () => import('@/pages/identity/TwoFactor.vue');
const ForgotPassword = () => import('@/pages/identity/ForgotPassword.vue');
const ChangePassword = () => import('@/pages/identity/profile/ChangePassword.vue');
const ResetPassword = () => import('@/pages/identity/ResetPassword.vue');
const Forbidden = () => import('@/pages/ForbiddenPage.vue');

const authenticationRoutes = [
    {
        path: '/login',
        name: 'Login',
        component: AccountLogin,
        meta: {
            title: 'Login',
            public: true,
        },
    },
    {
        path: '/2fa',
        name: 'TwoFactor',
        component: TwoFactor,
        meta: {
            title: 'Two Factor Authentication',
            public: true,
        },
    },
    {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: ForgotPassword,
        meta: {
            title: 'Forgot Password',
            public: true,
        },
    },
    {
        path: '/reset',
        name: 'ResetPassword',
        component: ResetPassword,
        meta: {
            title: 'Reset Password',
            public: true,
        },
    },
    {
        path: '/update-password',
        name: 'UpdatePassword',
        component: ChangePassword,
        meta: {
            title: 'Update Password',
            public: false,
        },
    },
    {
        path: '/403',
        name: 'Forbidden',
        component: Forbidden,
        meta: {
            title: 'Forbidden',
            public: true,
        },
    },
];

export default authenticationRoutes;
