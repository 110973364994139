<script setup lang="ts">


import { toRefs, ref, computed, onMounted } from 'vue';
import { InformationCircleIcon, XCircleIcon, CheckCircleIcon } from '@heroicons/vue/outline';
import { INotification, removeNotification, updateNotificationHeight } from '../../../composables/notification';
import { TransitionRoot } from '@headlessui/vue';

interface Props {
    info: INotification;
}

const props = defineProps<Props>();

const { id, type, title, message, duration, position, activate } = toRefs(props.info);
const notify = ref(null);
const typeNoti = [
    { icon: CheckCircleIcon, text: 'text-emerald-500', background: 'bg-white' },
    { icon: XCircleIcon, text: 'text-red-700', background: 'bg-red-300' },
    { icon: InformationCircleIcon, text: 'text-sky-700', background: 'bg-sky-100' },
    { icon: InformationCircleIcon, text: 'text-yellow-700', background: 'bg-yellow-100' },
];
const styleType = computed(() => {
    return typeNoti[type.value];
});
const show = () => {
    setTimeout(() => {
        close();
    }, duration.value);
};
const close = () => {
    activate.value = false;
};
const afterLeave = () => {
    removeNotification(id.value);
};

onMounted(() => {
    show();
    updateNotificationHeight(id.value, notify.value.clientHeight);
});

</script>

<template>
    <TransitionRoot
        as="template"
        appear
        :show="activate"
        enter="transform ease-out duration-300 transition"
        enter-from="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enter-to="translate-y-0 opacity-100 sm:translate-x-0"
        leave="transition ease-in duration-100"
        leave-from="translate-y-0 opacity-100 sm:translate-x-0"
        leave-to="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        @after-leave="afterLeave"
    >
        <div
            aria-live="assertive"
            :style="{ top: position + 'px' }"
            class="moveNotification print:hidden z-[100] fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
        >
            <div ref="notify" class="w-full flex flex-col items-center space-y-4 sm:items-end">
                <!--
          Notification panel, dynamically insert this into the live region when it needs to be displayed

          Entering: "transform ease-out duration-300 transition"
            From: "translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            To: "translate-y-0 opacity-100 sm:translate-x-0"
          Leaving: "transition ease-in duration-100"
            From: "opacity-100"
            To: "opacity-0"
        -->
                <div
                    :class="styleType.background"
                    class="max-w-sm w-full shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden"
                >
                    <div class="p-4">
                        <div class="flex items-start">
                            <div class="flex-shrink-0">
                                <!-- Heroicon name: outline/check-circle -->
                                <component
                                    :is="styleType.icon"
                                    :class="styleType.text"
                                    class="mr-3 flex-shrink-0 h-6 w-6"
                                    aria-hidden="true"
                                />
                            </div>
                            <div class="ml-3 w-0 flex-1 pt-0.5">
                                <p class="text-sm font-medium text-gray-900">{{ title }}</p>
                                <p class="mt-1 text-sm text-gray-500">{{ message }}</p>
                            </div>
                            <div class="ml-4 flex-shrink-0 flex">
                                <button
                                    type="button"
                                    class="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    @click="close"
                                >
                                    <span class="sr-only">Close</span>
                                    <!-- Heroicon name: solid/x -->
                                    <svg
                                        class="h-5 w-5"
                                        :class="styleType.background"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        aria-hidden="true"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                            clip-rule="evenodd"
                                        />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </TransitionRoot>
</template>

<style scoped>
.moveNotification {
    transition: 0.3s all ease;
}
</style>
