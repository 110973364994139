const messages = {
    editIncidence: 'Edit Incidence',
    addIncidence: 'Add Incidence',
    incidenceUpdatedSuccessfully: 'Incidence updated successfully',
    confirmDeleteIncidence: 'Confirm Deletion of the Incidence',
    incidenceAddedSuccessfully: 'Incidence added successfully',
    incidenceDeletedSuccessfully: 'Incidence deleted successfully',
    incidenceAssignedSuccessfully: 'Incidence assigned successfully',
    assignIncidence: 'Assign Incidence',
    unauthorizedEmployeeList: "You don't have authorization to assign employees",
    unauthorizedIncidenceList: "You don't have authorization to view incidences list",
    assignToEmployee: 'Assign to an Employee',
    Complaint: 'Complaint',
    Incentive: 'Incentive',
    Refund: 'Refund',
    FixSurgery: 'FixSurgery',
    GiftCard: 'Credit Balance',
    CreditBalance: 'Credit Balance',
    requestingUser: 'Requesting User',
    compensations: 'Compensations',
    process: 'Process',
    approvedCompensation: 'Approved Compensation',
    requiresCompensation: 'Requires Compensation',
    desiredCompensation: 'Desired Compensation',
    compensation: 'Compensation',
    incidenceType: 'Incidence Type',
    processCompensationSuccessfully: 'Compensation processed successfully',
    Requested: 'Requested',
    compensationType: 'Compensation Type',
    compensationRequest: 'Compensation Request',
    solve: 'Solve',
    processCompensation: 'Process Compensation',
    resolvingActions: 'Resolving Actions',
    solveIncidence: 'Solve Incidence',
    customerSatisfaction: 'Customer Satisfaction',
    valuationOfMotives: 'Valuation of Motives',
    approved: 'Approved',
    denied: 'Denied',
    amountApproved: 'Amount Approved',
    incidenceClosedSuccessfully: 'Incidence closed successfully',
    closeIncidence: 'Close Incidence',
    observations: 'Observations',
    compensationRequestDetails: 'Compensation Request Details',
    incidenceId: 'Incidence ID',
    requestDetails: 'Request Details',
    addAmountToGiftCard: 'Add Amount to Credit Balance',
    mountAddedSuccessfully: 'Mount added successfully',
    giftCard: 'Credit Balance',
    close: 'Close',
    incentiveAddedSuccessfully: 'Incentive added successfully',
    complaintAddedSuccessfully: 'Complaint added successfully',
    incidenceData: 'Incidence Data',
    incidenceDataHelpText: 'General Incidence Data',
    patientData: "Patient's Data",
    patientDetailsHelpText: 'General Data of the Patient Reporting the Incidence',
    approve: 'Approve',
    deny: 'Deny',
    noticeApproveSolutionComplaint:
        'If you approve this solution, it will automatically go to accounting for processing.',
    noticeApproveSolutionIncentive: 'Are you sure you want to approve this solution?',
    confirmApproveSolution: 'Confirm Solution Approval',
    solutionApprovedSuccessfully: 'Solution approved successfully',
    noticeDenySolutionComplaint:
        'If you deny this solution, the compensation cannot be processed in accounting-.',
    noticeDenySolutionIncentive: 'Are you sure you want to deny this solution?',
    confirmDenySolution: 'Confirm the Denial of the Solution',
    solutionDeniedSuccessfully: 'Solution denied successfully',
    compensationCreatedBy: 'Compensation Created By',
    paymentMethod: 'Payment Method',
    processingDate: 'Processing Date',
    refunds: 'Refunds',
    reasonsForRejection: 'Reasons for Rejection',
    paymentDate: 'Payment Date',
    pending: 'Pending',
    reviewed: 'Reviewed',
    planned: 'Planned',
    paid: 'Paid',
    cancelled: 'Cancelled',
    confirmScheduleRefund: 'Confirm Refund Authorization',
    noticeScheduleRefund: 'Are you sure you want to authorize this refund?',
    refundAuthorizedSuccessfully: 'Refund authorized successfully',
    payRefund: 'Pay Refund',
    payRefundSuccessfully: 'Refund paid successfully',
    refundConfirmation: 'Refund Confirmation',
    generalRemarks: 'General Observations',
    incidenceCreationDate: 'Incidence Creation Date',
    refundStatus: 'Refund Status',
    refundCreationDate: 'Refund Creation Date',
    refundAmount: 'Refund Amount',
    schedulePayment: 'Schedule Payment',
    scheduleRefundSuccessfully: 'Scheduled refund successfully',
    byCreationDate: 'Refund Creation Date',
    byIncidenceCreationDate: 'Incidence Creation Date',
    byPaymentDate: 'Refund Payment Date',
    orderBy: 'Order By',
    sortDirection: 'Sort By Date',
    ascending: 'Ascending',
    descending: 'Descending',
    incidenceStatus: 'Incidence Status',
    incidenceDate: 'Incidence Date',
    scheduleRefund: 'Schedule Refund',
    scheduledRefund: 'Scheduled Refund',
    cancelRefundSuccessfully: 'Canceled refund successfully',
    cancelRefund: 'Cancel Refund',
    selectPaymentDate: 'Select Payment Date',
    scheduleFor: 'Refunds to be Paid in ',
    authorizeRefund: 'Authorize refund',
    authorizeTreatmentRefund: 'Authorize treatment refund',
    paymentDetails: 'Payment details',
    reassignToEmployee: 'Reassign to an Employee',
    byApprove: 'By Approve',
    reSchedulePayment: 'Reschedule Payment',
    type: 'Type',
    Closed: 'Closed',
    refundDetails: 'Refund Details',
    refundDetailsHelpText: 'General Refund Details',
    paidOn: 'Paid On',
    paidBy: 'Paid By',
    plannedPaymentDate: 'Planned Payment Date',
    selectWorker: 'You must select an employee',
    procedureDate: 'Procedure Date',
    answeredPhone: 'Answered Phone',
    contactedPatient: 'Contacted Patient',
    addComment: 'Add a Comment',
    special: 'Special',
    originalDatetime: 'Original Datetime',
    totalPlannedFor: 'Total planned for ',
    canNotCopied: 'Can Not Copied',
    copied: 'Copied',
    copyNote: 'Copy Note',
    confirmNonSurgicalRollback: 'Confirm Undo completed appointment',
    noticeNonSurgicalRollback: 'Are you sure you want to undo this appointment done',
    complaintsByEmployeeReport: 'Complaints by employee',
    complaintsReportDescription: 'Show the total complaints per employee',
};

export default messages;
