import { ref, reactive } from 'vue';
export enum NotificationTypes {
    SUCCESS,
    ERROR,
    INFO,
    WARNING,
}
export interface INotification {
    id: number;
    activate: boolean;
    type: NotificationTypes;
    duration: number;
    height: number;
    position: number;
    title?: string;
    message?: string;
}
export const gap = ref(10);
export const activate = ref(false);
export const notifications = ref<INotification[]>([]);

export const Notification = reactive({
    type: NotificationTypes.SUCCESS,
    title: '',
    message: '',
    duration: 5000,
});

export class Notifications {
    public static Error(title: string, message = '', duration = 5000) {
        setNotification(title, message, NotificationTypes.ERROR, duration);
    }
    public static Success(title: string, message = '', duration = 5000) {
        setNotification(title, message, NotificationTypes.SUCCESS, duration);
    }
    public static Info(title: string, message = '', duration = 5000) {
        setNotification(title, message, NotificationTypes.INFO, duration);
    }
    public static Warning(title: string, message = '', duration = 5000) {
        setNotification(title, message, NotificationTypes.WARNING, duration);
    }
}
function setNotification(
    title: string,
    message: string,
    type = NotificationTypes.SUCCESS,
    duration = 5000
) {
    notifications.value.push({
        id: notifications.value.length + 1,
        activate: true,
        type: type,
        title: title,
        message: message,
        duration: duration,
        height: 50,
        position: getPosition(),
    });
}

const getPosition = () => {
    let lastPosition = 0;
    notifications.value.forEach((x) => {
        lastPosition += x.height + gap.value;
    });
    return lastPosition;
};

export const removeNotification = (id: number) => {
    const index = notifications.value.findIndex((el) => el.id === id);
    if (index != -1) {
        for (let i = index + 1; i < notifications.value.length; i++) {
            notifications.value[i].position -= notifications.value[index].height + gap.value;
        }
        notifications.value.splice(index, 1);
    }
};

export const updateNotificationHeight = (id: number, height: number) => {
    const index = notifications.value.findIndex((el) => el.id === id);
    if (index != -1) notifications.value[index].height = height;
};

export const clearAllNotifications = () => {
    notifications.value.map((n) => removeNotification(n.id));
};
