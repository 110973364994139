const messages = {
    documentsTypes: 'Tipos de Documentos',
    documentType: 'Tipo de Documento',
    consents: 'Consentimientos',
    isRequired: 'Es Requerido',
    signatureRequired: 'Firma Requerida',
    generalAnesthesia: 'Anestesia General',
    documentLanguage: 'Idioma del Documento',
    addDocumentType: 'Adicionar Tipo de Documento',
    editDocumentType: 'Editar Tipo de Documento',
    addConsent: 'Adicionar Consentimiento',
    editConsent: 'Editar Consentimiento',
    addPaymentMethod: 'Adicionar método de pago',
    editPaymentMethod: 'Editar método de pago',
    height: 'Altura',
    weight: 'Peso',
    drugs: 'Drogas',
    alcohol: 'Alcohol',
    nicotine: 'Nicotina',
    medicalAilments: 'Dolencias Médicas',
    medicines: 'Medicamentos',
    otherMedicalConditions: 'Otras Condiciones Médicas',
    addYourComment: 'Adicione su Comentario',
    viewMore: 'Ver Más',
    addedAComment: 'Adicionó un Comentario',
    addedTags: 'etiquetas añadidas',
    viewDetailsFor: 'Ver Detalles Para',
    medicalRecord: 'Historial Médico',
    surgicalHistory: 'Antecedentes Quirúrgicos',
    imageType: 'Tipo de Imagen',
    before: 'Después',
    after: 'Antes',
    consentEditedSuccesfully: 'Consentimiento editado satisfactoriamente',
    editProfile: 'Editar Perfil',
    size: 'Longitud',
    pountAbbreviation: 'Lbs',
    bmi: 'IMC',
    deprecadedHeight:
        'Altura no usada: Se conserva solo con fines de referencia, para configurar el nuevo campo de altura.',
};

export default messages;
