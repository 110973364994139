const PlanningTasks = () => import('@/pages/humanResources/planningTasks/PlanningTasks.vue');
const AddEditPlanningTask = () =>
    import('@/pages/humanResources/planningTasks/AddEditPlanningTask.vue');

export const planningTaskRoutes = [
    {
        path: '/tasks',
        name: 'Tasks',
        component: PlanningTasks,
        meta: {
            menu: false,
            permissionsAnyOf: ['Permissions.Calendar.Tasks.Search'],
        },
        children: [],
    },
    {
        path: '/tasks/add',
        name: 'AddPlanningTask',
        component: AddEditPlanningTask,
        meta: {
            menu: false,
        },
        children: [],
    },
    {
        path: '/tasks/edit/:id',
        name: 'EditPlanningTask',
        component: AddEditPlanningTask,
        meta: {
            menu: false,
        },
        props: true,
        children: [],
    },
];

export default planningTaskRoutes;
