const messages = {
    commissionRules: 'Commissions Rules',
    commissionRulesHelpText: 'Setup the rules to trigger commissions of your employees',
    productOrProcedure: 'Product / Procedure',
    quantity: 'Quantity',
    commissionType: 'Commission Type',
    value: 'Value',
    paymentMethod: 'Payment Method',
    balanceRange: 'Range of Balance',
    triggerOnPaymentNum: 'Trigger on Payment',
    team: 'Team',
    commissions: 'Commissions',
    debitCard: 'Debit Card',
    creditCard: 'Credit Card',
    cash: 'Cash',
    accountBalance: 'Account Balance',
    anyEmployee: 'Any Employee',
    noTeam: 'No Team',
    any: 'Any',
    noRange: 'No Range',
    employeeName: 'Name of the Employee',
    item: 'Item',
    teamDrName: 'Team Dr. Name',
    searchRules: 'Search Rules',
    employee: 'Employee',
    percentage: 'Percentage',
    fixed: 'Fixed',
    createNewItemCommissionRule: 'Create Item Commission Rule',
    createNewItemCommissionRuleHelpText:
        'A certain condition in which a commission will be triggered',
    fromBalance: 'From Balance',
    toBalance: 'To Balance',
    commissionRuleAdded: 'Commission rule added successfully',
    commissionRuleCreationError: 'Error creating commission rule',
    payment: 'Payment',
    disableComissionRule: 'Disable Commission Rule',
    disableComissionRuleHelpText:
        'This rule will not longer be evaluated for commissions from now on',
    enableComissionRule: 'Enable Commission Rule',
    enableComissionRuleHelpText:
        'This rule will be restored from now on for computation of incoming commissions',
    isActive: 'Is active',
    errorLoadingCommissionableItems: 'Error loading commissionable items',
    errorLoadingCommissionRules: 'Error loading commission rules',
    commissionableItem: 'Commissionable Item',
    financed: 'Financed',
    undefinedType: 'Undefined',
    emptyCommissionRules: 'You have not created any rules',
    emptyCommissionRulesHelpText:
        'Start creating new commission rules to increase your employees earnings',
    firstAssistant: 'First Assistant',
    secondAssistant: 'Second Assistant',
    anesthesiologist: 'Anesthesiologist',
    surgeryCoordinator: 'Surgery Coordinator',
    fromQuantity: 'From Quantity',
    toQuantity: 'To Quantity',
    comboType: 'Combo Type',
    simple: 'Simple',
    multiple: 'Multiple',
    combo: 'Combo',
    case: 'Case',
    createNewProcedureCommissionRule: 'Create procedure commission rule',
    createNewProcedureCommissionRuleHelpText:
        'A certain condition in which a commission will be triggered',
    teamMemberType: 'Member type',
    quantityEach: 'Quantity of Each',
    quantityEachHelpText: 'Matches the quantity range for each combo item',
    Bundles: 'Bundles',
    addBundle: 'Add Bundle',
    editBundle: 'Edit Bundle',
    commissionableItems: 'Commissionable Items',
    createNewPaymentCommissionRule: 'Create payment commission rule',
    createNewPaymentCommissionRuleHelpText:
        'A certain condition in which a commission will be triggered',
    firstPayment: 'First Payment',
    fromAmount: 'From Amount',
    toAmount: 'To Amount',
    transferRule: 'Transfer Rule',
    transferRuleHelpText: 'Mark this rule as a transfer rule',
    isTransferRule: 'Is Transfer Rule',
    commission: 'Commission | Commissions',
    sourceEvent: 'Source Event',
    noCommissionsForThisPayroll: 'No Commissions for this Period',
    noCommissionsForThisPayrollHelpText:
        'Commissions are created from configured events in the system',
    anyItem: 'Any Item',
    itemsRules: 'Item Rules',
    proceduresRules: 'Procedure Rules',
    paymentRules: 'Payment Rules',
    itemsRulesExplanatoryText: 'Applies to any commissionable item',
    proceduresRulesExplanatoryText: 'Any commission rule for surgical and not surgical procedures',
    paymentRulesExplanatoryText: 'Commissions applied over client payments',
    changePublishedStatus: 'Change Published Status',
    changeCommissionRulesView: 'Change Commission Rules View',
    appliedCommissions: 'Applied Commissions',
    fromDate: 'From Date',
    toDate: 'To Date',
    noAppliedCommissions: 'No Applied Commissions',
    noAppliedCommissionsHelpText: 'Come Back Later',
    myCommissions: 'My Commissions',
    overTotal: 'Over Total',
    discount: 'Discount',
    thirdAssistant: 'Third Assistant',
    fourthAssistant: 'Fourth Assistant',
    massagesOnly: 'Massages only',
    massagesOnlyHelpText: 'Match only on massage procedures',
    patientPhone: 'Patient phone',
    earningsAndDeductions: 'Earnings and Deductions',
    reviewAndPayments: 'Review and Payments',
    appliedOnDate: 'Applied on date',
    commissionValue: 'Commission value',
    unspecified: 'Unspecified',
    confirmCommissionDelete: 'Are you sure you want to delete this commission?',
    confirmCommissionDeleteHelpText: 'Removing an applied commission is an irreversible action',
    removeComissionRule: 'Remove Commission Rule',
    removeComissionRuleHelpText: 'This rule will not longer exist for commissions from now on',
    SaleCommission: 'Sale Commission',
    ServiceCommissions: 'Service Commission',
    itemType: 'Item type',
    nonSurgicalProcedure: 'Non-surgical Procedure',
    surgicalProcedure: 'Surgical procedure',
    showingThreeMonthsCommissions: 'Showing three months of commissions',
    showingThreeMonthsCommissionsHelpText:
        'Please use the filters to change any of the date boundaries',
    dynamic: 'Dynamic',
    discountType: 'Discount type',
};

export default messages;
