const messages = {
    defaultConfig: 'Configuración Predeterminada',
    overtimeConfigurations: 'Configuración de Horas Extras',
    addSettings: 'Añadir Configuración',
    fromThe: 'Desde las',
    firstExplanatoryTextOvertime: 'Configuraciones de horas extras.',
    addOvertimeConfig: 'Adicionar Configuración de Horas Extras',
    editOvertimeConfig: 'Editar Configuración de Horas Extras',
};

export default messages;
