const messages = {
    generalData: 'General Information',
    schedule: 'Schedule',
    salary: 'Salary',
    workHours: 'Working Hours',
    commissions: 'Commissions',
    history: 'History',
    contactInfo: 'Contact Information',
    employees: 'Employees',
    employeesList: 'A list of all registered employees',
    socialSecNumber: 'Social Security Number',
    office: 'Office',
    occupation: 'Occupation',
    dateOfBirth: 'Date of Birth',
    hireDate: 'Hire Date',
    dismissalDate: 'Dismissal Date',
    generalDataHelpText: 'General Data about this employee',
    company: 'Company',
    companyTaxNumber: 'Company Tax Id Number',
    contactInfoHelpText: 'Several ways to contact the employee',
    systemAccount: 'User Account',
    systemAccountHelpText: 'Related user profile of this employee in the system',
    savingProgress: 'Saving Progress',
    savingProgressHelpText: 'Your current data will be restored if you come back',
    employeeIsCompanyHelpText: 'Check this if employee is being hired as a company',
    itsCompany: "It's a Company",
    companyName: 'Company Name',
    companyTin: 'Company TIN',
    ssn: 'Social Security Number',
    weeklyHours: 'Weekly Hours',
    otherPaymentPreferences: 'Other Payment Preferences',
    methodOfPayments: 'Method of Payments',
    allowOvertimePayments: 'Allow Overtime Payments',
    allowOvertimePaymentsHelpText: 'Mark this to allow overtime pay to the employee.',
    selectBaseSalary: 'Select the Base Salary',
    hourlySalary: 'Hourly Salary',
    yearlySalary: 'Yearly Salary',
    weeklySalary: 'Weekly Salary',
    setManually: 'Set manually',
    computed: 'Computed',
    weekly: 'Weekly',
    biweekly: 'Biweekly',
    monthly: 'Monthly',
    preferredPaymentCycle: 'Preferred Payment Cycle',
    preferredPaymentCycleHelpText: 'How often the employee wants to get paid',
    newEmployee: 'New Employee',
    registerEmployee: 'Register Employee',
    selectEmployeeWorkingDays: 'Select Employee Working Days',
    workingHours: 'Working Hours',
    hoursWorked: 'Hours Worked',
    totalDailyHours: 'Total Daily Hours',
    totalHours: 'Total Hours ',
    entryTime: 'Entry Time',
    exitTime: 'Exit Time',
    salaryTypes: 'Salary Types',
    salaryTypesHelpText: 'Configured Salary Types for this Employee',
    fixedSalary: 'Fixed Salary',
    hourlyWage: 'Hourly Wage',
    commissionSalary: 'Commission Wage',
    individualCommissionSalary: 'Individual Commission Wage',
    employeePayRate: 'Employee Pay Rate',
    employeePayRateHelpText: 'Earning of this Employee Over a Period',
    otherPaymentPreferencesHelpText: 'Payment Cycle, Method and Others',
    currentSchedule: 'Current Schedule',
    thisEmployeeWorksOn: 'This employee works on',
    noPayrollsForEmployee: 'No past payrolls for this employee',
    noPayrollsForEmployeeHelpText: 'As soon as a payroll is generated, it will be available here',
    noCommissionsConfigForEmployee: 'No commissions configuration',
    noCommissionsConfigForEmployeeHelpText: 'Edit this employee to configure his commissions',
    noRelatedUserForEmployee: 'This employee has no related user account ',
    noRelatedUserForEmployeeHelpText: 'An available user is required for this',
    selectUserAccountForEmployee: 'Select one user account to link it',
    selectUserAccountForEmployeeHelpText:
        'The employee will be able to login in the system with this user',
    otherUserActionsHelpText: 'Some actions of the linked account',
    employeeLinkedAccountNotFound: 'No user accounts found',
    employeeLinkedAccountNotFoundHelpText:
        'Please update the values you are filtering on or contact your admin.',
    confirmLinkUserAccount: 'Link user account',
    linkUserAccountHelpText:
        'The employee will be able to login in the system with this user. Proceed?',
    unlinkUserAccount: 'Unlink user account',
    unlinkUserAccountHelpText:
        'This will unset the employee user account, but it will be not deleted. Proceed?',
    resendPasswordReset: 'Email Password Reset',
    confirmUnlinkUserAccount: 'Unlink User Account',
    otherActions: 'Other Actions',
    anyEmployee: 'Any Employee',
    addEmployee: 'Add Employee',
    reasonForChange: 'Reason For Change',
    workingHoursEditedSuccessfully: 'Working hours edited successfully',
    workingHoursCreatedSuccessfully: 'Schedule successfully recorded',
    selectOccupation: 'Select Occupation',
    unauthorizedOccupationsTemplatesList:
        "You don't have authorization to view occupations templates list",
    unauthorizedAddOccupationTemplate:
        "You don't have authorization to create an occupation template",
    unauthorizedEditOccupationTemplate:
        "You don't have authorization to edit an occupation template ",
    unauthorizedEmployeesStatistics: "You don't have authorization to view employees statistics",
    unauthorizedAddCommissionRule: "You don't have authorization to create commission rule",
    unauthorizedCommissionsRulesList: "You don't have authorization to view commissions rules list",
    nonOccupationTemplate:
        'This occupation has no defined template. You will have to fill in the data manually.',
    unauthorizedEmployeesDetails: "You don't have authorization to view employees details",
    unauthorizedEmployeesWorkingHours:
        "You don't have authorization to view the employee's hours worked",
    overtimeEditedSuccessfully: 'Overtime configuration edited successfully',
    overtimeAddedSuccessfully: 'Overtime configuration added successfully',
    noBonusesForEmployee: 'No bonuses for this payroll',
    noBonusesForEmployeeHelpText:
        'Use bonuses to reward achievements or exceptional behaviour on the payment period',
    addBonus: 'Add Bonus',
    addDeduction: 'Add Deduction',
    bonus: 'Bonus',
    deduction: 'Deduction',
    reason: 'Reason',
    noDeductionsForEmployee: 'No deductions for payroll',
    noDeductionsForEmployeeHelpText: 'Include taxes and other benefits like health insurance',
    included: 'Included',
    allowToSkipLunchBreakPunch: 'Allow to skip Lunch Break',
    currentScheduleHelpText: "Data of the Employee's Working Day",
    currentScheduleTitle: "Employee's Current Work Schedule",
    editCurrentRegister: 'Edit Current Register',
    editCurrentRegisterHelpText: 'Edit Current Register',
    searchPaymentPeriods: 'Search Payment Period',
    allPeriods: 'All Periods',
    noPaymentPeriods: 'No payment periods for this criteria',
    noPaymentPeriodsHelpText: 'Start a New One to Begin',
    selectPayrolls: 'Select Payrolls',
    overtime: 'Overtime',
    paymentDay: 'Payment Day',
    paymentPeriod: 'Payment Period',
    registeredHoursDoNotMatch: 'Registered hours do not match',
    registeredHoursDoNotMatchHelpText:
        'This means that { employeeName } has punches for this period' +
        ' not reflected on his payroll draft. Probably because they changed' +
        ' after the draft was created',
    updateUnregisteredHours: 'Do you want to refresh this draft to include the missing hours?',
    updateNow: 'Update now',
    noLeaveAsIs: 'Leave as it is',
    readyToPrint: 'Ready to print?',
    checkPrintingDate: 'Check printing date',
    includeThisPayrollForReview: 'Include this payroll for review',
    includeThisPayrollForReviewHelpText:
        'This will set this payroll as ready to pay. This action can be reverted later if you need it.',
    editEmployee: 'Edit Employee',
    selectTime: 'Select Schedule',
    sameSchedule: 'Set the same schedule for every day.',
    setSchedule: 'Set Schedule',
    schedulesSetUpSuccessfully: 'Schedules set up successfully',
    currentSchedulesByDay: 'Current Schedules By Day',
    scheduledEntryTime: 'Scheduled Entry Time',
    scheduledIn: 'Scheduled In',
    scheduledExitTime: 'Scheduled Exit Time',
    scheduledOut: 'Scheduled Out',
    timeTable: 'Time Table',
    scheduledTimeFor: 'Scheduled Time For ',
    allowOverNight: 'Allow Over Night',
    printCheck: 'Print Check',
    initialCheckNumber: 'Initial Check Number',
    finalCheckNumber: 'Final Check Number',
    titleErrorNumberChecks: 'Error en el Número de Cheques',
    messageErrorNumberChecks:
        'La cantidad de cheques no coincide con el número de pagos a efectuar.',
    scheduleForCurrentRecord: 'Schedule For Current Record',
    openNewPaymentPeriod: 'Open New Payment Period',
    draftCreatedOn: 'Draft Created On',
    currentDraft: 'Current Draft',
    editDraft: 'Edit Draft',
    completed: 'Completed',
    department: 'Department',
    paidByStructure: 'Report Of Money Paid In Payrolls By Structures',
    paidByEmployees: 'Report Of Money Paid In Payrolls By Employees',
    workedHoursByEmployees: 'Report Of Worked Hours In Payrolls By Employees',
    paidByStructureExplanatoryText:
        'Generates a report of how much money was paid in payroll by structure',
    paidByEmployeesExplanatoryText:
        'Generates a report of how much money was paid in payroll per employee',
    workedHoursByEmployeesExplanatoryText:
        'Generates a report of worked hours in payroll by employee',
    workedHours: 'Worked Hours',
    clickShowSsn: 'Click here to show the SSN.',
    clockInOptions: 'Clock In Options',
    mobileClockIn: 'Mobile Clock In',
    biometricClockIn: 'Biometric Clock In',
    unauthorizedEmployeesGeneralData: "You don't have authorization to view employees details",
    unauthorizedEmployeesSalaryData: "You don't have authorization to view employees salary data",
    unauthorizedEmployeesWorkHours: "You don't have authorization to view employees work hours",
    unauthorizedEmployeesCommissions: "You don't have authorization to view employees commissions",
    unauthorizedEmployeesPayrollHistory:
        "You don't have authorization to view employees payroll history",
    unauthorizedEmployeesEmployeeUserAccount:
        "You don't have authorization to view employees user account",
    defaultToOvertimeOnClockIn: 'Default to overtime on clock in',
    defaultToOvertimeOnClockInHelpText: 'ALl punches created will allow overtime',
};

export default messages;
