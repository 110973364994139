const AllCampaigns = () => import('../../pages/publicity/campaigns/AllCampaigns.vue');
const AddEditCampaign = () => import('../../pages/publicity/campaigns/AddEditCampaign.vue');

export const campaignRoutes = [
    {
        path: 'campaigns',
        name: 'Campaigns',
        component: AllCampaigns,
        meta: {
            menu: true,
            title: 'Campaigns',
            permissionsAnyOf: ['Permissions.Publicity.Campaigns.Search'],
        },
    },
    {
        path: 'campaigns/add',
        name: 'AddCampaign',
        component: AddEditCampaign,
    },
    {
        path: 'campaigns/edit/:id',
        name: 'EditCampaign',
        component: AddEditCampaign,
        props: true,
    },
];

export default campaignRoutes;
