const messages = {
    to: 'To',
    notReceiptFound: 'Not Receipt Found',
    noReceipt: 'Receipt Number',
    receipt: 'Receipt',
    referenceCode: 'Reference Code',
    transactionFee: 'Transaction Cost',
    patientNumber: 'Patient Number',
    invoice: 'Invoice',
    invoiceDate: 'Invoice Date',
    accountNumber: 'Account Number',
    invoiceDataNotAvailable: 'The invoice data is not available.',
    invoiceDataIsLoading: 'Invoice data is loading.',
};

export default messages;
