const messages = {
    anesthesiologist: 'Anesthesiologist',
    firstAssistant: 'First Assistant',
    secondAssistant: 'Second Assistant',
    appointmentDate: 'Appointment Date',
    note: 'Note',
    uploadChartDocument: 'Upload Chart Document',
    addYourComment: 'Add Your Comment',
    providerSchedule: 'Provider Schedule',
    notSchedulesAvailables: 'Not Schedules Availables',
    editMassageAppointment: 'Edit Massage Appointment',
    addMassageAppointment: 'Add Massage Appointment',
    otherMembers: 'Other Members',
    notDefined: 'Not Defined',
    nonSurgicalAppointments: 'Non Surgical Appointments',
    clientId: 'Client ID',
    confirmReschedule: 'Confirm / Reschedule',
    rejectPendingAppointment: 'Reject',
    appointmentCancelSuccessfully: 'Appointment cancel successfully',
    appointmentResetSuccessfully: 'Appointment reset successfully',
    youWantToCancelAppointment: 'Are you sure you want to cancel this appointment?',
    youWantToResetAppointment: 'Are you sure you want to reset this appointment?',
    myAppointments: 'My Appointments',
    appointmentTime: 'Appointment Time',
    confirmed: 'Confirmed',
    preOpProcessing: 'PreOp Processing',
    scheduled: 'Scheduled',
    appointmentStatus: 'Appointment Status',
    chartPercent: 'Chart %',
    balancePercent: 'Balance %',
    pendingAppointments: 'Pending Appointments',
    surgeonAppointmentsPendingOfConfirmation: 'Surgeon Appointments Pending of Confirmation',
    reschedule: 'ReSchedule',
    requiredConfirmation: 'Required Confirmation',
    filterAppointments: 'Filter Appointments',
    mySurgeryAppointments: 'My Surgery Appointments',
    member: 'Member',
    editSurgery: 'Edit Surgery',
    massagist: 'Massagist',
    surgeon: 'Surgeon',
    medicalProvider: 'Medical Provider',
    appointmentCapacity: 'Appointment Capacity',
    scheduleSettings: 'Schedule Settings',
    startTime: 'Start Time',
    capacity: 'Capacity',
    scheduleRemovedSuccessfully: 'Schedule removed successfully',
    confirmDeleteProvidersSchedule: "Confirm Delete Provider's Schedule",
    addNewProviderSchedule: 'Add New Provider Schedule',
    editProviderSchedule: 'Edit Provider Schedule',
    initPreOp: 'Start PreOp',
    endPreOp: 'End PreOp',
    date: 'Date',
    otherSchedule: 'Other Schedule',
    NonSurgical: 'Non Surgical',
    confirmReScheduleAppointment: 'Confirm or ReSchedule Appointment',
    scheduleType: 'Schedule Type',
    editNotes: 'Edit Notes',
    notify: 'Notify',
    observations: 'Observations',
    notifyPatientBySms: 'Notify by SMS',
    notifyPatientBySmsHelpText: 'This action will send a message to notify the patient',
    noticeReset: "This action will reset the current appointment's status back to pending",
};

export default messages;
