import routes from './routes';
import hrLocales from '@/locales/en/humanResources/index';
import accountingLocales from '@/locales/en/accounting/index';
import occupationsLocales from '@/locales/en/setting/index';
import structuresLocales from '@/locales/en/setting/index';
import overtimeLocales from '@/locales/en/setting';
import clientProfileLocales from '@/locales/en/clientProfile/index';
import productsLocales from '@/locales/en/setting/index';
import proceduresLocales from '@/locales/en/setting/index';
import rolesLocales from '@/locales/en/identity/index';
import statisticsLocales from '@/locales/en/statistics/index';
import paymentsLocales from '@/locales/en/accounting/index';
import frontDeskLocales from '@/locales/en/frontDesk/index';
import salesLocales from '@/locales/en/sales/index';
import publicityLocales from '@/locales/en/publicity/index';
import coordinationLocales from '@/locales/en/coordination/index';
import medicalLocales from '@/locales/en/medicalServices/medical.locales';
import invoiceLocales from '@/locales/en/invoice/index';
import customerServiceQA from '@/locales/en/customerServiceQA/index';
import inventory from '@/locales/en/inventory/index';
const locale = {
    en: {
        es: 'Spanish',
        en: 'English',
        yes: 'Yes',
        no: 'No',
        email: 'Email',
        name: 'Name',
        surname: 'Surname',
        birthDay: 'Birthday',
        photo: 'Photo',
        phone: 'Phone',
        address: 'Address',
        profile: 'Profile',
        account: 'Account',
        role: 'Role',
        language: 'Language',
        save: 'Save',
        saveChanges: 'Save Changes',
        password: 'Password',
        unlock: 'Unlock',
        actions: 'Actions',
        filter: 'Filter',
        edit: 'Edit',
        add: 'Add',
        subtract: 'Subtract',
        record: 'Record',
        accept: 'Accept',
        cancel: 'Cancel',
        emptyData: 'There is not data available...',
        added: '{entity} add successfully',
        edited: '{entity} edited successfully',
        searchBy: 'Search by {field}',
        active: 'Active',
        inactive: 'Inactive',
        activeDescription: 'The account is active.',
        ipRestricted: 'IP restricted',
        ipRestrictedDescription: 'The user is restricted to a list of ips.',
        requireClockIn: 'Require Clock In',
        requireClockInDescription: 'Require that the user clocks in before being able to log in.',
        twoFADescription: 'Two factor authentication is used to login.',
        twoFA: '2FA',
        registerAdmin: 'Register Administrator',
        existAdmin: 'Already exist an Administrator',
        registeredAdmin: 'User Administrator register successfully',
        infoAccount: 'Manage how information is displayed on your account.',
        infoProfile: 'This information will be displayed publicly so be careful what you share.',
        entry: 'Entry',
        exit: 'Exit',
        day: 'Day',
        filters: 'Filters',
        entity: {
            profile: 'Profile | Profiles',
            user: 'User | Users',
            occupation: 'occupation | occupations',
            office: 'office | offices',
        },
        details: 'Details',
        cellphone: 'Cellphone',
        address1: 'Address 1',
        address2: 'Address 2',
        zipCode: 'Zip code',
        city: 'City',
        state: 'State',
        country: 'Country',
        errorLoading: 'Error loading {entity}',
        discardChanges: 'Discard changes',
        next: 'Next',
        noSelection: 'No selection',
        bankCheck: 'Bank check',
        directDeposit: 'Direct deposit',
        monday: 'Monday',
        tuesday: 'Tuesday',
        wednesday: 'Wednesday',
        thursday: 'Thursday',
        friday: 'Friday',
        saturday: 'Saturday',
        sunday: 'Sunday',
        confirm: 'Confirm',
        departments: 'Departments',
        from: 'From',
        percentageInvalidRange: 'Percentage value must be between 0 and 100',
        disable: 'Disable',
        enable: 'Enable',
        proceed: 'Proceed',
        breakStart: 'Break Start',
        breakEnd: 'Break End',
        status: 'Status',
        startDate: 'Start Date',
        endDate: 'End Date',
        finalDate: 'End date',
        dposit: 'Deposit',
        occupationTemplate: 'Occupation Template',
        occupationOT: 'Occupation',
        salaryType: 'Salary Type',
        addTemplate: 'Add Template',
        selectTypeSalary: 'Select the Type of Salary',
        salaryByCommission: 'Salary by Commission',
        individualCommission: 'Individual Commission',
        filterOTemplates: 'Filter Occupations Templates',
        noDataAvailable: 'No data available.',
        salaryData: 'Salary Data',
        haveVacations: 'Have Vacations',
        createdBy: 'Created By',
        creationDate: 'Creation Date',
        paymentsMethods: 'Payments Methods',
        description: 'Description',
        user: 'User',
        dateofBirth: 'Date of Birth',
        dateofCapture: 'Date of Capture',
        searchByStatus: 'Search by Status',
        Assigned: 'Assigned',
        Sent: 'Sent',
        Created: 'Created',
        Pending: 'Pending',
        Sold: 'Sold',
        Void: 'Void',
        Unassigned: 'Unassigned',
        id: 'Identifier',
        clearAll: 'Clear All',
        lead: 'Lead',
        leads: 'Leads',
        addLead: 'Add Lead',
        created: 'Created',
        price: 'Price',
        Massagist: 'Massagist',
        Surgeon: 'Surgeon',
        MedicalProvider: 'Medical Provider',
        minimumPaid: 'Minimum Paid',
        maxPaid: 'Maximum Paid',
        searchMinimumPaid: 'Search for payments for the minimum paid',
        searchMaxPaid: 'Search for payments for the maximum paid',
        provider: 'Provider',
        providerName: 'Provider Name',
        dateOfCreation: 'Date of Creation',
        beneficiaryName: 'Beneficiary',
        concept: 'Concept',
        pdfInvoice: 'PDF invoice',
        done: 'Done',
        payments: 'Payments',
        graph: 'Gráfica',
        humanResources: 'Human Resources',
        age: 'Age',
        patient: 'Patient',
        totalAdding: 'Total Adding',
        totalSubtracting: 'Total Subtracting',
        adjustmentsTypes: 'Adjustments Types',
        initialDateTime: 'Initial Date',
        endDateTime: 'End Date',
        paymentStatistics: 'Payment Statistics',
        totalPaid: 'Total Paid',
        numberPaymentsMade: 'Number of Payments Made',
        amountSales: 'Amount of Sales',
        amountSalesBySeller: 'Sales by Seller',
        sellers: 'Sellers',
        minimumSales: 'Sales Minimum',
        maximumSales: 'Maximum Sales',
        patientId: 'Patient ID',
        patientName: 'Patient Name',
        sellerName: 'Seller Name',
        totalQuote: 'Total Quote',
        pendingAmount: 'Pending Amount',
        accountingStatistics: 'Accounting Statistics',
        identifier: 'Identifier',
        noSurgical: 'No Surgical',
        accessories: 'Accessories',
        salesStatistics: 'Sales Statistics',
        accountsReceivable: 'Accounts Receivable',
        salesByPatient: 'Sales by Patient',
        selectRange: 'Select Range',
        debtRange: 'Debt Range',
        selectTab: 'Select a Tab',
        paymentMethodAddedSuccessfully: 'Payment method added successfully',
        paymentMethodUpdatedSuccessfully: 'Payment method updated successfully',
        paymentMethodDeletedSuccessfully: 'Payment method deleted successfully',
        AllContexts: 'All Contexts',
        ClientProfile: 'Client Profile',
        Configuration: 'Configuration',
        HumanResources: 'HumanResources',
        Setting: 'Setting',
        templateAddedSuccessfully: 'Template added successfully',
        templateEditedSuccessfully: 'Template edited successfully',
        myPatients: 'My Patients',
        search: 'Search',
        comments: 'Comments',
        message: 'Message',
        call: 'Call',
        directory: 'Directory',
        searchByDate: 'Search by Date',
        productService: 'Product/Service',
        incidences: 'Incidences',
        Processing: 'Processing',
        Resolve: 'Resolve',
        MedicalServices: 'Medical Services',
        userName: 'User Name',
        productName: 'Product Name',
        availableProduct: 'Product Availability',
        productAttribute: 'Product Attribute',
        serviceName: 'Service Name',
        doctorName: 'Doctor Name',
        procedureName: 'Procedure Name',
        filterType: 'Filter Type',
        youNeedAMissingPermission: 'You need a missing permission to view this piece',
        youNeedAMissingPermissionHelpText:
            'Please ask your admin to grant you the << {permissionName} >> permission',
        print: 'Print',
        salesStatisticsExplanatoryText:
            'Sales statistics of leads by day, sales by type of procedures and by departments',
        accountsReceivableExplanatoryText: 'Accounts receivable by days, by amount and by month',
        ...hrLocales,
        ...accountingLocales,
        ...routes,
        ...occupationsLocales,
        ...structuresLocales,
        ...overtimeLocales,
        ...clientProfileLocales,
        ...productsLocales,
        ...proceduresLocales,
        ...rolesLocales,
        ...statisticsLocales,
        ...paymentsLocales,
        ...frontDeskLocales,
        ...salesLocales,
        ...publicityLocales,
        ...coordinationLocales,
        ...medicalLocales,
        ...customerServiceQA,
        ...invoiceLocales,
        ...inventory,
    },
};
export default locale;
