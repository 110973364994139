import { RouterView } from 'vue-router';
const SalesReports = () => import('@/pages/sales/reports/SalesReports.vue');
const CustomerServicesReports = () =>
    import('@/pages/customerServiceQA/reports/CustomerServiceQAReports.vue');

const reportsRoutes = [
    {
        path: 'reports',
        name: 'Reports',
        redirect: () => {
            return { name: 'SurgerySalesReport' };
        },
        component: RouterView,
        meta: {
            title: 'Reports',
            public: true,
            icon: 'ChartSquareBarIcon',
        },
        children: [
            {
                path: 'sales',
                name: 'SalesReports',
                component: SalesReports,
                meta: {
                    menu: true,
                    title: 'Sales reports',
                    permissionsAnyOf: [
                        'Permissions.Reports.SurgerySalesReport.View',
                        'Permissions.Reports.CosmetologySalesReport.View',
                        'Permissions.Reports.EarningsReport.View',
                    ],
                },
            },
            {
                path: 'customer-services',
                name: 'CustomerServicesReports',
                component: CustomerServicesReports,
                meta: {
                    menu: true,
                    title: 'Customer services reports',
                    permissionsAnyOf: ['Permissions.Reports.ComplaintsReport.View'],
                },
            },
        ],
    },
];

export default reportsRoutes;
