import axios from 'axios';
import { useIdentityStore } from '@/store/identity.store';
import { ProgressFinisher, useProgress } from '@marcoschulte/vue3-progress';
import { ErrorResult } from '@/composables';

const progresses = [] as ProgressFinisher[];
//const Controller = ref<AbortController>(new AbortController());

const HttpClient = axios.create({
    baseURL: `${import.meta.env.VITE_API_BASE_URL}v${import.meta.env.VITE_API_VERSION}/`,
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials: true,
});

HttpClient.interceptors.request.use(async (config) => {
    progresses.push(useProgress().start());
    //config.signal = Controller.value.signal;

    if (config.url?.includes('Account/Login') || config.url?.includes('Account/Refresh')) {
        return config;
    }

    if (config && config.headers && useIdentityStore().token())
        config.headers['Authorization'] = 'Bearer ' + useIdentityStore().token();

    const cultureCode = useIdentityStore().$state.data?.cultureCode;
    if (cultureCode) config.headers['Accept-Language'] = cultureCode;

    return config;
});

HttpClient.interceptors.response.use(
    (response) => {
        progresses.pop()?.finish();
        return response;
    },
    async (error) => {
        progresses.pop()?.finish();
        if (error.response?.status === 401) {
            const identityStore = useIdentityStore();
            if (identityStore.refreshTokenExpired()) {
                await identityStore.logout();
                return Promise.reject(
                    new ErrorResult(error.response?.data?.message, error.response?.status)
                );
            }
            if (identityStore.tokenExpired()) {
                try {
                    await identityStore.refresh();
                } catch {
                    return Promise.reject(
                        new ErrorResult(error.response?.data?.message, error.response?.status)
                    );
                }
            }
            error.config.headers['Authorization'] = 'Bearer ' + identityStore.token();
            return HttpClient(error.config);
        } else if (
            error.response &&
            error.response.status === 403 &&
            error.response.data.data?.redirectUrl
        ) {
            const identityStore = useIdentityStore();
            identityStore.data = null;
            window.location.href = error.response.data.data.redirectUrl;
            return Promise.reject(new ErrorResult(error, error.response.status));
        } else if (error.response?.status === 415) {
            return Promise.reject(
                new ErrorResult(error.response?.data?.title, error.response?.status)
            );
        } else if (error.response?.status === 422) {
            return Promise.reject(
                new ErrorResult(
                    error.response.data.message,
                    error.response?.status,
                    error.response.data.data
                )
            );
        } else if (error.response && error.response.status === 403) {
            if (error.response.data.allOf && error.response.data.allOf.length > 0)
                console.warn('BackEnd Missing Permissions: ' + error.response.data.allOf);
            if (error.response.data.anyOf && error.response.data.anyOf.length > 0)
                console.warn(
                    'BackEnd Missing Permissions From AnyOf: ' + error.response.data.anyOf
                );
            return Promise.reject(
                new ErrorResult(
                    error.response?.data?.message ?? 'An error has occurred',
                    error.response?.status
                )
            );
        } else if (error.code == 'ERR_CANCELED' || error.name == 'CanceledError') {
            return Promise.reject(new ErrorResult(error.message, error.response?.status, error));
        } else {
            return Promise.reject(
                new ErrorResult(
                    error.response?.data?.message ?? 'An error has occurred',
                    error.response?.status
                )
            );
        }
    }
);

//export { HttpClient, Controller };
export { HttpClient };
