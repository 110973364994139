const messages = {
    structuresTypes: 'Structures Types',
    addStructureType: 'Add Structure Type',
    editStructureType: 'Edit Structure Type',
    level: 'Level',
    informativeTextStructuresTypes: 'A list of all the structures types of the company.',
    parentStructure: 'Parent Structure',
    firstChild: 'Add Structure After:',
    secondChild: 'Add Structure Before:',
    companyStructure: 'Company Structure',
    addStructure: 'Add Structure',
    editStructure: 'Edit Structure',
    addStructureTo: 'Add Structure to ',
    addRootStructure: 'Add Root Structure',
    delete: 'Delete',
    confirmDeleteStructure: 'Are you sure you want to delete this structure?',
    structureDeletedSuccessfully: 'Structure deleted successfully',
    structureAddedSuccessfully: 'Structure added successfully',
    structureEditedSuccessfully: 'Structure edited successfully',
    structureTypeAddedSuccessfully: 'Structure added successfully',
    structureTypeEditedSuccessfully: 'Structure edited successfully',
};

export default messages;
