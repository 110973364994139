import { ref } from 'vue';
import { CityDto } from '@/models';

export const city = ref<CityDto>({ id: 0, name: '' });
export const state = ref<string>();
export const country = ref<string>();

export const setZipValRelatedData = (cit: CityDto, stat: string, countr: string) => {
    [city.value, state.value, country.value] = [cit, stat, countr];
};

export const resetZipValRelatedData = () =>
    ([city.value, state.value, country.value] = [{ id: 0, name: '' }, '', '']);
