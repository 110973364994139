const messages = {
    anesthesiologist: 'Anestesista',
    firstAssistant: 'Primer Asistente',
    secondAssistant: 'Segundo Asistente',
    appointmentDate: 'Fecha de la Cita',
    note: 'Nota',
    uploadChartDocument: 'Cargar Expediente Médico',
    addYourComment: 'Agrega tu Comentario',
    providerSchedule: 'Horario del Proveedor',
    notSchedulesAvailables: 'No hay horarios disponibles',
    editMassageAppointment: 'Editar Cita de Masaje',
    addMassageAppointment: 'Añadir Cita de Masaje',
    otherMembers: 'Otros Miembros',
    notDefined: 'No Definido',
    nonSurgicalAppointments: 'Citas no Quirúrgicas',
    clientId: 'ID del Cliente',
    confirmReschedule: 'Confirmar / Reprogramar',
    rejectPendingAppointment: 'Rechazar',
    appointmentCancelSuccessfully: 'Cita cancelada con éxito',
    appointmentResetSuccessfully: 'Cita actualizada con éxito',
    youWantToCancelAppointment: '¿Está seguro que desea cancelar esta cita?',
    youWantToResetAppointment: '¿Está seguro que desea resetear esta cita?',
    myAppointments: 'Mis Citas',
    appointmentTime: 'Hora de la Cita',
    confirmed: 'Confirmado',
    preOpProcessing: 'Procesamiento Preoperatorio',
    scheduled: 'Programado',
    appointmentStatus: 'Estado de las Citas',
    chartPercent: 'Chart %',
    balancePercent: 'Saldo %',
    pendingAppointments: 'Citas Pendientes',
    surgeonAppointmentsPendingOfConfirmation: 'Citas de cirujano pendientes de confirmación',
    reschedule: 'Reprogramar',
    requiredConfirmation: 'Confirmación Requerida',
    filterAppointments: 'Filtrar Citas',
    mySurgeryAppointments: 'Mis Citas de Cirugía',
    member: 'Miembro',
    editSurgery: 'Editar Cirugía',
    massagist: 'Masajista',
    surgeon: 'Cirujano',
    medicalProvider: 'Proveedor Médico',
    appointmentCapacity: 'Capacidad de Citas',
    scheduleSettings: 'Configuración del Horario',
    startTime: 'Hora de Inicio',
    capacity: 'Capacidad',
    scheduleRemovedSuccessfully: 'Programación eliminada con éxito',
    confirmDeleteProvidersSchedule: 'Confirmar Eliminación del Horario del Proveedor',
    addNewProviderSchedule: 'Añadir Nuevo Horario de Proveedor',
    editProviderSchedule: 'Editar Horario de Proveedor',
    initPreOp: 'Iniciar PreOp',
    endPreOp: 'Finalizar PreOp',
    date: 'Fecha',
    otherSchedule: 'Otro Horario',
    NonSurgical: 'No Quirúrgico',
    confirmReScheduleAppointment: 'Confirmar o Reagendar Cita',
    scheduleType: 'Tipo de Horario',
    editNotes: 'Editar Notas',
    notify: 'Notificar',
    observations: 'Observaciones',
    notifyPatientBySms: 'Notificar por SMS',
    notifyPatientBySmsHelpText: 'Esta acción enviará un mensaje para notificar al paciente',
    noticeReset: 'Esta acción restablecerá el estado actual de la cita a pendiente',
};

export default messages;
