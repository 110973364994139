const locale = {
    Settings: 'Configuración',
    Procedures: 'Procedimientos',
    Users: 'Usuarios',
    Roles: 'Roles',
    Publicity: 'Publicidad',
    Leads: 'Clientes Potenciales',
    Assets: 'Recursos',
    'Customer Source': 'Fuente del cliente',
    'First Contact': 'Primer Contacto',
    Contracts: 'Contratos',
    Campaigns: 'Campañas',
    'Planning Tasks': 'Tareas Planeadas',
    'Human Resources': 'Recursos Humanos',
    Employees: 'Empleados',
    'User Profile': 'Perfil de usuario',
    Home: 'Principal',
    Identity: 'Identidad',
    'Advertising TVs': 'Pantallas publicitarias',
    Accounting: 'Contabilidad',
    Inventory: 'Inventario',
    FrontDesk: 'Recepción',
    Sales: 'Ventas',
    'Assigned Leads': 'Leads Asignados',
    'Documents Types': 'Tipos de Documentos',
    Consents: 'Consentimientos',
    'Products Attributes': 'Atributos de Productos',
    'Doctors & Procedures': 'Doctores y Procedimientos',
    'Sale Products': 'Productos en Venta',
    Commissions: 'Comisiones',
    'Payments Methods': 'Métodos de Pago',
    'Occupations Templates': 'Plantillas de Ocupaciones',
    Payroll: 'Nómina',
    Payments: 'Pagos',
    Company: 'Compañía',
    Balances: 'Balances',
    Products: 'Productos',
    Patients: 'Pacientes',
    Earnings: 'Ganancias',
    'Daily Payments': 'Pagos Diarios',
    'All Leads': 'Todos los Leads',
    'Assign Assistants': 'Asignar Asistentes',
    'Chart Leads': 'Chart de los Leads',
    'Patients Chart': 'Chart de los Pacientes',
    'Payments Types': 'Tipos de Pagos',
    Statistics: 'Estadísticas',
    Sellers: 'Vendedores',
    Coordination: 'Coordinación',
    'Pending Appointments': 'Citas Pendientes',
    'Non surgical Appointments': 'Citas no Quirúrgicas',
    'Surgery Setup': 'Configuración de la Cirugía',
    Schedules: 'Horarios',
    'Schedule Settings': 'Ajuste de Horario',
    'Structures Types': 'Tipos de Estructuras',
    'Overtime Configurations': 'Configuración de Horas Extras',
    'Company Structure': 'Estructura de la Compañía',
    'My profile': 'Mi Perfil',
    'Change password': 'Cambiar Contraseña',
    Notifications: 'Notificaciones',
    Logout: 'Cerrar sesión',
    Occupations: 'Ocupaciones',
    'All Appointments': 'Todas las Citas',
    'Medical Services': 'Servicios Médicos',
    'Massage appointments': 'Citas de masaje',
    'Finished Services': 'Tratamientos Finalizados',
    Incidences: 'Incidencias',
    CustomerServiceQA: 'Atención al Cliente',
    Compensations: 'Compensaciones',
    'My Incidences': 'Mis Incidencias',
    'Appointment Services': 'Servicios de Citas',
    'Evaluation appointments': 'Citas de Evaluación',
    'Commissions config': 'Configuración de Comisión',
    'Nursing appointments': 'Citas de Enfermería',
    'No Surgical appointments': 'Citas No Quirúrgicas',
    Refunds: 'Reembolsos',
    Calendar: 'Calendar',
    Invoices: 'Facturas',
    Recuperation: 'Recuperación',
    ExternalProviders: 'Proveedores Externos',
    ExternalProvidersCategories: 'Categorías de Proveedores Externos',
    Appointments: 'Citas',
    'Current Patients': 'Pacientes Actuales',
    Cosmetology: 'Cosmetología',
    'My CheckIns': 'Mis CheckIns',
    'Massages Appointments': 'Citas de Masaje',
    Reports: 'Reportes',
    Chargebacks: 'Contracargos',
    'Incoming Leads': 'Nuevos Clientes Potenciales',
};
export default locale;
