import { RouterView } from 'vue-router';
const NonSurgicalPage = () => import('@/pages/medicalServices/nonSurgicals/NonSurgicalPage.vue');
const CosmetologyAppointments = () =>
    import('@/pages/sales/appointments/CosmetologyAppointments.vue');
const MedicalServicesPatients = () =>
    import('@/pages/medicalServices/patients/MedicalServicesPatients.vue');

const cosmetologyRoutes = [
    {
        path: 'cosmetology',
        name: 'Cosmetology',
        redirect: () => {
            return { path: '/cosmetology/appointments' };
        },
        component: RouterView,
        meta: {
            title: 'Cosmetology',
            public: false,
            icon: 'EyeIcon',
        },
        children: [
            {
                path: 'appointments',
                name: 'CosmetologySchedule',
                component: CosmetologyAppointments,
                meta: {
                    public: false,
                    menu: true,
                    title: 'Schedule',
                    header: false,
                    permissionsAllOf: [
                        'Permissions.Cosmetology.AssignedLeads.Search',
                        'Permissions.MedicalServices.NonSurgical.AppointmentsSearch',
                        'Permissions.MedicalServices.NonSurgical.CompleteNonSurgicalSession',
                        'Permissions.MedicalServices.NonSurgical.SearchNonSurgicalAppointments',
                    ],
                },
            },
            {
                path: 'non-surgical',
                name: 'CosmetologyAppointments',
                component: NonSurgicalPage,
                meta: {
                    public: false,
                    menu: true,
                    title: 'Appointments',
                    header: false,
                    permissionsAllOf: [
                        'Permissions.Cosmetology.AssignedLeads.Search',
                        'Permissions.MedicalServices.NonSurgical.AppointmentsSearch',
                        'Permissions.MedicalServices.NonSurgical.CompleteNonSurgicalSession',
                        'Permissions.MedicalServices.NonSurgical.SearchNonSurgicalAppointments',
                    ],
                },
            },
            {
                path: 'patients',
                name: 'CosmetologyPatients',
                component: MedicalServicesPatients,
                meta: {
                    public: false,
                    menu: true,
                    title: 'Patients',
                    header: true,
                    permissionsAllOf: [
                        'Permissions.Cosmetology.AssignedLeads.Search',
                        'Permissions.MedicalServices.Leads.Search',
                    ],
                },
            },
        ],
    },
];

export default cosmetologyRoutes;
