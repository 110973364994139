import { ageFunction, conditionalArrayPop } from '../utility.functions';
import { localize, setLocale } from '@vee-validate/i18n';
import { configure, defineRule } from 'vee-validate';
import i18n from './../plugins/i18n';
import {
    alpha_spaces as alphaSpaces,
    confirmed,
    email,
    image,
    max,
    ext as extValidator,
    max_value as maxVal,
    min,
    min_value as minVal,
    numeric,
    required,
    size,
} from '@vee-validate/rules';
import dayjs from 'dayjs';
import { setZipValRelatedData, resetZipValRelatedData } from '@/composables/zip-validation-effects';
import { AddressApi } from '@/openapi/address-api';

const addressApi = new AddressApi();

const strongPassword = (value) => {
    if (!value || value.length === 0) return true;
    //more than 8 characters, at least one capital letter, at least one number
    const strongRegex = new RegExp('^(?=.{8,}$)(?=.*?[A-Z])(?=.*?[0-9])');
    return strongRegex.test(value);
};

const validDomainValidator = (value) => {
    return /^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/.test(value);
};

const validSsnValidator = (value) => {
    return /^(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$/.test(value);
};

const validPhoneValidator = (value) => {
    if (!value || value.length === 0) return true;
    return /^\d{3}-\d{3}-\d{4}?$/.test(value);
};

const validTaxIdValidator = (value) => {
    return /^\d{2}-\d{7}$/.test(value);
};

const validPercentageValidator = (value) => {
    return /^((10000000(\.0{1,8})?)|(\d{1,8}(\.\d{1,2})?))$/.test(value);
};

const dateInTheFuture = (value: string) => {
    const unpackedVal = conditionalArrayPop(value);
    if (unpackedVal) return getDate(unpackedVal) > dayjs().toDate();
    return true;
};

const dateAfterField = (value, [target], ctx) => {
    const unpackedValue = conditionalArrayPop(value);
    const secondVal = conditionalArrayPop(ctx.form[target]);
    if (secondVal && unpackedValue) return getDate(unpackedValue) > getDate(secondVal);
    return true;
};

const dateBeforeField = (value, [target], ctx) => {
    const unpackedValue = conditionalArrayPop(value);
    const secondVal = conditionalArrayPop(ctx.form[target]);
    if (secondVal && unpackedValue) return getDate(unpackedValue) < getDate(ctx.form[target]);
    return true;
};

const isToday = (value) => {
    // corner case for litepie component to unpack
    // single value from array
    const unpackValue = conditionalArrayPop(value);
    if (unpackValue)
        return (
            dayjs().toISOString().split('T')[0] == getDate(unpackValue).toISOString().split('T')[0]
        );
    return true;
};

const isMoney = (value, [decimal]) => {
    const regex = new RegExp(`^\\$*\\d+(\\.\\d{1,${decimal ?? 2}})*$`, 'g');
    return value ? regex.test(value) : true;
};
const isDecimal = (value, [decimal]) => {
    const regex = new RegExp(`^\\$*\\d+(\\.\\d{1,${decimal ?? 2}})*$`, 'g');
    return value ? regex.test(value) : true;
};

const minPrice = (value: string, price: number) => {
    value = value.startsWith('$') ? value.slice(1) : value;
    return minVal(value, { min: price });
};

const timeAfterTime = (value: string, [target], ctx) => {
    if (ctx.form[target] && value)
        return dayjs(value, 'hh:mm A').isAfter(dayjs(ctx.form[target], 'hh:mm A'));
    return true;
};

const greaterThanField = (value: string, [target], ctx) => {
    if (ctx.form[target] && value) return Number(value) > Number(ctx.form[target]);
    return true;
};

const lessThanField = (value: string, [target], ctx) => {
    if (ctx.form[target] && value) return Number(value) < Number(ctx.form[target]);
    return true;
};

// ##### or #####-####
const validZipCode = (value) => /^\d{5}(?:[-\s]\d{4})?$/.test(value);
const getDate = (value) => {
    if (/^\d{2}-\d{2}-\d{4}?$/.test(value)) return dayjs(value, 'MM-DD-YYYY').toDate();
    else return new Date(value);
};
const existentZipCode = async (zip: string, [target], ctx) => {
    if (zip) {
        const r = await addressApi
            .addressFullAddressByZipCode(zip)
            .then((res) => {
                if (res.data.data.city)
                    setZipValRelatedData(
                        res.data.data.city,
                        res.data.data.state,
                        res.data.data.country
                    );
                else resetZipValRelatedData();
                return Boolean(res.data.data.city);
            })
            .catch(() => {
                resetZipValRelatedData();
                return false;
            });
        return r;
    } else {
        return true;
    }
};

const isPercentageValue = (value: string) => {
    let parsedVal: number;
    if (value) {
        parsedVal = Number(value.replace('%', ''));
        if (parsedVal > 100 || parsedVal < 0) return false;
        return true;
    }
    return true;
};
const lengthValidator = (value, params) => {
    const length = Array.isArray(params) ? params[0] : params['length'];
    if (value === '' || value === null || value === undefined) {
        return true;
    }
    if (typeof value === 'number') {
        value = String(value);
    }
    if (!value.length) {
        value = Array.from(value);
    }
    return value.length === Number(length);
};
export default {
    install(app) {
        strongPassword;
        defineRule('required', required);
        defineRule('min', min);
        defineRule('max', max);
        defineRule('alpha_spaces', alphaSpaces);
        defineRule('email', email);
        defineRule('numeric', numeric);
        defineRule('min_value', minVal);
        defineRule('max_value', maxVal);
        defineRule('length', lengthValidator);
        defineRule('passwordMismatch', confirmed);
        defineRule('domain', validDomainValidator);
        defineRule('image', image);
        defineRule('extValidator', extValidator);
        defineRule('fileSizeKb', size);
        defineRule('minAge', (value, min = 18) => {
            if (!value || value.length === 0) return true;
            return ageFunction(value) >= min;
        });
        defineRule('ssn', validSsnValidator);
        defineRule('phone', validPhoneValidator);
        defineRule('tax_id', validTaxIdValidator);
        defineRule('percentage', validPercentageValidator);
        defineRule('futureDate', dateInTheFuture);
        defineRule('pastDate', (value) => !dateInTheFuture(value));
        defineRule('isToday', isToday);
        defineRule('todayOrFuture', (value) => isToday(value) || dateInTheFuture(value));
        defineRule('dateBefore', dateBeforeField);
        defineRule('dateAfter', dateAfterField);
        defineRule('dateAfterDate', dateAfterField);
        defineRule('strongPassword', strongPassword);
        defineRule('zipCode', validZipCode);
        defineRule('isMoney', isMoney);
        defineRule('isDecimal', isDecimal);
        defineRule('minPrice', minPrice);
        defineRule('timeAfterTime', timeAfterTime); // 🎵🎵 if you're lost you can look and you will find me...
        defineRule('existentZipCode', existentZipCode);
        defineRule('isPercentage', isPercentageValue);
        defineRule('greaterThanField', greaterThanField);
        defineRule('lessThanField', lessThanField);

        configure({
            generateMessage: localize({
                es: {
                    names: {
                        name: 'nombre',
                        surName: 'apellidos',
                        surname: 'apellidos',
                        birthdate: 'fecha de nacimiento',
                        phone: 'teléfono',
                        password: 'contraseña',
                        confirm: 'confirmar',
                        role: 'rol',
                        email: 'correo',
                    },
                    messages: {
                        required: `El campo {field} es requerido.`,
                        length: `El campo {field} deveria tener como mínimo 0:{length} caracteres.`,
                        min: `El campo {field} deveria tener minimo 0:{length} caracteres.`,
                        email: `El campo {field} no es un correo valido.`,
                        numeric: `El campo {field} no es un numero.`,
                        strongPassword: `El campo {field} debe de tener mas de 8 caracteres, al menos una mayuscula y un numero.`,
                        minAge: `La edad minima permitida es 0:{min} años`,
                        passwordMismatch: `El campo {field} no corresponde.`,
                        domain: `El campo {field} no tiene un nombre de dominio valido.`,
                        image: `El campo {field}solo acepta imagenes`,
                        fileSizeKb: `El archivo {field} deberia ser menor de 0:{size} kb`,
                        alpha_spaces: `El campo {field} deberia contener solo letras y espacios`,
                        ssn: `El campo {field} debe tener el formato de un numero de seguridad social`,
                        phone: `El campo {field} debe tener el formato de un numero de phone.`,
                        futureDate: `El campo {field} solo admite fechas futuras`,
                        pastDate: `El campo {field} solo admite fechas pasadas`,
                        todayOrFuture: `El campo {field} admite la fecha actual o una fecha futura`,
                        dateBefore: `El campo {field} debe estar antes de 0:{target}`,
                        dateAfter: `El campo {field} debe estar despues de 0:{target}`,
                        isMoney: `El campo {field} debe ser un precio valido`,
                        isDecimal: `El campo {field} debe ser un decimal valido`,
                        min_value: `El mínimo {field} admitido es 0:{value}`,
                        max_value: `El máximo {field} admitido es 0:{value}`,
                        extValidator: ({ field, value, form, rule }) => {
                            return `El campo ${field} acepta solo las extensiones ${rule?.params}`;
                        },
                        dateAfterDate: ({ field, value, form, rule }) => {
                            return `El campo ${field} debe estar despues del ${
                                rule?.params
                                    ? dayjs(form[rule?.params[0]] as string).format('MM-DD-YYYY')
                                    : '-'
                            }`;
                        },
                        minPrice: `El  {field} minimo admitido es $0:{min}`,
                        timeAfterTime: `El {field} debe estar despues de 0:{field}`,
                        existentZipCode: 'El código zip proporcionado no existe',
                        validZipCode: 'El código zip no es válido',
                        isPercentage: 'El valor porcentual debe ser un número entre 0 y 100',
                        lessThanField: 'El valor debe ser menor que 0:{target}',
                        greaterThanField: 'El valor debe ser mayor que 0:{target}',
                        tax_id: `El campo {field} debe tener el formato de un NIT de empresa`,
                        percentage: `El campo {field} debe tener el formato de un valor porcentual`,
                    },
                },
                en: {
                    messages: {
                        required: `The field {field} is required.`,
                        length: `This field {field} should have 0:{length} characters.`,
                        min: `This field {field} should have as minimum 0:{length} characters.`,
                        email: `This field {field} is not a valid email.`,
                        numeric: `This field {field} is not a number.`,
                        strongPassword: `Field {field} must have more than 8 characters, at least one capital letter and one number.`,
                        minAge: `The minimum age allowed is 0:{min} years`,
                        passwordMismatch: `This field {field} does not match.`,
                        domain: `The field {field} has to be a valid domain name.`,
                        image: `The field {field} accepts only images`,
                        fileSizeKb: `The file {field} should be smaller than 0:{size} kb`,
                        alpha_spaces: `The field {field} should contain only letters and spaces`,
                        ssn: `The field {field} must have the format of a social security number`,
                        phone: `The field {field} must be a valid phone number format.`,
                        futureDate: `The field {field} only allows future dates`,
                        pastDate: `The field {field} only allows dates in the past`,
                        todayOrFuture: `The field {field} allows the current day or a date in the future`,
                        dateBefore: `The field {field} must be before 0:{target}`,
                        dateAfter: `The field {field} must come after 0:{target}`,
                        isMoney: `The field {field} must be a valid price`,
                        isDecimal: `The field {field} must be a valid decimal`,
                        min_value: `The minimum allowed {field} is 0:{value}`,
                        max_value: `The maximum allowed {field} is 0:{value}`,
                        extValidator: ({ field, value, form, rule }) => {
                            return `The field ${field} accepts only  ${rule?.params} extensions`;
                        },
                        dateAfterDate: ({ field, value, form, rule }) => {
                            return `The field ${field} must come after ${
                                rule?.params
                                    ? dayjs(form[rule?.params[0]] as string).format('MM-DD-YYYY')
                                    : '-'
                            }`;
                        },
                        minPrice: `The minimum allowed {field} is $0:{min}`,
                        timeAfterTime: `The {field} must be after 0:{field}`,
                        existentZipCode: 'This zip code does not exist',
                        validZipCode: 'This zip code is invalid',
                        isPercentage: 'Percentage value must be a number between 0 and 100',
                        lessThanField: 'Field must be less than 0:{target}',
                        greaterThanField: 'Field must be greater than 0:{target}',
                        tax_id: `The field {field} must have the format of a NIT company number`,
                        percentage: `The field {field} must have the format of a percentage value`,
                    },
                },
            }),
            validateOnBlur: true,
            validateOnChange: true,
            validateOnInput: true,
            validateOnModelUpdate: true,
        });
        setLocale(i18n.global.locale.value);
    },
};
