<script setup lang="ts">


import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { ExclamationIcon, XIcon } from '@heroicons/vue/outline';
import { useMainStore } from '@/store/main.store';

const { t } = useI18n({});
const store = useMainStore();
const options = computed(() => {
    return store.globalDialog.options;
});
const show = computed(() => store.globalDialog.open);
const cooldown = computed(() => store.globalDialog.cooldown);

const dialogSeverity = computed(() => {
    switch (options.value.severity) {
        case 'error':
            return ['bg-red-100', 'bg-red-600', 'text-red-600'];
        case 'warning':
            return ['bg-amber-100', 'bg-amber-600', 'bg-amber-600'];
        case 'info':
            return ['bg-blue-100', 'bg-blue-600', 'text-blue-600'];
        default:
            return ['bg-blue-100', 'bg-blue-600', 'text-blue-600'];
    }
});

function close() {
    store.$patch({
        globalDialog: {
            open: false,
            cooldown: 1,
            followUpAction: () => undefined,
            cancelAction: () => undefined,
            options: {
                ...options.value,
                cancelText: t('cancel'),
                confirmText: t('confirm'),
            },
        },
    });
}

function cancel() {
    store.globalDialog.cancelAction();
    close();
}

function runAction() {
    store.globalDialog.followUpAction();
    close();
}

</script>

<template>
    <TransitionRoot as="template" :show="show">
        <Dialog as="div" class="relative z-40" @close="cancel">
            <TransitionChild
                as="template"
                enter="ease-out duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in duration-200"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
                <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </TransitionChild>

            <div class="fixed z-21 inset-0 overflow-y-auto">
                <div
                    class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0"
                >
                    <TransitionChild
                        as="template"
                        enter="ease-out duration-300"
                        enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enter-to="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leave-from="opacity-100 translate-y-0 sm:scale-100"
                        leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <DialogPanel
                            class="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6"
                        >
                            <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                                <button
                                    type="button"
                                    class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    @click="close"
                                >
                                    <span class="sr-only">Close</span>
                                    <XIcon class="h-6 w-6" aria-hidden="true" />
                                </button>
                            </div>
                            <div class="sm:flex sm:items-start">
                                <div
                                    :class="[
                                        dialogSeverity[0],
                                        'mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10',
                                    ]"
                                >
                                    <ExclamationIcon
                                        :class="[dialogSeverity[2], 'h-6 w-6']"
                                        aria-hidden="true"
                                    />
                                </div>
                                <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                    <DialogTitle
                                        as="h3"
                                        class="text-lg leading-6 font-medium text-gray-900"
                                    >
                                        {{ options.title }}
                                    </DialogTitle>
                                    <div class="mt-2">
                                        <p class="text-sm text-gray-500">{{ options.text }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                <button
                                    type="button"
                                    :class="[
                                        dialogSeverity[1],
                                        'w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm',
                                    ]"
                                    :disabled="cooldown as boolean"
                                    @click="runAction"
                                >
                                    {{ cooldown ? cooldown : options.confirmText }}
                                </button>
                                <button
                                    type="button"
                                    class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                                    @click="cancel"
                                >
                                    {{ options.cancelText }}
                                </button>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>
