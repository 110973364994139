const AddTutorial = () => import('@/pages/publicity/tutorials/AddTutorial.vue');
const TutorialBrowser = () => import('@/pages/publicity/tutorials/TutorialBrowser.vue');
const TutorialsHome = () => import('@/pages/publicity/tutorials/TutorialsHome.vue');

const assetRoutes = [
    {
        path: 'tutorials',
        name: 'Tutorials',
        component: TutorialsHome,
        meta: {
            menu: false,
            title: 'Tutorials',
        },
    },
    {
        path: 'tutorials/view/:category/:tutorialId?',
        name: 'TutorialBrowser',
        component: TutorialBrowser,
        props: true,
    },
    {
        path: 'tutorials/add/:assetId',
        name: 'AddTutorial',
        component: AddTutorial,
        props: true,
    },
];

export default assetRoutes;
