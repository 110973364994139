const FrontPatients = () => import('../pages/frontdesk/tpv/FrontPatients.vue');
const FrontAppointments = () => import('../pages/frontdesk/checkIn/FrontAppointments.vue');
const DispatchAccessories = () => import('@/pages/frontdesk/tpv/dispatch/DispatchAccessories.vue');
const DailyPayments = () => import('../pages/frontdesk/reports/DailyPayments.vue');
const EarningsReport = () => import('../pages/frontdesk/reports/EarningsReport.vue');
import { RouterView } from 'vue-router';
const AllLeads = () => import('@/pages/publicity/leads/AllLeads.vue');
const AddEditLead = () => import('@/pages/publicity/leads/AddEditLead.vue');
const AllAppointments = () => import('@/pages/frontdesk/AllAppointments.vue');
const CheckOutSurgeryAppointment = () =>
    import('@/pages/medicalServices/surgeries/checkOut/CheckOutSurgeryAppointment.vue');
const frontDeskRoutes = [
    {
        path: 'frontdesk',
        name: 'Frontdesk',
        redirect: (to) => {
            return { path: '/frontdesk/tpv' };
        },
        component: RouterView,
        meta: {
            title: 'FrontDesk',
            public: false,
            icon: 'UserIcon',
        },
        children: [
            {
                path: 'tpv',
                name: 'FrontDeskTpv',
                component: FrontPatients,
                meta: {
                    menu: true,
                    title: 'Patients',
                    permissionsAnyOf: ['Permissions.FrontDesk.PublicityLeads.Search'],
                },
            },
            {
                path: 'check-in',
                name: 'FrontDeskPatients',
                component: FrontAppointments,
                meta: {
                    menu: true,
                    title: 'CheckIn',
                    permissionsAnyOf: ['Permissions.FrontDesk.Appointments.Search'],
                },
            },
            {
                path: 'check-in/dispatch/:id',
                name: 'FrontDeskCheckInDispatch',
                component: DispatchAccessories,
                meta: {
                    menu: false,
                    permissionsAnyOf: [
                        'Permissions.FrontDesk.Sales.Dispatch',
                        'Permissions.FrontDesk.PublicityLeads.Detail',
                        'Permissions.FrontDesk.Sales.Create',
                    ],
                },
                props: {
                    routeName: 'FrontDeskPatients',
                },
            },
            {
                path: 'tpv/dispatch/:id',
                name: 'FrontDeskDispatch',
                component: DispatchAccessories,
                permissionsAllOf: [
                    'Permissions.FrontDesk.Sales.Dispatch',
                    'Permissions.FrontDesk.PublicityLeads.Detail',
                    'Permissions.FrontDesk.Sales.Create',
                ],
            },
            {
                path: 'earnings',
                name: 'FrontDeskEarnings',
                component: EarningsReport,
                meta: {
                    menu: true,
                    title: 'Earnings',
                    permissionsAnyOf: ['Permissions.FrontDesk.Sales.EarningReport'],
                },
            },
            {
                path: 'daily-payments',
                name: 'FrontDeskDailyPayments',
                component: DailyPayments,
                meta: {
                    menu: true,
                    title: 'Daily Payments',
                    permissionsAnyOf: ['Permissions.FrontDesk.Sales.DailyPayments'],
                },
            },
            {
                path: 'front-leads',
                name: 'FrontDeskLeads',
                component: AllLeads,
                meta: {
                    menu: true,
                    title: 'Leads',
                    permissionsAnyOf: ['Permissions.FrontDesk.PublicityLeads.Search'],
                },
                props: {
                    routeAddName: 'FrontDeskAddLead',
                    routeEditName: 'FrontDeskEditLead',
                },
            },
            {
                path: 'front-leads/add',
                name: 'FrontDeskAddLead',
                component: AddEditLead,
                meta: {
                    permissionsAnyOf: ['Permissions.FrontDesk.PublicityLeads.Create'],
                },
                props: {
                    routeName: 'FrontDeskLeads',
                },
            },
            {
                path: 'front-leads/edit/:id',
                name: 'FrontDeskEditLead',
                component: AddEditLead,
                meta: {
                    permissionsAnyOf: ['Permissions.FrontDesk.PublicityLeads.Update'],
                },
                props: {
                    routeName: 'FrontDeskLeads',
                },
            },
            {
                path: 'appointments',
                name: 'AllAppointments',
                component: AllAppointments,
                meta: {
                    menu: true,
                    title: 'All Appointments',
                    permissionsAllOf: ['Permissions.FrontDesk.Appointments.Search'],
                },
                props: {
                    checkOutRoute: 'FrontDeskChangeCheckOutSurgeryAppointment',
                },
            },
            {
                path: 'appointments/change-check-out/:id/:patientId',
                name: 'FrontDeskChangeCheckOutSurgeryAppointment',
                component: CheckOutSurgeryAppointment,
                meta: {
                    public: false,
                    title: 'CheckOut',
                    header: true,
                },
                props: {
                    editable: true,
                    route: 'AllAppointments',
                },
            },
        ],
    },
];
export default frontDeskRoutes;
