const MyIncidences = () => import('@/pages/customerServiceQA/incidences/MyIncidences.vue');
import { RouterView } from 'vue-router';
const FinishedServices = () =>
    import('@/pages/customerServiceQA/finishedServices/FinishedServices.vue');
const CustomerSupportRefunds = () =>
    import('@/pages/customerServiceQA/refunds/CustomerSupportRefunds.vue');
const CustomerSupportIncidences = () =>
    import('@/pages/customerServiceQA/incidences/CustomerSupportIncidences.vue');
const CustomerSupportPatients = () =>
    import('@/pages/customerServiceQA/incidences/CustomerSupportPatients.vue');

const customerServiceQARoutes = [
    {
        path: 'customerServiceQA',
        name: 'customerServiceQA',
        redirect: (to) => {
            return { path: '/customerServiceQA/incidences' };
        },
        component: RouterView,
        meta: {
            title: 'CustomerServiceQA',
            public: false,
            icon: 'ChatAlt2Icon',
        },
        children: [
            {
                path: 'incidences',
                name: 'Incidences',
                component: CustomerSupportIncidences,
                meta: {
                    menu: true,
                    title: 'Incidences',
                    permissionsAnyOf: ['Permissions.CustomerServiceQA.Incidence.Incidences'],
                },
            },
            {
                path: 'my-incidences',
                name: 'MyIncidences',
                component: MyIncidences,
                meta: {
                    menu: true,
                    title: 'My Incidences',
                    permissionsAnyOf: ['Permissions.CustomerServiceQA.Incidence.MyIncidences'],
                },
            },
            {
                path: 'patients',
                name: 'Patients',
                component: CustomerSupportPatients,
                meta: {
                    menu: true,
                    title: 'Patients',
                    permissionsAnyOf: ['Permissions.CustomerServiceQA.PublicityLeads.Search'],
                },
            },
            {
                path: 'finished-services',
                name: 'FinishedServices',
                component: FinishedServices,
                meta: {
                    menu: true,
                    title: 'Finished Services',
                    permissionsAnyOf: [
                        'Permissions.CustomerServiceQA.AccountingFinishedServices.Search',
                    ],
                },
            },
            {
                path: 'refunds',
                name: 'Refunds',
                component: CustomerSupportRefunds,
                meta: {
                    menu: true,
                    title: 'Refunds',
                    permissionsAnyOf: ['Permissions.CustomerServiceQA.Compensation.Search'],
                },
            },
        ],
    },
];
export default customerServiceQARoutes;
