import { createApp } from 'vue';
import router from './routes/index';
import pinia from './store/index';
import VeeValidatePlugin from './plugins/validation.rules';
import './styles/tailwind.css';
import './styles/progress.css';
import App from './App.vue';
import { Vue3ProgressPlugin } from '@marcoschulte/vue3-progress';
import ServerNotifications from './plugins/server-notifications';

import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import { vMaska } from 'maska/vue';
import dayjs from 'dayjs';
import customParserFormat from 'dayjs/plugin/customParseFormat';
import objectSupport from 'dayjs/plugin/objectSupport';
import relativeTime from 'dayjs/plugin/relativeTime';
import duration from 'dayjs/plugin/duration';
import i18n from './plugins/i18n';

import 'viewerjs/dist/viewer.css';
import VueViewer from 'v-viewer';

dayjs.extend(customParserFormat);
dayjs.extend(objectSupport);
dayjs.extend(relativeTime);
dayjs.extend(duration);

const app = createApp(App);
app.use(router);
app.use(pinia);
app.use(VeeValidatePlugin);
app.use(i18n);
app.directive('maska', vMaska);
app.use(Vue3ProgressPlugin);
app.use(ServerNotifications);
app.use(VueViewer);
app.component('DatePicker', Datepicker);

app.mount('#app');
