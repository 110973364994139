const FirstContacts = () => import('../../pages/publicity/firstcontacts/FirstContacts.vue');
const AddFirstContact = () => import('../../pages/publicity/firstcontacts/AddFirstContact.vue');
const EditFirstContact = () => import('../../pages/publicity/firstcontacts/EditFirstContact.vue');

const firstContactRoutes = [
    {
        path: 'first-contacts',
        name: 'PublicityFirstContacts',
        component: FirstContacts,
        meta: {
            menu: true,
            title: 'First Contact',
            permissionsAnyOf: ['Permissions.Publicity.FirstContacts.Search'],
        },
    },
    {
        path: 'first-contacts/add',
        name: 'PublicityFirstContactAdd',
        component: AddFirstContact,
    },
    {
        path: 'first-contacts/edit/:id',
        name: 'PublicityFirstContactEdit',
        component: EditFirstContact,
    },
];

export default firstContactRoutes;
