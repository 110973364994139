const messages = {
    productAttributes: 'Product Attributes',
    textProductsAttributes: 'The attributes belong to a product.',
    attributes: 'Attributes',
    exclusiveProducts: 'Exclusive for Products',
    addProductAttributes: 'Add Product Attributes',
    notProductAttributes: 'Not Product Attributes',
    typeAttribute: 'Type Attribute',
    detailsFor: 'Details for',
    information: 'Information',
    products: 'Products',
    addAttributesProductService: 'Add new product & service attributes',
    editAttributesProductService: 'Edit product & service attributes',
    picture: 'Picture',
    addNewProduct: 'Add New Product',
    editProduct: 'Edit Product',
    productAddedSuccessfully: 'Product added successfully',
    productUpdatedSuccessfully: 'Product updated successfully',
    confirmDeleteProduct: 'Confirm disable product',
    productAvailable: 'Product is available',
    unavailable: 'Unavailable',
    available: 'Available',
    attribute: 'Attribute',
    salable: 'Salable',
    theProductIsSalable: 'This product can be sold',
    productType: 'Product type',
    inventoryByOffice: 'Inventory by office',
    sourceOffice: 'Source office',
    destinationOffice: 'Destination office',
    barcode: 'Barcode',
};

export default messages;
