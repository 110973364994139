const locale = {
    Settings: 'Settings',
    Procedures: 'Procedures',
    Users: 'Users',
    Roles: 'Roles',
    Publicity: 'Publicity',
    Leads: 'Leads',
    Assets: 'Assets',
    'Customer Source': 'Customer Source',
    'First Contact': 'First Contact',
    Contracts: 'Contracts',
    Campaigns: 'Campaigns',
    'Planning Tasks': 'Planning Tasks',
    'Human Resources': 'Human Resources',
    Employees: 'Employees',
    'User Profile': 'User Profile',
    Home: 'Home',
    Identity: 'Identity',
    'Advertising TVs': 'Advertising TVs',
    Accounting: 'Accounting',
    Inventory: 'Inventory',
    FrontDesk: 'FrontDesk',
    Sales: 'Sales',
    'Assigned Leads': 'Assigned Leads',
    'Documents Types': 'Documents Types',
    Consents: 'Consents',
    'Products Attributes': 'Products Attributes',
    'Doctors & Procedures': 'Doctors & Procedures',
    'Sale Products': 'Sale Products',
    Commissions: 'Commissions',
    'Payments Methods': 'Payments Methods',
    'Occupations Templates': 'Occupations Templates',
    Payroll: 'Payroll',
    Payments: 'Payments',
    Company: 'Company',
    Balances: 'Balances',
    Products: 'Products',
    Patients: 'Patients',
    Earnings: 'Earnings',
    'Daily Payments': 'Daily Payments',
    'All Leads': 'All Leads',
    'Assign Assistants': 'Assign Assistants',
    'Chart Leads': 'Chart Leads',
    'Patients Chart': 'Patients Chart',
    'Payments Types': 'Payments Types',
    Statistics: 'Statistics',
    Sellers: 'Sellers',
    Coordination: 'Coordination',
    'Pending Appointments': 'Pending Appoinments',
    'Non surgical Appointments': 'Non Surgical Appointments',
    'Surgery Setup': 'Surgery Setup',
    Schedules: 'Schedules',
    'Schedule Settings': 'Schedule Setting',
    'Structures Types': 'Structures Types',
    'Overtime Configurations': 'Overtime Configurations',
    'Company Structure': 'Company Structure',
    'My profile': 'My Profile',
    'Change password': 'Change Password',
    Notifications: 'Notifications',
    Logout: 'Logout',
    Occupations: 'Occupations',
    'Medical Services': 'Medical Services',
    'Massage appointments': 'Massage appointments',
    'All Appointments': 'All Appointments',
    'Finished Services': 'Finished Treatments',
    Incidences: 'Incidences',
    CustomerServiceQA: 'Customer Support',
    Compensations: 'Compensations',
    'My Incidences': 'My Incidences',
    'Appointment Services': 'Appointment Services',
    'Evaluation appointments': 'Evaluation appointments',
    'Commissions config': 'Commissions config',
    'Nursing appointments': 'Nursing appointments',
    'No Surgical appointments': 'No Surgical appointments',
    Refunds: 'Refunds',
    Calendar: 'Calendar',
    Invoices: 'Invoices',
    Recuperation: 'Recuperation',
    Appointments: 'Appointments',
    'Current Patients': 'Current Patients',
    Cosmetology: 'Cosmetology',
    'My CheckIns': 'My CheckIns',
    ExternalProviders: 'External Providers',
    ExternalProvidersCategories: 'External Providers Categories',
    'Massages Appointments': 'Massages Appointments',
    Reports: 'Reports',
    Chargebacks: 'Chargebacks',
    'Incoming Leads': 'Incoming Leads',
};
export default locale;
