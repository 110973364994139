import { ref } from 'vue';

/**
 * @deprecated Dont use global state for the filters. Use useModal instead
 */
export const isOpen = ref(false);

export function useModal() {
    const isOpen = ref(false);
    return {
        isOpen,
        openModal: () => (isOpen.value = true),
        closeModal: () => (isOpen.value = false),
    };
}

/**
 * @deprecated The method should not be used. Use useModal instead
 */
export function closeModal() {
    isOpen.value = false;
}

/**
 * @deprecated The method should not be used. Use useModal instead
 */
export function openModal() {
    isOpen.value = true;
}
