const messages = {
    cantEmployees: 'Cantidad de Empleados',
    January: 'Enero',
    December: 'Diciembre',
    November: 'Noviembre',
    October: 'Octubre',
    September: 'Septiembre',
    August: 'Agosto',
    July: 'Julio',
    June: 'Junio',
    May: 'Mayo',
    April: 'Abril',
    March: 'Marzo',
    February: 'Febrero',
};

export default messages;
