const messages = {
    documentsTypes: 'Documents Types',
    documentType: 'Document Type',
    consents: 'Consents',
    isRequired: 'Is Required',
    signatureRequired: 'Signature Required',
    generalAnesthesia: 'General Anesthesia',
    documentLanguage: 'Document Language',
    addDocumentType: 'Add Document Type',
    editDocumentType: 'Editar Tipo de Documento',
    addConsent: 'Add Consent',
    editConsent: 'Edit Consent',
    addPaymentMethod: 'Add Payment Method',
    editPaymentMethod: 'Edit Payment Method',
    height: 'Height',
    weight: 'Weight',
    drugs: 'Drugs',
    alcohol: 'Alcohol',
    nicotine: 'Nicotine',
    medicalAilments: 'Medical Ailments',
    medicines: 'Medicines',
    otherMedicalConditions: 'Other Medical Conditions',
    addYourComment: 'Add Your Comment',
    viewMore: 'View More',
    addedAComment: 'Added a comment',
    addedTags: 'added tags',
    viewDetailsFor: 'View Details For',
    medicalRecord: 'Medical Record',
    surgicalHistory: 'Surgical History',
    imageType: 'Image Type',
    before: 'Before',
    after: 'After',
    consentEditedSuccesfully: 'Consent edited successfully',
    editProfile: 'Edit Profile',
    size: 'Size',
    pountAbbreviation: 'Lbs',
    bmi: 'BMI',
    deprecadedHeight:
        'Deprecaded Height: Retained for reference purposes only, to configure the new height field.',
};

export default messages;
