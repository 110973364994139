import { defineStore } from 'pinia';
import { EmployeeDto } from '@/models';
interface ServiceTimer {
    timer: string;
    timeBegan: Date | string | null;
    timeStopped: Date | string | null;
    stoppedDuration: number;
    started: number | null;
    running: boolean;
}
export const TimerProcess = {
    MASSAGE: 'massage',
    NONSURGICAL: 'nonsurgical',
};
export const useMedicalServicesStore = defineStore('medicalServices', {
    persist: {
        key: 'medicalServices',
        storage: sessionStorage,
    },
    state: () => ({
        massage: {
            serviceTime: {
                timer: '00:00',
                selectedItem: null,
                selectedDate: null,
                timeBegan: null,
                timeStopped: null,
                stoppedDuration: 0,
                started: null,
                running: false,
            } as ServiceTimer,
        },
        nonsurgical: {
            serviceTime: {
                timer: '00:00',
                selectedItem: null,
                selectedDate: null,
                timeBegan: null,
                timeStopped: null,
                stoppedDuration: 0,
                started: null,
                running: false,
            } as ServiceTimer,
        },
        selectedSurgeon: {} as EmployeeDto,
    }),
    getters: {
        getServiceStarted:
            (state) =>
            (process: string = TimerProcess.MASSAGE) =>
                !!state[process].serviceTime.timeBegan,
        getServiceSelectedItem:
            (state) =>
            (process: string = TimerProcess.MASSAGE) =>
                state[process].serviceTime.selectedItem,
        getServiceSelectedDate:
            (state) =>
            (process: string = TimerProcess.MASSAGE) =>
                state[process].serviceTime.selectedDate,
        getServiceTime:
            (state) =>
            (process: string = TimerProcess.MASSAGE) =>
                state[process].serviceTime.timer,
        getTimerRunning:
            (state) =>
            (process: string = TimerProcess.MASSAGE) =>
                state[process].serviceTime.running,
    },
    actions: {
        zeroPrefix(num, digit) {
            let zero = '';
            for (let i = 0; i < digit; i++) {
                zero += '0';
            }
            return (zero + num).slice(-digit);
        },
        resetTimer(process: string = TimerProcess.MASSAGE) {
            this[process].serviceTime.running = false;
            clearInterval(this[process].serviceTime.started);
            this[process].serviceTime.stoppedDuration = 0;
            this[process].serviceTime.timeBegan = null;
            this[process].serviceTime.timeStopped = null;
            this[process].serviceTime.selectedItem = null;
            this[process].serviceTime.selectedDate = null;
            this[process].serviceTime.timer = '00:00';
        },
        setInitialTime(initialMinutes: number, process: string) {
            this.resetTimer(process);
            this[process].serviceTime.timeBegan =
                new Date().getTime() - new Date().setMinutes(initialMinutes, 0, 0);
            const timeElapsed = new Date(
                (new Date().getTime() -
                    this[process].serviceTime.timeBegan -
                    this[process].serviceTime.stoppedDuration) as number
            );
            this[process].serviceTime.timer =
                this.zeroPrefix(timeElapsed.getUTCMinutes(), 2) +
                ':' +
                this.zeroPrefix(timeElapsed.getUTCSeconds(), 2);
        },
        clockMassageRunning() {
            this.massage.serviceTime.timeBegan =
                typeof this.massage.serviceTime.timeBegan === 'string'
                    ? new Date(this.massage.serviceTime.timeBegan)
                    : this.massage.serviceTime.timeBegan;
            this.massage.serviceTime.timeStopped =
                typeof this.massage.serviceTime.timeStopped === 'string'
                    ? new Date(this.massage.serviceTime.timeStopped)
                    : this.massage.serviceTime.timeStopped;
            const currentTime = new Date(),
                timeElapsed = new Date(
                    ((currentTime as any) -
                        (this.massage.serviceTime.timeBegan as any) -
                        this.massage.serviceTime.stoppedDuration) as number
                ),
                min = timeElapsed.getUTCMinutes(),
                sec = timeElapsed.getUTCSeconds();

            this.massage.serviceTime.timer =
                this.zeroPrefix(min, 2) + ':' + this.zeroPrefix(sec, 2);
        },
        clockNonSurgicalRunning() {
            this.nonsurgical.serviceTime.timeBegan =
                typeof this.nonsurgical.serviceTime.timeBegan === 'string'
                    ? new Date(this.nonsurgical.serviceTime.timeBegan)
                    : this.nonsurgical.serviceTime.timeBegan;
            this.nonsurgical.serviceTime.timeStopped =
                typeof this.nonsurgical.serviceTime.timeStopped === 'string'
                    ? new Date(this.nonsurgical.serviceTime.timeStopped)
                    : this.nonsurgical.serviceTime.timeStopped;
            const currentTime = new Date(),
                timeElapsed = new Date(
                    ((currentTime as any) -
                        (this.nonsurgical.serviceTime.timeBegan as any) -
                        this.nonsurgical.serviceTime.stoppedDuration) as number
                ),
                min = timeElapsed.getUTCMinutes(),
                sec = timeElapsed.getUTCSeconds();

            this.nonsurgical.serviceTime.timer =
                this.zeroPrefix(min, 2) + ':' + this.zeroPrefix(sec, 2);
        },
        startTimer(
            selectedItem: string,
            selectedDate: Date,
            process: string = TimerProcess.MASSAGE
        ) {
            if (this[process].serviceTime.running) return;

            if (this[process].serviceTime.timeBegan === null) {
                this.resetTimer(process);
                this[process].serviceTime.timeBegan = new Date();
            }

            if (this[process].serviceTime.timeStopped !== null) {
                this[process].serviceTime.timeStopped =
                    typeof this[process].serviceTime.timeStopped === 'string'
                        ? new Date(this[process].serviceTime.timeStopped)
                        : this[process].serviceTime.timeStopped;
                this[process].serviceTime.stoppedDuration +=
                    (new Date() as any) - (this[process].serviceTime.timeStopped as any);
            }
            this[process].serviceTime.selectedItem = selectedItem;
            this[process].serviceTime.selectedDate = selectedDate;
            this[process].serviceTime.started =
                process === TimerProcess.MASSAGE
                    ? window.setInterval(this.clockMassageRunning, 500)
                    : window.setInterval(this.clockNonSurgicalRunning, 500);
            this[process].serviceTime.running = true;
        },
        stopTimer(process: string = TimerProcess.MASSAGE) {
            this[process].serviceTime.running = false;
            this[process].serviceTime.timeStopped = new Date();
            clearInterval(this[process].serviceTime.started);
        },
        startProcess(
            selectedItem: string,
            selectedDate: Date,
            process: string = TimerProcess.MASSAGE
        ) {
            this[process].serviceTime.selectedItem = selectedItem;
            this[process].serviceTime.selectedDate = selectedDate;
        },
        stopProcess(process: string = TimerProcess.MASSAGE) {
            this[process].serviceTime.selectedItem = null;
            this[process].serviceTime.selectedDate = null;
        },
    },
});
