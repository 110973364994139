import routes from './routes';
import hrLocales from '@/locales/es/humanResources/index';
import accountingLocales from '@/locales/es/accounting/index';
import occupationsLocales from '@/locales/es/setting/index';
import structuresLocales from '@/locales/es/setting/index';
import overtimeLocales from '@/locales/es/setting/index';
import clientProfileLocales from '@/locales/es/clientProfile/index';
import productsLocales from '@/locales/es/setting/index';
import proceduresLocales from '@/locales/es/setting/index';
import rolesLocales from '@/locales/es/identity/index';
import statisticsLocales from '@/locales/es/statistics/index';
import paymentsLocales from '@/locales/es/accounting/index';
import frontDeskLocales from '@/locales/es/frontDesk/index';
import salesLocales from '@/locales/es/sales/index';
import publicityLocales from '@/locales/es/publicity/index';
import coordinationLocales from '@/locales/es/coordination/index';
import medicalLocales from '@/locales/es/medicalServices/medical.locales';
import invoiceLocales from '@/locales/es/invoice/index';
import customerServiceQA from '@/locales/es/customerServiceQA/index';
import inventory from '@/locales/es/inventory/index';

const locale = {
    es: {
        es: 'Español',
        en: 'Ingles',
        yes: 'Sí',
        no: 'No',
        email: 'Correo Electrónico',
        name: 'Nombre',
        surname: 'Apellidos',
        birthDay: 'Fecha de Nacimiento',
        phone: 'Teléfono',
        address: 'Dirección',
        photo: 'Foto',
        profile: 'Perfil',
        account: 'Cuenta',
        role: 'Rol',
        language: 'Idioma',
        save: 'Guardar',
        saveChanges: 'Guardar Cambios',
        password: 'Contraseña',
        unlock: 'Desbloquear',
        actions: 'Acciones',
        filter: 'Filtrar',
        add: 'Adicionar',
        subtract: 'Sustraer',
        record: 'Historial',
        edit: 'Editar',
        accept: 'Aceptar',
        cancel: 'Cancelar',
        emptyData: 'No hay datos disponibles...',
        added: '{entity} adicionado exitosamente',
        edited: '{entity} editado exitosamente',
        searchBy: 'Buscar por {field}',
        active: 'Activo',
        inactive: 'Inactivo',
        activeDescription: 'La cuenta esta activa',
        ipRestricted: 'Restringido por IP',
        ipRestrictedDescription: 'El usuario esta restringido a un listado de ips.',
        requireClockIn: 'Require Clock In',
        requireClockInDescription: 'Require that the user clocks in before being able to log in.',
        twoFADescription: 'Autenticación de dos factores empleada para loguearse.',
        twoFA: '2FA',
        registerAdmin: 'Registrar Administrador',
        existAdmin: 'Ya existe un Administrador',
        registeredAdmin: 'Usuario Administrador registrado exitosamente',
        infoAccount: 'Administra cómo se muestra la información en su cuenta.',
        infoProfile:
            'Esta información se mostrará públicamente, así que tenga cuidado con lo que comparte.',
        entry: 'Entrada',
        exit: 'Salida',
        day: 'Día',
        filters: 'Filtros',
        entity: {
            profile: 'Perfil | Perfiles',
            user: 'Usuario | Usuarios',
            occupation: 'ocupación | ocupaciones',
            office: 'oficina | oficinas',
        },
        details: 'Detalles',
        cellphone: 'Teléfono Celular',
        address1: 'Dirección 1',
        address2: 'Dirección 2',
        zipCode: 'Código Zip',
        city: 'Ciudad',
        state: 'Estado',
        country: 'País',
        errorLoading: 'Error al cargar {entity}',
        hiringDate: 'Fecha de Contratación',
        discardChanges: 'Descartar Cambios',
        next: 'Siguiente',
        noSelection: 'Sin selección',
        bankCheck: 'Cheque Bancario',
        directDeposit: 'Depósito Directo',
        monday: 'Lunes',
        tuesday: 'Martes',
        wednesday: 'Miércoles',
        thursday: 'Jueves',
        friday: 'Viernes',
        saturday: 'Sábado',
        sunday: 'Domingo',
        confirm: 'Confirmar',
        departments: 'Departamentos',
        from: 'Desde',
        percentageInvalidRange: 'El valor porcentual debe estar entre 0 y 100',
        disable: 'Desabilitar',
        enable: 'Habilitar',
        proceed: 'Continuar',
        breakStart: 'Inicio del Descanso',
        breakEnd: 'Fin del Descanso',
        status: 'Estado',
        startDate: 'Fecha de Inicio',
        endDate: 'Fecha de Fin',
        finalDate: 'Fecha de Fin',
        dposit: 'Depósito',
        occupationTemplate: 'Plantilla de Ocupación',
        occupationOT: 'Ocupación',
        salaryType: 'Tipo de Salario',
        addTemplate: 'Adicionar Plantilla',
        selectTypeSalary: 'Selecciona el tipo de salario',
        salaryByCommission: 'Salario por Comisión',
        individualCommission: 'Salario individual por comisión ',
        filterOTemplates: 'Filtrar plantillas de ocupaciones',
        noDataAvailable: 'No hay datos disponibles.',
        salaryData: 'Datos Salariales',
        haveVacations: 'Tiene derecho a vacaciones',
        createdBy: 'Creado Por',
        creationDate: 'Fecha de Creación',
        paymentsMethods: 'Métodos de Pago',
        description: 'Descripción',
        user: 'Usuario',
        dateofBirth: 'Fecha de Nacimiento',
        dateofCapture: 'Fecha de Captación',
        searchByStatus: 'Buscar por Estado',
        Assigned: 'Asignado',
        Sent: 'Enviado',
        Created: 'Creado',
        Pending: 'Pendiente',
        Sold: 'Vendido',
        Void: 'Vacío',
        Unassigned: 'No Asignado',
        id: 'Identificador',
        clearAll: 'Limpiar Todo',
        lead: 'Cliente Potencial',
        leads: 'Clientes Potenciales',
        addLead: 'Añadir Cliente Potencial',
        created: 'Creado',
        price: 'Precio',
        Massagist: 'Masajista',
        Surgeon: 'Cirujano',
        MedicalProvider: 'Proveedor Médico',
        minimumPaid: 'Mínimo Pagado',
        maxPaid: 'Máximo Pagado',
        searchMinimumPaid: 'Buscar pagos por el mínimo pagado',
        searchMaxPaid: 'Buscar pagos por el máximo pagado',
        provider: 'Proveedor',
        providerName: 'Nombre del Proveedor',
        dateOfCreation: 'Fecha de Creación',
        beneficiaryName: 'Beneficiario',
        concept: 'Concepto',
        pdfInvoice: 'PDF de Factura',
        done: 'Hecho',
        payments: 'Pagos',
        graph: 'Gráfica',
        humanResources: 'Recursos Humanos',
        age: 'Edad',
        patient: 'Paciente',
        totalAdding: 'Suma Total',
        totalSubtracting: 'Resta Total',
        adjustmentsTypes: 'Tipos de Ajustes',
        initialDateTime: 'Fecha Inicial',
        endDateTime: 'Fecha Final',
        paymentStatistics: 'Estadísticas de Pago',
        totalPaid: 'Total Pagado',
        numberPaymentsMade: 'Cantidad de Pagos Realizados',
        amountSales: 'Cantidad de Ventas',
        amountSalesBySeller: 'Ventas por Vendedor',
        sellers: 'Vendedores',
        minimumSales: 'Mínimo de Ventas',
        maximumSales: 'Máximo de Ventas',
        patientId: 'ID del Paciente',
        patientName: 'Nombre del Paciente',
        sellerName: 'Nombre del Vendedor',
        totalQuote: 'Total de la Cuota',
        pendingAmount: 'Monto Pendiente',
        accountingStatistics: 'Estadísticas de Contabilidad',
        identifier: 'Identificador',
        noSurgical: 'No Quirúrgico',
        accessories: 'Accesorios',
        salesStatistics: 'Estadísticas de Ventas',
        accountsReceivable: 'Cuentas por Cobrar',
        salesByPatient: 'Ventas por Pacientes',
        selectRange: 'Seleccionar Rango',
        debtRange: 'Rango de Deudas',
        paymentMethodAddedSuccessfully: 'Método de pago adicionado exitosamente',
        paymentMethodUpdatedSuccessfully: 'Método de pago editado exitosamente',
        paymentMethodDeletedSuccessfully: 'Método de pago eliminado exitosamente',
        selectTab: 'Seleccione una pestaña',
        AllContexts: 'Todos los Contextos',
        ClientProfile: 'Perfil del Cliente',
        Configuration: 'Configuración General',
        HumanResources: 'Recursos Humanos',
        Setting: 'Configuración',
        templateAddedSuccessfully: 'Plantilla adicionada exitosamente',
        templateEditedSuccessfully: 'Plantilla editada exitosamente',
        myPatients: 'Mis Pacientes',
        search: 'Buscar',
        comments: 'Comentarios',
        message: 'Mensaje',
        call: 'Llamar',
        directory: 'Directorio',
        searchByDate: 'Buscar por Fecha',
        productService: 'Producto/Servicio',
        incidences: 'Incidencias',
        Processing: 'Procesando',
        Resolve: 'Resuelta',
        MedicalServices: 'Servicios Médicos',
        Massage: 'Masaje',
        userName: 'Nombre de Usuario',
        productName: 'Nombre del Producto',
        availableProduct: 'Disponibilidad del Producto',
        productAttribute: 'Atributo del Producto',
        serviceName: 'Nombre del Servicio',
        doctorName: 'Nombre del Doctor',
        procedureName: 'Nombre del Procedimiento',
        filterType: 'Tipo de Filtro',
        youNeedAMissingPermission: 'Necesita un permiso extra para ver esta parte',
        youNeedAMissingPermissionHelpText:
            'Por favor, pide a tu administrador el permiso << {permissionName} >>',
        print: 'Imprimir',
        salesStatisticsExplanatoryText:
            'Estadísticas de ventas de leads por días, por tipo de procedimientos y por departamentos',
        accountsReceivableExplanatoryText: 'Cuentas por cobrar por días, por monto y por mes',
        ...hrLocales,
        ...accountingLocales,
        ...routes,
        ...occupationsLocales,
        ...structuresLocales,
        ...overtimeLocales,
        ...clientProfileLocales,
        ...productsLocales,
        ...proceduresLocales,
        ...rolesLocales,
        ...statisticsLocales,
        ...paymentsLocales,
        ...frontDeskLocales,
        ...salesLocales,
        ...publicityLocales,
        ...coordinationLocales,
        ...medicalLocales,
        ...invoiceLocales,
        ...customerServiceQA,
        ...inventory,
    },
};
export default locale;
