/* tslint:disable */
/* eslint-disable */
/**
 * API Explorer
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ChangePasswordCommand } from '../models';
// @ts-ignore
import { ForgotPasswordCommand } from '../models';
// @ts-ignore
import { ImpersonateUserCommand } from '../models';
// @ts-ignore
import { LoginCommand } from '../models';
// @ts-ignore
import { RefreshTokenCommand } from '../models';
// @ts-ignore
import { RegisterAdminCommand } from '../models';
// @ts-ignore
import { RequestPasswordUpdateCommand } from '../models';
// @ts-ignore
import { ResetPasswordCommand } from '../models';
// @ts-ignore
import { Result } from '../models';
// @ts-ignore
import { ResultOfBoolean } from '../models';
// @ts-ignore
import { ResultOfListOfString } from '../models';
// @ts-ignore
import { ResultOfTokenDto } from '../models';
// @ts-ignore
import { ResultOfUserProfileDto } from '../models';
// @ts-ignore
import { SendTwoFactorCodeCommand } from '../models';
// @ts-ignore
import { UnlockUserAccountCommand } from '../models';
// @ts-ignore
import { UpdateCultureCommand } from '../models';
// @ts-ignore
import { VerifyResetPasswordTokenCommand } from '../models';
// @ts-ignore
import { VerifyTwoFactorCodeCommand } from '../models';
/**
 * AccountApi - axios parameter creator
 * @export
 */
export const AccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ChangePasswordCommand} changePasswordCommand 
         * @param {string} [xRequestid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountChangePassword: async (changePasswordCommand: ChangePasswordCommand, xRequestid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'changePasswordCommand' is not null or undefined
            assertParamExists('accountChangePassword', 'changePasswordCommand', changePasswordCommand)
            const localVarPath = `/api/v1.1/Account/ChangePassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xRequestid != null) {
                localVarHeaderParameter['x-requestid'] = String(xRequestid);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changePasswordCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ForgotPasswordCommand} forgotPasswordCommand 
         * @param {string} [xRequestid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountForgotPassword: async (forgotPasswordCommand: ForgotPasswordCommand, xRequestid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forgotPasswordCommand' is not null or undefined
            assertParamExists('accountForgotPassword', 'forgotPasswordCommand', forgotPasswordCommand)
            const localVarPath = `/api/v1.1/Account/ForgotPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xRequestid != null) {
                localVarHeaderParameter['x-requestid'] = String(xRequestid);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forgotPasswordCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountGetAnyAdmin: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.1/Account/GetAnyAdmin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountGetTwoFactorProviders: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.1/Account/TwoFactorProviders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ImpersonateUserCommand} impersonateUserCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountImpersonateUser: async (impersonateUserCommand: ImpersonateUserCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'impersonateUserCommand' is not null or undefined
            assertParamExists('accountImpersonateUser', 'impersonateUserCommand', impersonateUserCommand)
            const localVarPath = `/api/v1.1/Account/ImpersonateUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(impersonateUserCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LoginCommand} loginCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountLogin: async (loginCommand: LoginCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginCommand' is not null or undefined
            assertParamExists('accountLogin', 'loginCommand', loginCommand)
            const localVarPath = `/api/v1.1/Account/Login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountLogout: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.1/Account/Logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RefreshTokenCommand} refreshTokenCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountRefresh: async (refreshTokenCommand: RefreshTokenCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'refreshTokenCommand' is not null or undefined
            assertParamExists('accountRefresh', 'refreshTokenCommand', refreshTokenCommand)
            const localVarPath = `/api/v1.1/Account/Refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(refreshTokenCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RegisterAdminCommand} registerAdminCommand 
         * @param {string} [xRequestid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountRegisterAdmin: async (registerAdminCommand: RegisterAdminCommand, xRequestid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registerAdminCommand' is not null or undefined
            assertParamExists('accountRegisterAdmin', 'registerAdminCommand', registerAdminCommand)
            const localVarPath = `/api/v1.1/Account/RegisterAdmin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xRequestid != null) {
                localVarHeaderParameter['x-requestid'] = String(xRequestid);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registerAdminCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RequestPasswordUpdateCommand} requestPasswordUpdateCommand 
         * @param {string} [xRequestid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountRequestPasswordUpdate: async (requestPasswordUpdateCommand: RequestPasswordUpdateCommand, xRequestid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestPasswordUpdateCommand' is not null or undefined
            assertParamExists('accountRequestPasswordUpdate', 'requestPasswordUpdateCommand', requestPasswordUpdateCommand)
            const localVarPath = `/api/v1.1/Account/RequestPasswordUpdate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xRequestid != null) {
                localVarHeaderParameter['x-requestid'] = String(xRequestid);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestPasswordUpdateCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ResetPasswordCommand} resetPasswordCommand 
         * @param {string} [xRequestid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountResetPassword: async (resetPasswordCommand: ResetPasswordCommand, xRequestid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resetPasswordCommand' is not null or undefined
            assertParamExists('accountResetPassword', 'resetPasswordCommand', resetPasswordCommand)
            const localVarPath = `/api/v1.1/Account/ResetPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xRequestid != null) {
                localVarHeaderParameter['x-requestid'] = String(xRequestid);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resetPasswordCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SendTwoFactorCodeCommand} sendTwoFactorCodeCommand 
         * @param {string} [xRequestid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountSendCode: async (sendTwoFactorCodeCommand: SendTwoFactorCodeCommand, xRequestid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sendTwoFactorCodeCommand' is not null or undefined
            assertParamExists('accountSendCode', 'sendTwoFactorCodeCommand', sendTwoFactorCodeCommand)
            const localVarPath = `/api/v1.1/Account/SendTwoFactorCode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xRequestid != null) {
                localVarHeaderParameter['x-requestid'] = String(xRequestid);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendTwoFactorCodeCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UnlockUserAccountCommand} unlockUserAccountCommand 
         * @param {string} [xRequestid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountUnlockUser: async (unlockUserAccountCommand: UnlockUserAccountCommand, xRequestid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'unlockUserAccountCommand' is not null or undefined
            assertParamExists('accountUnlockUser', 'unlockUserAccountCommand', unlockUserAccountCommand)
            const localVarPath = `/api/v1.1/Account/UnlockUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xRequestid != null) {
                localVarHeaderParameter['x-requestid'] = String(xRequestid);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(unlockUserAccountCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateCultureCommand} updateCultureCommand 
         * @param {string} [xRequestid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountUpdateCulture: async (updateCultureCommand: UpdateCultureCommand, xRequestid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateCultureCommand' is not null or undefined
            assertParamExists('accountUpdateCulture', 'updateCultureCommand', updateCultureCommand)
            const localVarPath = `/api/v1.1/Account/UpdateCulture`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xRequestid != null) {
                localVarHeaderParameter['x-requestid'] = String(xRequestid);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCultureCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [name] 
         * @param {string} [surName] 
         * @param {string} [phoneNumber] 
         * @param {string} [birthDay] 
         * @param {File} [avatar] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountUpdateProfile: async (name?: string, surName?: string, phoneNumber?: string, birthDay?: string, avatar?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.1/Account/UpdateProfile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


                if (name !== undefined) { 
                localVarFormParams.append('Name', name as any);
            }
    
                if (surName !== undefined) { 
                localVarFormParams.append('SurName', surName as any);
            }
    
                if (phoneNumber !== undefined) { 
                localVarFormParams.append('PhoneNumber', phoneNumber as any);
            }
    
                if (birthDay !== undefined) { 
                localVarFormParams.append('BirthDay', birthDay as any);
            }
    
                if (avatar !== undefined) { 
                localVarFormParams.append('Avatar', avatar as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VerifyTwoFactorCodeCommand} verifyTwoFactorCodeCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountVerifyCode: async (verifyTwoFactorCodeCommand: VerifyTwoFactorCodeCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'verifyTwoFactorCodeCommand' is not null or undefined
            assertParamExists('accountVerifyCode', 'verifyTwoFactorCodeCommand', verifyTwoFactorCodeCommand)
            const localVarPath = `/api/v1.1/Account/VerifyTwoFactorCode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(verifyTwoFactorCodeCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VerifyResetPasswordTokenCommand} verifyResetPasswordTokenCommand 
         * @param {string} [xRequestid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountVerifyResetPasswordToken: async (verifyResetPasswordTokenCommand: VerifyResetPasswordTokenCommand, xRequestid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'verifyResetPasswordTokenCommand' is not null or undefined
            assertParamExists('accountVerifyResetPasswordToken', 'verifyResetPasswordTokenCommand', verifyResetPasswordTokenCommand)
            const localVarPath = `/api/v1.1/Account/VerifyResetPasswordToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xRequestid != null) {
                localVarHeaderParameter['x-requestid'] = String(xRequestid);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(verifyResetPasswordTokenCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountApi - functional programming interface
 * @export
 */
export const AccountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ChangePasswordCommand} changePasswordCommand 
         * @param {string} [xRequestid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountChangePassword(changePasswordCommand: ChangePasswordCommand, xRequestid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountChangePassword(changePasswordCommand, xRequestid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ForgotPasswordCommand} forgotPasswordCommand 
         * @param {string} [xRequestid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountForgotPassword(forgotPasswordCommand: ForgotPasswordCommand, xRequestid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountForgotPassword(forgotPasswordCommand, xRequestid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountGetAnyAdmin(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultOfBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountGetAnyAdmin(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountGetTwoFactorProviders(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultOfListOfString>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountGetTwoFactorProviders(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ImpersonateUserCommand} impersonateUserCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountImpersonateUser(impersonateUserCommand: ImpersonateUserCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultOfTokenDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountImpersonateUser(impersonateUserCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {LoginCommand} loginCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountLogin(loginCommand: LoginCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultOfTokenDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountLogin(loginCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountLogout(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountLogout(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RefreshTokenCommand} refreshTokenCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountRefresh(refreshTokenCommand: RefreshTokenCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultOfTokenDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountRefresh(refreshTokenCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RegisterAdminCommand} registerAdminCommand 
         * @param {string} [xRequestid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountRegisterAdmin(registerAdminCommand: RegisterAdminCommand, xRequestid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountRegisterAdmin(registerAdminCommand, xRequestid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RequestPasswordUpdateCommand} requestPasswordUpdateCommand 
         * @param {string} [xRequestid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountRequestPasswordUpdate(requestPasswordUpdateCommand: RequestPasswordUpdateCommand, xRequestid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountRequestPasswordUpdate(requestPasswordUpdateCommand, xRequestid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ResetPasswordCommand} resetPasswordCommand 
         * @param {string} [xRequestid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountResetPassword(resetPasswordCommand: ResetPasswordCommand, xRequestid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountResetPassword(resetPasswordCommand, xRequestid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SendTwoFactorCodeCommand} sendTwoFactorCodeCommand 
         * @param {string} [xRequestid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountSendCode(sendTwoFactorCodeCommand: SendTwoFactorCodeCommand, xRequestid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountSendCode(sendTwoFactorCodeCommand, xRequestid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UnlockUserAccountCommand} unlockUserAccountCommand 
         * @param {string} [xRequestid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountUnlockUser(unlockUserAccountCommand: UnlockUserAccountCommand, xRequestid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountUnlockUser(unlockUserAccountCommand, xRequestid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateCultureCommand} updateCultureCommand 
         * @param {string} [xRequestid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountUpdateCulture(updateCultureCommand: UpdateCultureCommand, xRequestid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountUpdateCulture(updateCultureCommand, xRequestid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [name] 
         * @param {string} [surName] 
         * @param {string} [phoneNumber] 
         * @param {string} [birthDay] 
         * @param {File} [avatar] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountUpdateProfile(name?: string, surName?: string, phoneNumber?: string, birthDay?: string, avatar?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultOfUserProfileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountUpdateProfile(name, surName, phoneNumber, birthDay, avatar, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {VerifyTwoFactorCodeCommand} verifyTwoFactorCodeCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountVerifyCode(verifyTwoFactorCodeCommand: VerifyTwoFactorCodeCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultOfTokenDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountVerifyCode(verifyTwoFactorCodeCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {VerifyResetPasswordTokenCommand} verifyResetPasswordTokenCommand 
         * @param {string} [xRequestid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountVerifyResetPasswordToken(verifyResetPasswordTokenCommand: VerifyResetPasswordTokenCommand, xRequestid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountVerifyResetPasswordToken(verifyResetPasswordTokenCommand, xRequestid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccountApi - factory interface
 * @export
 */
export const AccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountApiFp(configuration)
    return {
        /**
         * 
         * @param {ChangePasswordCommand} changePasswordCommand 
         * @param {string} [xRequestid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountChangePassword(changePasswordCommand: ChangePasswordCommand, xRequestid?: string, options?: any): AxiosPromise<Result> {
            return localVarFp.accountChangePassword(changePasswordCommand, xRequestid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForgotPasswordCommand} forgotPasswordCommand 
         * @param {string} [xRequestid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountForgotPassword(forgotPasswordCommand: ForgotPasswordCommand, xRequestid?: string, options?: any): AxiosPromise<Result> {
            return localVarFp.accountForgotPassword(forgotPasswordCommand, xRequestid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountGetAnyAdmin(options?: any): AxiosPromise<ResultOfBoolean> {
            return localVarFp.accountGetAnyAdmin(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountGetTwoFactorProviders(options?: any): AxiosPromise<ResultOfListOfString> {
            return localVarFp.accountGetTwoFactorProviders(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ImpersonateUserCommand} impersonateUserCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountImpersonateUser(impersonateUserCommand: ImpersonateUserCommand, options?: any): AxiosPromise<ResultOfTokenDto> {
            return localVarFp.accountImpersonateUser(impersonateUserCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoginCommand} loginCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountLogin(loginCommand: LoginCommand, options?: any): AxiosPromise<ResultOfTokenDto> {
            return localVarFp.accountLogin(loginCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountLogout(options?: any): AxiosPromise<Result> {
            return localVarFp.accountLogout(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RefreshTokenCommand} refreshTokenCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountRefresh(refreshTokenCommand: RefreshTokenCommand, options?: any): AxiosPromise<ResultOfTokenDto> {
            return localVarFp.accountRefresh(refreshTokenCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RegisterAdminCommand} registerAdminCommand 
         * @param {string} [xRequestid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountRegisterAdmin(registerAdminCommand: RegisterAdminCommand, xRequestid?: string, options?: any): AxiosPromise<Result> {
            return localVarFp.accountRegisterAdmin(registerAdminCommand, xRequestid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RequestPasswordUpdateCommand} requestPasswordUpdateCommand 
         * @param {string} [xRequestid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountRequestPasswordUpdate(requestPasswordUpdateCommand: RequestPasswordUpdateCommand, xRequestid?: string, options?: any): AxiosPromise<Result> {
            return localVarFp.accountRequestPasswordUpdate(requestPasswordUpdateCommand, xRequestid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ResetPasswordCommand} resetPasswordCommand 
         * @param {string} [xRequestid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountResetPassword(resetPasswordCommand: ResetPasswordCommand, xRequestid?: string, options?: any): AxiosPromise<Result> {
            return localVarFp.accountResetPassword(resetPasswordCommand, xRequestid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SendTwoFactorCodeCommand} sendTwoFactorCodeCommand 
         * @param {string} [xRequestid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountSendCode(sendTwoFactorCodeCommand: SendTwoFactorCodeCommand, xRequestid?: string, options?: any): AxiosPromise<Result> {
            return localVarFp.accountSendCode(sendTwoFactorCodeCommand, xRequestid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UnlockUserAccountCommand} unlockUserAccountCommand 
         * @param {string} [xRequestid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountUnlockUser(unlockUserAccountCommand: UnlockUserAccountCommand, xRequestid?: string, options?: any): AxiosPromise<Result> {
            return localVarFp.accountUnlockUser(unlockUserAccountCommand, xRequestid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateCultureCommand} updateCultureCommand 
         * @param {string} [xRequestid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountUpdateCulture(updateCultureCommand: UpdateCultureCommand, xRequestid?: string, options?: any): AxiosPromise<Result> {
            return localVarFp.accountUpdateCulture(updateCultureCommand, xRequestid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [name] 
         * @param {string} [surName] 
         * @param {string} [phoneNumber] 
         * @param {string} [birthDay] 
         * @param {File} [avatar] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountUpdateProfile(name?: string, surName?: string, phoneNumber?: string, birthDay?: string, avatar?: File, options?: any): AxiosPromise<ResultOfUserProfileDto> {
            return localVarFp.accountUpdateProfile(name, surName, phoneNumber, birthDay, avatar, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VerifyTwoFactorCodeCommand} verifyTwoFactorCodeCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountVerifyCode(verifyTwoFactorCodeCommand: VerifyTwoFactorCodeCommand, options?: any): AxiosPromise<ResultOfTokenDto> {
            return localVarFp.accountVerifyCode(verifyTwoFactorCodeCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VerifyResetPasswordTokenCommand} verifyResetPasswordTokenCommand 
         * @param {string} [xRequestid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountVerifyResetPasswordToken(verifyResetPasswordTokenCommand: VerifyResetPasswordTokenCommand, xRequestid?: string, options?: any): AxiosPromise<Result> {
            return localVarFp.accountVerifyResetPasswordToken(verifyResetPasswordTokenCommand, xRequestid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountApi - object-oriented interface
 * @export
 * @class AccountApi
 * @extends {BaseAPI}
 */
export class AccountApi extends BaseAPI {
    /**
     * 
     * @param {ChangePasswordCommand} changePasswordCommand 
     * @param {string} [xRequestid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountChangePassword(changePasswordCommand: ChangePasswordCommand, xRequestid?: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).accountChangePassword(changePasswordCommand, xRequestid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForgotPasswordCommand} forgotPasswordCommand 
     * @param {string} [xRequestid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountForgotPassword(forgotPasswordCommand: ForgotPasswordCommand, xRequestid?: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).accountForgotPassword(forgotPasswordCommand, xRequestid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountGetAnyAdmin(options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).accountGetAnyAdmin(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountGetTwoFactorProviders(options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).accountGetTwoFactorProviders(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ImpersonateUserCommand} impersonateUserCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountImpersonateUser(impersonateUserCommand: ImpersonateUserCommand, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).accountImpersonateUser(impersonateUserCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoginCommand} loginCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountLogin(loginCommand: LoginCommand, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).accountLogin(loginCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountLogout(options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).accountLogout(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RefreshTokenCommand} refreshTokenCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountRefresh(refreshTokenCommand: RefreshTokenCommand, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).accountRefresh(refreshTokenCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RegisterAdminCommand} registerAdminCommand 
     * @param {string} [xRequestid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountRegisterAdmin(registerAdminCommand: RegisterAdminCommand, xRequestid?: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).accountRegisterAdmin(registerAdminCommand, xRequestid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RequestPasswordUpdateCommand} requestPasswordUpdateCommand 
     * @param {string} [xRequestid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountRequestPasswordUpdate(requestPasswordUpdateCommand: RequestPasswordUpdateCommand, xRequestid?: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).accountRequestPasswordUpdate(requestPasswordUpdateCommand, xRequestid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ResetPasswordCommand} resetPasswordCommand 
     * @param {string} [xRequestid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountResetPassword(resetPasswordCommand: ResetPasswordCommand, xRequestid?: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).accountResetPassword(resetPasswordCommand, xRequestid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SendTwoFactorCodeCommand} sendTwoFactorCodeCommand 
     * @param {string} [xRequestid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountSendCode(sendTwoFactorCodeCommand: SendTwoFactorCodeCommand, xRequestid?: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).accountSendCode(sendTwoFactorCodeCommand, xRequestid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UnlockUserAccountCommand} unlockUserAccountCommand 
     * @param {string} [xRequestid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountUnlockUser(unlockUserAccountCommand: UnlockUserAccountCommand, xRequestid?: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).accountUnlockUser(unlockUserAccountCommand, xRequestid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateCultureCommand} updateCultureCommand 
     * @param {string} [xRequestid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountUpdateCulture(updateCultureCommand: UpdateCultureCommand, xRequestid?: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).accountUpdateCulture(updateCultureCommand, xRequestid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [name] 
     * @param {string} [surName] 
     * @param {string} [phoneNumber] 
     * @param {string} [birthDay] 
     * @param {File} [avatar] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountUpdateProfile(name?: string, surName?: string, phoneNumber?: string, birthDay?: string, avatar?: File, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).accountUpdateProfile(name, surName, phoneNumber, birthDay, avatar, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VerifyTwoFactorCodeCommand} verifyTwoFactorCodeCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountVerifyCode(verifyTwoFactorCodeCommand: VerifyTwoFactorCodeCommand, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).accountVerifyCode(verifyTwoFactorCodeCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VerifyResetPasswordTokenCommand} verifyResetPasswordTokenCommand 
     * @param {string} [xRequestid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountVerifyResetPasswordToken(verifyResetPasswordTokenCommand: VerifyResetPasswordTokenCommand, xRequestid?: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).accountVerifyResetPasswordToken(verifyResetPasswordTokenCommand, xRequestid, options).then((request) => request(this.axios, this.basePath));
    }
}
