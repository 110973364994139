import { HttpTransportType, HubConnectionBuilder } from '@microsoft/signalr';
import { Notifications } from '@/composables/notification';
import { useIdentityStore } from '@/store/identity.store';
import { setNotification } from '@/composables';

export default {
    install() {
        const store = useIdentityStore();
        const connection = new HubConnectionBuilder()
            .withUrl(import.meta.env.VITE_BACKEND_URL + '/ws/notify', {
                accessTokenFactory: async () => await store.waitForToken(),
                skipNegotiation: true,
                transport: HttpTransportType.WebSockets,
            })
            .build();

        connection.on('Notification', (message: string) => {
            setNotification(message);
            Notifications.Info(message);
        });

        connection.onclose(async () => {
            await start();
        });

        async function start() {
            try {
                await connection.start();
            } catch (e) {
                setTimeout(start, 5000);
            }
        }

        start();
    },
};
