/* tslint:disable */
/* eslint-disable */
/**
 * API Explorer
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AddProviderScheduleCommand } from '../models';
// @ts-ignore
import { EditProviderScheduleCommand } from '../models';
// @ts-ignore
import { Result } from '../models';
// @ts-ignore
import { ResultOfListOfProviderBlockScheduleDto } from '../models';
// @ts-ignore
import { ResultOfListOfScheduleRangeDto } from '../models';
/**
 * ProviderSchedulesApi - axios parameter creator
 * @export
 */
export const ProviderSchedulesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AddProviderScheduleCommand} addProviderScheduleCommand 
         * @param {string} [xRequestid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerSchedulesCreate: async (addProviderScheduleCommand: AddProviderScheduleCommand, xRequestid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addProviderScheduleCommand' is not null or undefined
            assertParamExists('providerSchedulesCreate', 'addProviderScheduleCommand', addProviderScheduleCommand)
            const localVarPath = `/api/v1.1/ProviderSchedules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xRequestid != null) {
                localVarHeaderParameter['x-requestid'] = String(xRequestid);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addProviderScheduleCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [xRequestid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerSchedulesDelete: async (id: string, xRequestid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('providerSchedulesDelete', 'id', id)
            const localVarPath = `/api/v1.1/ProviderSchedules/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xRequestid != null) {
                localVarHeaderParameter['x-requestid'] = String(xRequestid);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [appointmentId] 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerSchedulesGetAvailableSchedules: async (id?: string, appointmentId?: string, date?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.1/ProviderSchedules/AvailableSchedules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }

            if (appointmentId !== undefined) {
                localVarQueryParameter['AppointmentId'] = appointmentId;
            }

            if (date !== undefined) {
                localVarQueryParameter['Date'] = (date as any instanceof Date) ?
                    (date as any).toISOString() :
                    date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {object} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerSchedulesGetBlockSchedules: async (request?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.1/ProviderSchedules/BlockSchedules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (request !== undefined) {
                localVarQueryParameter['request'] = request;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {EditProviderScheduleCommand} editProviderScheduleCommand 
         * @param {string} [xRequestid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerSchedulesUpdate: async (id: string, editProviderScheduleCommand: EditProviderScheduleCommand, xRequestid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('providerSchedulesUpdate', 'id', id)
            // verify required parameter 'editProviderScheduleCommand' is not null or undefined
            assertParamExists('providerSchedulesUpdate', 'editProviderScheduleCommand', editProviderScheduleCommand)
            const localVarPath = `/api/v1.1/ProviderSchedules/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xRequestid != null) {
                localVarHeaderParameter['x-requestid'] = String(xRequestid);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editProviderScheduleCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProviderSchedulesApi - functional programming interface
 * @export
 */
export const ProviderSchedulesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProviderSchedulesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AddProviderScheduleCommand} addProviderScheduleCommand 
         * @param {string} [xRequestid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerSchedulesCreate(addProviderScheduleCommand: AddProviderScheduleCommand, xRequestid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerSchedulesCreate(addProviderScheduleCommand, xRequestid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [xRequestid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerSchedulesDelete(id: string, xRequestid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerSchedulesDelete(id, xRequestid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [appointmentId] 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerSchedulesGetAvailableSchedules(id?: string, appointmentId?: string, date?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultOfListOfScheduleRangeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerSchedulesGetAvailableSchedules(id, appointmentId, date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {object} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerSchedulesGetBlockSchedules(request?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultOfListOfProviderBlockScheduleDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerSchedulesGetBlockSchedules(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {EditProviderScheduleCommand} editProviderScheduleCommand 
         * @param {string} [xRequestid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerSchedulesUpdate(id: string, editProviderScheduleCommand: EditProviderScheduleCommand, xRequestid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerSchedulesUpdate(id, editProviderScheduleCommand, xRequestid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProviderSchedulesApi - factory interface
 * @export
 */
export const ProviderSchedulesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProviderSchedulesApiFp(configuration)
    return {
        /**
         * 
         * @param {AddProviderScheduleCommand} addProviderScheduleCommand 
         * @param {string} [xRequestid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerSchedulesCreate(addProviderScheduleCommand: AddProviderScheduleCommand, xRequestid?: string, options?: any): AxiosPromise<Result> {
            return localVarFp.providerSchedulesCreate(addProviderScheduleCommand, xRequestid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [xRequestid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerSchedulesDelete(id: string, xRequestid?: string, options?: any): AxiosPromise<Result> {
            return localVarFp.providerSchedulesDelete(id, xRequestid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [appointmentId] 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerSchedulesGetAvailableSchedules(id?: string, appointmentId?: string, date?: string, options?: any): AxiosPromise<ResultOfListOfScheduleRangeDto> {
            return localVarFp.providerSchedulesGetAvailableSchedules(id, appointmentId, date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {object} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerSchedulesGetBlockSchedules(request?: object, options?: any): AxiosPromise<ResultOfListOfProviderBlockScheduleDto> {
            return localVarFp.providerSchedulesGetBlockSchedules(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {EditProviderScheduleCommand} editProviderScheduleCommand 
         * @param {string} [xRequestid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerSchedulesUpdate(id: string, editProviderScheduleCommand: EditProviderScheduleCommand, xRequestid?: string, options?: any): AxiosPromise<Result> {
            return localVarFp.providerSchedulesUpdate(id, editProviderScheduleCommand, xRequestid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProviderSchedulesApi - object-oriented interface
 * @export
 * @class ProviderSchedulesApi
 * @extends {BaseAPI}
 */
export class ProviderSchedulesApi extends BaseAPI {
    /**
     * 
     * @param {AddProviderScheduleCommand} addProviderScheduleCommand 
     * @param {string} [xRequestid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderSchedulesApi
     */
    public providerSchedulesCreate(addProviderScheduleCommand: AddProviderScheduleCommand, xRequestid?: string, options?: AxiosRequestConfig) {
        return ProviderSchedulesApiFp(this.configuration).providerSchedulesCreate(addProviderScheduleCommand, xRequestid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [xRequestid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderSchedulesApi
     */
    public providerSchedulesDelete(id: string, xRequestid?: string, options?: AxiosRequestConfig) {
        return ProviderSchedulesApiFp(this.configuration).providerSchedulesDelete(id, xRequestid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {string} [appointmentId] 
     * @param {string} [date] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderSchedulesApi
     */
    public providerSchedulesGetAvailableSchedules(id?: string, appointmentId?: string, date?: string, options?: AxiosRequestConfig) {
        return ProviderSchedulesApiFp(this.configuration).providerSchedulesGetAvailableSchedules(id, appointmentId, date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {object} [request] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderSchedulesApi
     */
    public providerSchedulesGetBlockSchedules(request?: object, options?: AxiosRequestConfig) {
        return ProviderSchedulesApiFp(this.configuration).providerSchedulesGetBlockSchedules(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {EditProviderScheduleCommand} editProviderScheduleCommand 
     * @param {string} [xRequestid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderSchedulesApi
     */
    public providerSchedulesUpdate(id: string, editProviderScheduleCommand: EditProviderScheduleCommand, xRequestid?: string, options?: AxiosRequestConfig) {
        return ProviderSchedulesApiFp(this.configuration).providerSchedulesUpdate(id, editProviderScheduleCommand, xRequestid, options).then((request) => request(this.axios, this.basePath));
    }
}
