import dayjs from 'dayjs';
import { AxiosResponse } from 'axios';

const ageFunction = (date) => {
    const today = dayjs(new Date());
    const birthDay = dayjs(new Date(date));
    const age = Math.round(today.diff(birthDay, 'y'));
    return age;
};

async function getFileFromUrl(url, name = '', defaultType = 'image/jpeg') {
    const response = await fetch(url, {
        credentials: 'include',
        headers: {
            'Access-Control-Allow-Origin': import.meta.env.VITE_FRONTEND_URL,
        },
    });
    if (!name) name = url.split('\\').pop();
    const data = await response.blob();

    return new File([data], name, {
        type: data.type || defaultType,
    });
}

const conditionalArrayPop = (arr: string | string[]): string => (Array.isArray(arr) ? arr[0] : arr);

const toBase64 = (file: File) =>
    new Promise<string | ArrayBuffer | null>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

const fromBase64 = (b64String: string, fileName = 'newFile'): File => {
    const base64parts = b64String.split(',');
    const metadata = base64parts[0].split(';');
    const fileFormat = metadata[1];
    const fileExt = metadata[0].split('/')[1];
    const fileContent = atob(base64parts[1]);
    console.debug('File info....', fileFormat, fileExt, metadata);
    let n = fileContent.length;
    const u8arr = new Uint8Array(n);
    while (n--) u8arr[n] = fileContent.charCodeAt(n);
    return new File([u8arr], `${fileName}.${fileExt}`, { type: fileFormat });
};

const formatDate = (dateString: string, locale?: string) =>
    (dateString && dayjs(dateString).format('MMMM D, YYYY')) || '';
const formatTime = (dateString: string) => dateString && dayjs(dateString).format('hh:mm A');

const downloadFile = (response: AxiosResponse, fileName: string) => {
    const type = response.headers['content-type'];
    console.debug(response.headers);
    const blob = new Blob([response.data], { type: type });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();

    URL.revokeObjectURL(link.href);
};

export {
    ageFunction,
    getFileFromUrl,
    conditionalArrayPop,
    toBase64,
    fromBase64,
    formatDate,
    formatTime,
    downloadFile,
};
