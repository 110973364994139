const messages = {
    seller: 'Seller',
    location: 'Location',
    deviceName: 'Device Name',
    advertisingScreens: 'Advertising Screens',
    manageDevicesToShowAdversiting: 'Manage the devices to show adversiting',
    addAdvertisingTV: 'Add Advertising TV',
    noContentPlanned: 'No content planned',
    someContentPlanned: 'Some content planned',
    openOptions: 'Open Options',
    addNewAdvertisingTV: 'Add a New Advertising TV',
    deviceSuccessfullyDeleted: 'Device successfully deleted',
    confirmDeleteDevice: 'Confirm Delete Device',
    showcase: 'Showcase',
    destinationDate: 'Destination Date',
    editDevice: 'Edit Device',
    updatePlaylist: 'Update Playlist',
    copyThisSchedule: 'Copy this Schedule',
    activateShowcaseView: 'Activate Showcase View',
    confirmCopyOfPlaylist: 'Confirm Copy of Playlist',
    copyThePlaylistOf: 'Copy the Playlist of ',
    errorCopyingPlaylist: 'Error copying playlist',
    errorSavingPlaylist: 'Error saving playlist',
    playlistSaved: 'Playlist Saved',
    exitShowcaseMode: 'Exit Showcase Mode ',
    errorLoadingDeviceData: 'Error loading device data',
    tags: 'Tags',
    title: 'Title',
    file: 'File',
    assetType: 'Asset Type',
    assetAddedSuccessfully: 'Asset added successfully',
    youWantToDeleteAsset: 'Are you sure you want to delete this asset?',
    assets: 'Assets',
    addAsset: 'Add Asset',
    searchByTitle: 'Search by Title',
    searchByDescription: 'Search by Description',
    searchByYear: 'Search by Year',
    searchByMonth: 'Search by Month',
    searchByTag: 'Search by Tag',
    searchByType: 'Search by Type',
    detail: 'Detail',
    download: 'Download',
    upload: 'Upload',
    image: 'Image',
    video: 'Video',
    audio: 'Audio',
    document: 'Document',
    uploadDate: 'Upload Date',
    selectAsset: 'Select asset',
    browserDoesNotSupportVideoTag: 'Your browser does not support the video tag.',
    viewDetailsFor: 'View Details for ',
    browserDoesNotSupportAudioElement: 'Your browser does not support the audio element.',
    detailsFor: 'Details for ',
    confirmDeleteAsset: 'Confirm Delete Asset',
    notFound: 'Not Found',
    preview: 'Preview',
    downloadLink: 'Download Link',
    assetUpdatedSuccessfully: 'Asset updated successfully',
    messageLine: 'Message Line',
    generateContentFor: 'Generate Content for',
    post: 'Post',
    ad: 'Ad',
    both: 'Both',
    contentType: 'Content Type',
    campaigns: 'Campaigns',
    campaign: 'Campaign',
    addCampaign: 'Add Campaign',
    searchByContentType: 'Search by Content Type',
    searchByContentGeneratedFor: 'Search by content generated for',
    typeOfContract: 'Type Of Contract',
    influencer: 'Influencer',
    sponsorship: 'Sponsorship',
    advertisingServicesAgreement: 'Advertising Services Agreement',
    contractObjective: 'Contract Objective',
    contractDocument: 'Contract Document',
    contracts: 'Contracts',
    addContract: 'Add Contract',
    searchByName: 'Search by Name',
    searchByStartDate: 'Search by Start Date',
    searchByExpirationDate: 'Search by Expiration Date',
    objective: 'Objective',
    firstContactAddedSuccessfully: 'First contact added successfully',
    firstContactUpdatedSuccessfully: 'First contact updated successfully',
    firstContacts: 'First Contacts',
    firstContact: 'First Contact',
    addFirstContact: 'Add First Contact',
    addNewContact: 'Add New Contact',
    editContact: 'Edit Contact',
    pleaseSelectOne: 'Please Select One',
    customerSources: 'Customer Sources',
    preferredDoctor: 'Preferred Doctor',
    comment: 'Comment',
    assign: 'Assign',
    manually: 'Manually',
    automatically: 'Automatically',
    assignedTo: 'Assigned To',
    createNewAppointment: 'Create New Appointment',
    addNewTask: 'Add New Task',
    editTask: 'Edit Task',
    share: 'Share',
    unshare: 'Unshare',
    shareCalendar: 'Share Calendar',
    sharingWith: 'Sharing With',
    subscribedTo: 'Subscribed To',
    sourceAddedSuccessfully: 'Source added successfully',
    sourceUpdatedSuccessfully: 'Source updated successfully',
    addCustomerSource: 'Add Customer Source',
    addNewSource: 'Add New Source',
    editSource: 'Edit Source',
    noAdvertisingDevicesConfigured: 'No advertising devices configured',
    deviceStatus: 'Device Status',
    currentStatus: 'Current Status',
    offline: 'Offline',
    noContent: 'No content',
    playingNow: 'Playing Now',
    Offline: 'Offline',
    Online: 'Online',
    NoContent: 'No Content',
    Playing: 'Playing',
    tag: 'Tag',
    year: 'Year',
    month: 'Month',
    leadsPerDay: 'Leads per Day',
    publicityStatistics: 'Publicity Statistics',
    leadsStatisticsBySourceFirstContact: 'Statistics by Source and First Contact',
    existingLeadsAssigned: 'Existing Leads Assigned',
    newLeadsAssigned: 'New Leads Assigned',
    emergencyContact: 'Emergency Contact',
    emergencyContactMessage: 'This patient does not have an emergency contact.',
    Unknown: 'Unknown',
    secondaryPhone: 'Secondary Phone',
    inProcess: 'In process',
    processedBy: 'Processed by',
    conflictWith: 'In conflict with',
    resolveConflict: 'Resolve conflict',
    processingByOtherUserError: 'The lead is being processed by another user',
    existingLeadConflictError: 'The new lead has a conflict with an existing one',
    existingLead: 'Existing Lead',
    incomingLead: 'New lead',
    samePerson: 'Same Person',
    samePersonWarningMessage:
        'This action deletes the new lead and keeps the existing one unchanged. Do you want to continue?',
    differentPersons: 'Different persons',
    differentPersonsWarningMessage:
        'This action creates a lead and removes the phone number from the existing one. Do you want to continue?',
    withConflict: 'In conflict with an existing lead',
    processing: 'Processing',
    uploading: 'uploading...',
    // Tutorials
    tutorials: 'Help',
    createTutorial: 'Create Tutorial',
    youWantToDeleteTutorial: 'Are you sure you want to delete this tutorial?',
    tutorialsErrorTitle: 'Error!',
    tutorialsErrorMessage: 'Something went wrong.',
    tutorialsGetStarted: 'Select an item to get started.',
    tutorialsShowMore: 'Show More',
    tutorialsMoreFrom: 'More Help From',
    tutorialsReorder: 'Reorder',
    tutorialNewSortOrder: 'New Sort Order',
    // Reports
    publicityCustomerSourcesSold: 'Publicity Customer Sources Sold',
    publicityCustomerSourcesSoldDescription:
        'See how many NEW LEADS were sold in a period of time by customer source',
    publicityCustomerSourcesSoldReassignee: 'Publicity Customer Sources Reassignees',
    publicityCustomerSourcesSoldReassigneeDescription:
        'See how many social media reassignees were sold in a period of time by customer source',
};

export default messages;
