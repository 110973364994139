import { markRaw } from 'vue';
import { createPinia, Store } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import router from '@/routes/index';

const pinia = createPinia();
const AllPiniaStores = new Set<Store>();
pinia.use(piniaPluginPersistedstate);
pinia.use(({ store }) => {
    store.router = markRaw(router);
    AllPiniaStores.add(store);
    store.$all = AllPiniaStores;
    store.$reset_all = () => store.$all.forEach((s) => s.$reset());
});

declare module 'pinia' {
    export interface PiniaCustomProperties {
        $all: typeof AllPiniaStores;
        /** Reset all the stores */
        $reset_all: () => void;
    }
}

export default pinia;
