const messages = {
    procedure: 'Procedure',
    procedures: 'Procedures',
    addProcedure: 'Add Procedure',
    isSurgical: 'Is Surgical',
    addNewProcedure: 'Add New procedure',
    addNewDoctorProcedure: 'Add New doctor Procedure',
    editProcedure: 'Edit procedure',
    editDoctorProcedure: 'Edit Doctor Procedure',
    theProcedureIsSurgical: 'The procedure is surgical.',
    surgical: 'Surgical',
    notSurgical: 'Not Surgical',
    procedureAddedSuccessfully: 'Procedure added successfully',
    procedureEditedSuccessfully: 'Procedure edited successfully',
    doctorsAndProcedures: 'Doctors & Procedures',
    doctorsProceduresDeletedSuccessfully: "Doctor's procedure removed successfully",
    confirmDeleteDoctorsProcedures: "Confirm delete Doctor's procedure",
    noticeDelete: "This action can't be reversed and some data could be lost",
    errorLoadingEmployees: 'Error loading employees list',
    Accessories: 'Accessories',
    requiredAccessories: 'Required Accessories',
    promotionPrice: 'Promotion Price',
    hasPromotion: 'Has Promotion',
    doctorProcedureHasPromotion: "The doctor's procedure has a promotion.",
    doctorProcedureGeneralAnesthesia: "The doctor's procedure with general anesthesia.",
    pleaseSelectOne: 'Please select one',
    doctorProcedureAddedSuccessfully: 'Doctor Procedure added successfully',
    doctorProcedureUpdatedSuccessfully: 'Doctor Procedure updated successfully',
    appointmentServices: 'Appointment Services',
    addNewAppointmentService: 'Add New Appointment Service',
    addAppointmentService: 'Add Appointment Service',
    editAppointmentService: 'Edit Appointment Service',
    electronic: 'Electronic',
    canBeUseElectronic: 'Can be used electronically',
    emptyAccessories: 'You have not added any accessories',
    emptyAccessoriesHelpText: 'Start adding new accessories to the doctor procedures',
    emptyConsumables: 'You have not added any consumables',
    emptyConsumablesHelpText: 'Start adding new consumables to the doctor procedures',
    theAccessoryIsRequired: 'The accessory is required',
    addNewConsumable: 'Add new consumable',
    addNewAccessoryHelpText: 'Add new accessory to the doctor procedures',
    addNewAccessory: 'Add new accessory',
    addNewConsumableHelpText: 'Add new consumable to the doctor procedures',
    confirmDeleteProcedure: 'Confirm delete procedure',
    deleteProcedure: 'Delete Procedure',
    startAmount: 'Start Amount',
    endAmount: 'End Amount',
    addCosmetologyPromotion: 'Add Cosmetology Promotion',
    editCosmetologyPromotion: 'Edit Cosmetology Promotion',
};

export default messages;
