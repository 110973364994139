const AllProducts = () => import('../pages/inventory/products/AllProducts.vue');
import { RouterView } from 'vue-router';

const inventoryRoutes = [
    {
        path: 'inventory',
        name: 'inventory',
        redirect: (to) => {
            return { path: '/inventory/procedures' };
        },
        component: RouterView,
        meta: {
            title: 'Inventory',
            public: false,
            icon: 'ArchiveIcon',
        },
        children: [
            {
                path: 'products',
                name: 'InventoryProducts',
                component: AllProducts,
                meta: {
                    menu: true,
                    title: 'Products',
                    permissionsAnyOf: [
                        'Permissions.Inventory.Product.Search',
                        'Permissions.Inventory.Product.Detail',
                        'Permissions.Inventory.Product.Create',
                        'Permissions.Inventory.Product.Update',
                        'Permissions.Inventory.Product.Delete',
                    ],
                },
            },
        ],
    },
];

export default inventoryRoutes;
