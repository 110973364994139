const messages = {
    StockAdded: 'Added',
    StockRemoved: 'Removed',
    Surgery: 'Surgery',
    Cosmetology: 'Cosmetology',
    Dispatch: 'Dispatch',
    TpvSale: 'Sale',
    TpvSaleItemCancelled: 'Sale Cancelled',
};

export default messages;
