const CompanySettings = () => import('../pages/configuration/CompanySettings.vue');
const StructureSettings = () => import('../pages/configuration/StructureSettings.vue');
const AddStructureType = () => import('../pages/configuration/types/AddStructureType.vue');
const EditStructureType = () => import('../pages/configuration/types/EditStructureType.vue');
const ListStructureTypes = () => import('../pages/configuration/types/ListStructureTypes.vue');
const HumanResConfig = () => import('../pages/configuration/HumanResConfig.vue');
const OvertimeConfigurations = () =>
    import('../pages/humanResources/configuration/components/OvertimeConfigurations.vue');

const configRoutes = [
    {
        path: 'company',
        name: 'CompanyConfig',
        component: CompanySettings,
        meta: {
            menu: true,
            title: 'Company',
            icon: 'OfficeBuildingIcon',
            permissionsAnyOf: ['Permissions.Setting.SystemTheme.Update'],
        },
    },
    {
        path: 'hr',
        name: 'HumanResourcesConfig',
        component: HumanResConfig,
        meta: {
            menu: true,
            title: 'Occupations',
            icon: 'UserCircleIcon',
            permissionsAnyOf: ['Permissions.HumanResources.Occupations.List'],
        },
    },
    {
        path: 'structures-type',
        name: 'StructureTypes',
        component: ListStructureTypes,
        meta: {
            menu: true,
            title: 'Structures Types',
            icon: 'OfficeBuildingIcon',
            permissionsAnyOf: ['Permissions.Configuration.StructureType.List'],
        },
    },
    {
        path: 'structures-type/add',
        name: 'StructureTypeAdd',
        component: AddStructureType,
        meta: {
            title: 'Add Structure Type',
            icon: 'OfficeBuildingIcon',
        },
    },
    {
        path: 'structures-type/edit/:id',
        name: 'StructureTypeEdit',
        component: EditStructureType,
        meta: {
            title: 'Edit Structure Type',
            icon: 'OfficeBuildingIcon',
        },
    },
    {
        path: 'structure/tree',
        name: 'StructureTree',
        component: StructureSettings,
        meta: {
            menu: true,
            title: 'Company Structure',
            icon: 'OfficeBuildingIcon',
            permissionsAnyOf: ['Permissions.Configuration.Structure.List'],
        },
    },
    {
        path: 'overtime',
        name: 'OvertimeConfigurations',
        component: OvertimeConfigurations,
        meta: {
            menu: true,
            title: 'Overtime Configurations',
            icon: 'OfficeBuildingIcon',
            permissionsAnyOf: ['Permissions.HumanResources.Overtime.List'],
        },
    },
];

export default configRoutes;
