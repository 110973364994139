import occupations from '@/locales/es/setting/occupations.locales';
import structures from '@/locales/es/setting/structures.locales';
import overtime from '@/locales/es/setting/overtime.locales';
import products from '@/locales/es/setting/products.locales';
import procedures from '@/locales/es/setting/procedures.locales';

export default {
    ...occupations,
    ...structures,
    ...overtime,
    ...products,
    ...procedures,
};
